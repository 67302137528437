export enum ECitizenship {
  // Российская Федерация
  RussianFederation = 1,
  // Республика Казахстан
  RepublicOfKazakhstan = 2,
  // Республика Беларусь
  RepublicOfBelarus = 3,
  // Киргизская Республика
  KyrgyzRepublic = 4,
  // Республика Армения
  RepublicOfArmenia = 5,
}
