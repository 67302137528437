import { Layout } from 'layout';
import AgentRequestDetails from 'pages/AgentRequestDetails';
import AgentRequestsPage from 'pages/AgentRequests';
import { ContactData } from 'pages/ContactData';
import Help from 'pages/Help';
import Moderation from 'pages/ModerationRequest';
import { FinalStep } from 'pages/ModerationRequest/ExecutorModeration/steps/finalStep';
import { Step1 } from 'pages/ModerationRequest/ExecutorModeration/steps/step1';
import { Step2 } from 'pages/ModerationRequest/ExecutorModeration/steps/step2';
import { Step3 } from 'pages/ModerationRequest/ExecutorModeration/steps/step3';
import { Step4 } from 'pages/ModerationRequest/ExecutorModeration/steps/step4';
import { Step5 } from 'pages/ModerationRequest/ExecutorModeration/steps/step5';
import { Step6 } from 'pages/ModerationRequest/ExecutorModeration/steps/step6';
import { Step7 } from 'pages/ModerationRequest/ExecutorModeration/steps/step7';
import { FinalStepSubcontractor } from 'pages/ModerationRequest/SubcontractorModeration/steps/finalStep';
import { Step1Subcontractor } from 'pages/ModerationRequest/SubcontractorModeration/steps/step1';
import { Step2Subcontractor } from 'pages/ModerationRequest/SubcontractorModeration/steps/step2';
import { Step3Subcontractor } from 'pages/ModerationRequest/SubcontractorModeration/steps/step3';
import { Step4Subcontractor } from 'pages/ModerationRequest/SubcontractorModeration/steps/step4';
import NotFound from 'pages/NotFound';
import OrderDetailsPage from 'pages/OrderDetails';
import Orders from 'pages/Orders';
import { PrivateData } from 'pages/PrivateData';
import { Profile } from 'pages/Profile';
import { ChangePhone } from 'pages/Profile/components/ChangePhone';
import { ChangeTimezone } from 'pages/Profile/components/ChangeTimezone';
import { ConfirmEmail } from 'pages/Profile/components/ConfirmEmail';
import { ProfileSettings } from 'pages/ProfileSettings';
import { Receipts } from 'pages/Receipts';
import { Support } from 'pages/Support';
import { Navigate } from 'react-router-dom';
import { ExecutorTypeEnum } from 'reduxStore/reducers/ProfileSlice/types';

export function createRouter(role: ExecutorTypeEnum) {
  const isSubcontractor = role === ExecutorTypeEnum.Subcontractor;

  const routes = [
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: 'receipts',
          element: <Receipts />,
        },
        {
          path: 'moderation-request',
          children: [
            { index: true, element: <Moderation /> },
            {
              path: 'step1',
              element: isSubcontractor ? <Step1Subcontractor /> : <Step1 />,
            },
            {
              path: 'step2',
              element: isSubcontractor ? <Step2Subcontractor /> : <Step2 />,
            },
            {
              path: 'step3',
              element: isSubcontractor ? <Step3Subcontractor /> : <Step3 />,
            },
            {
              path: 'step4',
              element: isSubcontractor ? <Step4Subcontractor /> : <Step4 />,
            },
            {
              path: 'step5',
              element: <Step5 />,
            },
            {
              path: 'step6',
              element: <Step6 />,
            },
            {
              path: 'step7',
              element: <Step7 />,
            },
            {
              path: 'final-step',
              element: isSubcontractor ? <FinalStepSubcontractor /> : <FinalStep />,
            },
          ],
        },
        {
          path: 'help',
          element: <Help />,
        },
        {
          path: 'orders',
          children: [
            { index: true, element: <Orders /> },
            { path: ':type', element: <Orders /> },
          ],
        },
        {
          path: 'orders/:type/:id',
          element: <OrderDetailsPage />,
        },
        {
          path: 'profile',
          children: [
            {
              index: true,
              element: <Profile />,
            },
            {
              path: 'private',
              element: <PrivateData />,
            },
            {
              path: 'contacts',
              element: <ContactData />,
            },
            {
              path: 'settings',
              element: <ProfileSettings />,
            },
            {
              path: 'support',
              element: <Support />,
            },
            {
              path: 'phone',
              element: <ChangePhone />,
            },
            {
              path: 'timezone',
              element: <ChangeTimezone />,
            },
            {
              path: 'email',
              element: <ConfirmEmail />,
            },
          ],
        },
        {
          path: 'agent',
          children: [
            {
              path: 'orders/:type',
              element: <AgentRequestsPage />,
            },
            {
              path: 'orders/:type/:id',
              element: <AgentRequestDetails />,
            },
            {
              path: 'profile',
              children: [
                {
                  index: true,
                  element: <Profile />,
                },
                {
                  path: 'phone',
                  element: <ChangePhone />,
                },
                {
                  path: 'timezone',
                  element: <ChangeTimezone />,
                },
                {
                  path: 'email',
                  element: <ConfirmEmail />,
                },
              ],
            },
          ],
        },
        {
          path: 'not-found',
          element: <NotFound />,
        },
        {
          path: '*',
          element: <Navigate to="/not-found" />,
        },
      ],
    },
  ];

  return routes;
}
