import { CloseIcon, IconCalendar } from 'assets/icons';
import classNames from 'classnames';
import { format } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { useRenderIcons } from 'hooks/useRenderIcons';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { DateRange, RangeKeyDict } from 'react-date-range';
import { ValidationError } from '../ValidationError';
import { editFilterRuLocale, initialValueRange, variants } from './constants';
import { DateRangePickerProps } from './types';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';

/**TODO разобраться, почему не всплывает обработчик события click на document из модального окна */
export const DateRangePicker: FC<DateRangePickerProps> = ({
  label,
  errorMessage = '',
  disabled = false,
  icons,
  iconWrapperClassName,
  value,
  onChange,
  canClear = true,
  months = 2,
  maxDate,
  ...other
}) => {
  const ruLocale = editFilterRuLocale();

  const renderIcons = useRenderIcons(
    disabled,
    canClear
      ? [
          {
            icon: (
              <CloseIcon className="mr-1 -ml-3 mt-2 text-text-50 hover:cursor-pointer hover:text-text-50 sm:ml-0" />
            ),
            onClick: () => {
              onChange && onChange(initialValueRange);
              setIsOpenDateRange(false);
            },
          },
          {
            icon: <IconCalendar className="mt-2 mr-2 -ml-2 sm:ml-0" />,
            onClick: () => setIsOpenDateRangeHandle(),
          },
          ...(icons ?? []),
        ]
      : [
          { icon: <IconCalendar className="mt-2" />, onClick: () => setIsOpenDateRangeHandle() },
          ...(icons ?? []),
        ],
    iconWrapperClassName
  );

  const [dateRange, setDateRange] = useState([
    {
      startDate: value.startDate || new Date(),
      endDate: value.endDate || new Date(),
      key: 'selection',
    },
  ]);

  const [isOpenDateRange, setIsOpenDateRange] = useState(false);

  const dateRangeRef = useRef(null);
  const inputRef = useRef(null);

  const clickEventListenerHandler = useCallback(
    (event: Event) => {
      const element = event.target as HTMLElement | null;
      if (element) {
        const calRef = element.closest('.dateRangeRef');
        const inRef = element.closest('.inputRef');
        if (calRef !== dateRangeRef.current && inRef !== inputRef.current && isOpenDateRange) {
          setIsOpenDateRange(false);
        }
      }
    },
    [isOpenDateRange, setIsOpenDateRange, dateRangeRef.current, inputRef.current]
  );

  useEffect(() => {
    document.addEventListener('click', clickEventListenerHandler);
    return () => {
      document.removeEventListener('click', clickEventListenerHandler);
    };
  }, [clickEventListenerHandler]);

  useEffect(() => {
    setDateRange([
      {
        startDate: value.startDate || new Date(),
        endDate: value.endDate || new Date(),
        key: 'selection',
      },
    ]);
  }, [value.startDate, value.endDate]);

  const onChangeHandle = useCallback(
    (value: RangeKeyDict) => {
      onChange && onChange(value.selection);
      const startDate = value.selection.startDate;
      const endDate = value.selection.endDate;
      startDate &&
        endDate &&
        format(startDate, 'dd.MM.yyyy') !== format(endDate, 'dd.MM.yyyy') &&
        setIsOpenDateRange(false);
    },
    [onChange]
  );

  const setIsOpenDateRangeHandle = useCallback(() => {
    setIsOpenDateRange((prev) => !prev);
  }, [setIsOpenDateRange]);

  return (
    <div className="inputRef relative" ref={inputRef}>
      <div
        className={classNames('flex border-b border-light-50 transition-colors', {
          'border-danger': errorMessage,
        })}
      >
        <div className="flex grow flex-col justify-end pb-1">
          {label && <div className="body-normal mb-4 !text-text-50">{label}</div>}
          <div className="flex">
            <input
              className="body-normal block w-24 !text-text-100"
              disabled={disabled}
              value={
                value && value.startDate && value.endDate
                  ? format(value.startDate, 'dd.MM.yyyy')
                  : ''
              }
              onClick={setIsOpenDateRangeHandle}
              {...other}
            />
            {value && value.startDate && value.endDate && (
              <div className="w-4 !text-text-100">-</div>
            )}
            <input
              className="body-normal block grow !text-text-100"
              disabled={disabled}
              value={
                value && value.startDate && value.endDate ? format(value.endDate, 'dd.MM.yyyy') : ''
              }
              onClick={setIsOpenDateRangeHandle}
              {...other}
            />
          </div>
        </div>
        {renderIcons}
      </div>
      <ValidationError errorMessage={errorMessage} />
      <AnimatePresence>
        {isOpenDateRange && (
          <motion.div
            variants={variants}
            initial="initial"
            animate="animate"
            exit="initial"
            transition={{ duration: 0.33 }}
            className="dateRangeRef absolute top-full left-0 z-[60] !mt-0 w-full"
            ref={dateRangeRef}
          >
            <DateRange
              months={months}
              editableDateInputs={false}
              onChange={onChangeHandle}
              moveRangeOnFirstSelection={false}
              ranges={dateRange}
              direction="vertical"
              scroll={{ enabled: true, calendarHeight: 450 }}
              locale={ruLocale}
              dateDisplayFormat="dd.MM.yyyy"
              startDatePlaceholder="дд.мм.гггг"
              endDatePlaceholder="дд.мм.гггг"
              weekdayDisplayFormat="EEEEEE"
              rangeColors={['#F1F1F2']}
              showMonthArrow={false}
              maxDate={maxDate}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
