/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>0</td><td>NotSet</td><td>Не задано</td></tr><tr><td>1</td><td>NotFilled</td><td>Не заполнен</td></tr><tr><td>2</td><td>Filling</td><td>Заполняется</td></tr><tr><td>3</td><td>Filled</td><td>Заполнен</td></tr><tr><td>4</td><td>ValidationPending</td><td>Ожидает проверки рекрутером</td></tr><tr><td>5</td><td>ValidationInProgress</td><td>Проверяется рекрутером</td></tr><tr><td>6</td><td>ValidationFinished</td><td>Проверено рекрутером</td></tr></tbody></table></summary>
 */
export enum Onboarding_Underwriting_SharedEmuns_UnderwritingStatusEnum {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
    '_6' = 6,
}
