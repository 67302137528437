import { ReactNode, useMemo } from 'react';
import classNames from 'classnames';

export const useRenderIcons = (
  disabled: boolean,
  icons: RenderIcon[],
  iconWrapperClassNames?: string
): null | ReactNode => {
  const renderIcons = useMemo(() => {
    if (disabled) {
      return null;
    }

    return (
      <div className={classNames(iconWrapperClassNames, 'flex self-center')}>
        {icons.map((icon, index) => (
          <button
            key={index}
            className="mr-2.5 cursor-pointer last:mr-0"
            onClick={icon.onClick}
            type="button"
          >
            {icon.icon}
          </button>
        ))}
      </div>
    );
  }, [disabled, icons]);

  return renderIcons;
};
