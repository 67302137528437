import { FC } from 'react';
import { useAppSelector } from 'hooks/useAppSelector';
import { getProfileState } from 'reduxStore/reducers/ProfileSlice/selectors/getProfileState';

const ProfileHead: FC = () => {
  const { profile } = useAppSelector(getProfileState);

  return (
    <div className="flex items-center gap-4">
      <span className="body-bold sm:title2-bold lg:title-bold !text-text-100">
        {profile?.fullName}
      </span>
    </div>
  );
};

export default ProfileHead;
