import React from 'react';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { ReactComponent as CheckCircle } from 'assets/check-circle.svg';

interface AnnotationProps {
  children?: React.ReactNode;
  variant?: AnnotationVariant;
  onNomenclatureApproval?: () => void;
  onNomenclatureAdding?: () => void;
  rejectReason?: string;
}

export enum AnnotationVariant {
  BLUE = 'blue',
  RED = 'red',
  TEAL = 'teal',
}

export const Annotation2 = ({ variant = AnnotationVariant.BLUE, children }: AnnotationProps) => {
  const colors = {
    red: {
      border: `border-red`,
      text: `text-red`,
    },
    blue: {
      border: `border-blue`,
      text: `text-blue`,
    },
    teal: {
      border: `border-teal`,
      text: `text-teal`,
    },
  };
  return (
    <div className={`w-full mb-8 p-2 rounded-lg border ${colors[variant].border}`}>
      {variant === AnnotationVariant.TEAL ? (
        <CheckCircle className={`mb-2 ${colors[variant].text}`} />
      ) : (
        <InfoIcon className={`mb-2 ${colors[variant].text}`} />
      )}
      {children}
    </div>
  );
};
