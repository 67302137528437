import { OrderTypesEnum } from './enums';
import { OrderFilters } from './Filters';

export const determinateTabIndex = (type: OrderTypesEnum): number | null => {
  for (let i = 0; i < Object.keys(OrderTypesEnum).length; i++) {
    if (Object.values(OrderTypesEnum)[i] === type) return i + 1;
  }
  return null;
};

export const determinateFiltersCount = (searchParameters: OrderFilters): number => {
  let count = 0;
  if (searchParameters.cities?.length) count++;
  if (searchParameters.customId) count++;
  if (searchParameters.clients?.length) count++;
  if (searchParameters.create_date?.startDate) count++;
  return count;
};
