import { FieldErrors } from 'react-hook-form';

export default function useFieldErrorMessage(
  name: string,
  errors?: {
    [name: string]: FieldErrors;
  }
): string {
  // Простите, потомки...
  const [fieldArrayName, fieldArrayIndex, controlName] = name.split('.');
  return errors &&
    fieldArrayName &&
    fieldArrayIndex &&
    controlName &&
    errors[fieldArrayName] &&
    errors[fieldArrayName][fieldArrayIndex] &&
    errors[fieldArrayName][fieldArrayIndex][controlName]
    ? errors[fieldArrayName][fieldArrayIndex][controlName]?.message
    : errors && errors[name]
    ? errors[name]?.message
    : '';
}
