export enum ChatEntityType {
  // Не задано
  NotSet = 1,

  // Чат по заявке на модерацию
  ModerationRequestChat = 2,

  // Чат по заявке на оказание услуг
  OrderChat = 3,
}
