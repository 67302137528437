import { endpoints } from 'api/endpoints';
import { FieldRadioGroup } from 'components/FieldRadioGroup';
import { FieldTextarea } from 'components/redesign/FieldTextArea';
import { FieldUploadFileInput } from 'components/redesign/FieldUploadFileInput';
import { FileBusinessType } from 'enums/fileBusinessType';
import { OrganizationType } from 'enums/organizationType';
import { ESubcontractorModerationRequestStatusId } from 'enums/subcontractorModerationRequestStatus';
import { useAppSelector } from 'hooks/useAppSelector';
import { useFetchData } from 'hooks/useFetchData';
import FieldCustomSelect from 'pages/ModerationRequest/components/FieldCustomSelect';
import FieldSuggestionsCitiesSelect from 'pages/ModerationRequest/components/FieldSuggestionsCitiesSelect';
import {
  ESubcontractorModerFormLabel,
  ESubcontractorModerFormName,
} from 'pages/ModerationRequest/types';
import { FC } from 'react';
import { OptionProps, components } from 'react-select';
import { toast } from 'react-toastify';
import { getSubcontractorNavigation } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorNavigation';
import { getCommonSettlementBySuggestions, getSubjectBySuggestions } from 'services';
import { SettlementShort } from 'types';
import { OptionType } from 'ui/Select';
import { transformToSelectOptions } from 'utils/helpers';
import { UploadFileExtensions } from 'utils/settings';
import { serviceAreasRadio } from './consts';
import { Step1Props } from './types';

export const Step1Fields: FC<Step1Props> = ({
  control,
  watch,
  isFormDisabled,
  errors,
  clearErrors,
  setValue,
  checkFieldInChecklist,
  moderationRequestData,
  organizationTypeId,
  disableNavigate,
}) => {
  const { isFetching: isFetchingFederalDistricts, data: federalDistricts } = useFetchData(
    endpoints.GET_ALL_FEDERAL_DISTRICTS.url(),
    endpoints.GET_ALL_FEDERAL_DISTRICTS.type
  );

  const CityOption = (props: OptionProps<OptionType>) => {
    return (
      <div
        onClick={async () => {
          const oldValue = (props.selectProps.value as OptionType<SettlementShort>[]) || [];
          const response = await getCommonSettlementBySuggestions(props.data.value);
          if (response.status === 200 && response.data.isSuccess) {
            const { commonSettlement } = response.data;
            oldValue.push({
              label: commonSettlement.objectFullName,
              value: commonSettlement,
            });
          } else if (!response.data.isSuccess) {
            toast.error(response.data.message);
          }
          setValue('serviceAreasC', oldValue);
        }}
      >
        <components.Option {...props} />
      </div>
    );
  };
  const RegionOption = (props: OptionProps<OptionType>) => {
    return (
      <div
        onClick={async () => {
          const oldValue = (props.selectProps.value as OptionType<SettlementShort>[]) ?? [];
          const response = await getSubjectBySuggestions(props.data.value);
          if (response.status === 200 && response.data.isSuccess) {
            const { region } = response.data;
            oldValue.push({
              label: region.objectFullName,
              value: region,
            });
            setValue('serviceAreasR', oldValue);
          }
        }}
      >
        <components.Option {...props} />
      </div>
    );
  };

  return (
    <div className="flex w-full max-w-[600px] flex-col gap-8">
      <FieldTextarea
        name="organizationName"
        control={control}
        label="Наименование Организации/ИП"
        isClearable
        maxLength={1000}
        disabled={
          moderationRequestData?.statusId ===
          ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? checkFieldInChecklist &&
              checkFieldInChecklist(
                moderationRequestData,
                ESubcontractorModerFormName.organizationName
              )
            : isFormDisabled
        }
        errorMessage={errors?.organizationName?.message}
        clearErrors={clearErrors}
      />
      <p className="callout-normal -mt-8 !text-text-50">
        Полное наименование как в ЕГРЮЛ либо ЕГРИП
      </p>
      <FieldUploadFileInput
        name={organizationTypeId === OrganizationType.Individual ? 'egripScan' : 'egrulScan'}
        control={control}
        errorMessage={
          organizationTypeId === OrganizationType.Individual
            ? (errors as any)?.egripScan?.message
            : (errors as any)?.egrulScan?.message
        }
        acceptExtensions={
          organizationTypeId === OrganizationType.Individual
            ? UploadFileExtensions.egrip
            : UploadFileExtensions.egrul
        }
        disabled={
          moderationRequestData?.statusId !==
          ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? isFormDisabled
            : organizationTypeId === OrganizationType.Individual
            ? checkFieldInChecklist &&
              checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.egripScan)
            : checkFieldInChecklist &&
              checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.egrulScan)
        }
        fileBusinessTypeId={
          organizationTypeId === OrganizationType.Individual
            ? FileBusinessType.Egrip
            : FileBusinessType.Egrul
        }
        clearErrors={clearErrors}
        uploadLimit={1}
        disableNavigate={disableNavigate}
      />
      <p className="callout-normal -mt-8 !text-text-50">
        Вложите скан-копию выписки{' '}
        {organizationTypeId === OrganizationType.Individual ? 'ЕГРИП' : 'ЕГРЮЛ'}
      </p>
      <span className="headline-bold sm:title2-bold leading-5">
        Выберите тип детализации по территории обслуживания
      </span>
      <div>
        <FieldRadioGroup
          name={ESubcontractorModerFormName.serviceAreaTypeId}
          control={control}
          errors={errors}
          data={serviceAreasRadio}
          disabled={
            moderationRequestData?.statusId ===
            ESubcontractorModerationRequestStatusId.ElaborationRequest
              ? checkFieldInChecklist &&
                checkFieldInChecklist(
                  moderationRequestData,
                  ESubcontractorModerFormName.serviceAreaTypeId
                )
              : isFormDisabled
          }
        />
      </div>
      <div className="mb-4 min-h-[52px]">
        {watch('serviceAreaTypeId') === 3 && !isFetchingFederalDistricts && (
          <FieldCustomSelect
            name={ESubcontractorModerFormName.serviceAreasF}
            control={control}
            label={ESubcontractorModerFormLabel.federalDistricts}
            options={transformToSelectOptions(federalDistricts, 'name', 'id')}
            disabled={
              moderationRequestData?.statusId ===
              ESubcontractorModerationRequestStatusId.ElaborationRequest
                ? checkFieldInChecklist &&
                  checkFieldInChecklist(
                    moderationRequestData,
                    ESubcontractorModerFormName.serviceAreaTypeId
                  )
                : isFormDisabled
            }
            errorMessage={errors?.serviceAreas?.message}
            clearErrors={clearErrors}
          />
        )}
        {watch('serviceAreaTypeId') === 2 && (
          <FieldSuggestionsCitiesSelect
            name={ESubcontractorModerFormName.serviceAreasR}
            control={control}
            label={ESubcontractorModerFormLabel.subjects}
            variant="areas"
            isMulti
            type={2}
            customComponents={{ Option: RegionOption } as any}
            isDisabled={
              moderationRequestData?.statusId ===
              ESubcontractorModerationRequestStatusId.ElaborationRequest
                ? checkFieldInChecklist &&
                  checkFieldInChecklist(
                    moderationRequestData,
                    ESubcontractorModerFormName.serviceAreaTypeId
                  )
                : isFormDisabled
            }
            errorMessage={errors?.serviceAreas?.message}
            clearErrors={clearErrors}
          />
        )}
        {watch('serviceAreaTypeId') === 1 && (
          <FieldSuggestionsCitiesSelect
            name={ESubcontractorModerFormName.serviceAreasC}
            control={control}
            label={ESubcontractorModerFormLabel.cities}
            isMulti
            variant="areas"
            type={1}
            customComponents={{ Option: CityOption } as any}
            isDisabled={
              moderationRequestData?.statusId ===
              ESubcontractorModerationRequestStatusId.ElaborationRequest
                ? checkFieldInChecklist &&
                  checkFieldInChecklist(
                    moderationRequestData,
                    ESubcontractorModerFormName.serviceAreaTypeId
                  )
                : isFormDisabled
            }
            errorMessage={errors?.serviceAreas?.message}
            clearErrors={clearErrors}
          />
        )}
      </div>
    </div>
  );
};
