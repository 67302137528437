import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Spinner } from 'ui/Spinner';
import { Pagination } from 'components/Pagination';
import { AgentRequestCard } from 'components/AgentOrderCard';

import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { AgentRequestFilter, AgentRequestFilterResponse, AgentRequestTabType } from './types';
import useMediaQuery from 'hooks/useMediaQuery';
import { SMALL_DEVICE_MEDIA_QUERY } from 'utils/settings';
import { DesktopNavigationMenu } from 'components/NavigationMenu/desktopNavigationMenu';

type AgentRequestQueryParams = {
  type: AgentRequestTabType;
};

const typeToIdMap = {
  inProgress: 6,
  revision: 7,
  completed: 8,
};

const typeToTitleMap = {
  inProgress: 'В работе',
  revision: 'На доработке',
  completed: 'Завершенные',
};

const fetchRequests = async (filter: AgentRequestFilter) => {
  const response = await request<AgentRequestFilterResponse>(
    endpoints.AGENT_GET_REQUESTS.type,
    endpoints.AGENT_GET_REQUESTS.url(),
    {
      data: JSON.stringify(filter),
      responseType: 'json',
    }
  );
  return response.data;
};

const AgentRequestsPage = () => {
  const { type = 'inProgress' } = useParams<AgentRequestQueryParams>();

  const [enabled, setEnabled] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  const isSmallDevice = useMediaQuery(SMALL_DEVICE_MEDIA_QUERY);

  useEffect(() => {
    window.scrollTo(0, 0);
    setPageNumber(1);
    setEnabled(true);
  }, [type]);

  const handleChangePage = useCallback((value: number) => {
    setPageNumber(value);
    setEnabled(true);
  }, []);

  const { isFetching, data } = useQuery(
    type,
    () => {
      const filters = { pageNumber, pageSize: 5, tabType: typeToIdMap[type] };
      return fetchRequests(filters);
    },
    {
      enabled,
      onSuccess: () => {
        setEnabled(false);
      },
    }
  );

  return (
    <div>
      {isSmallDevice ? <h1>{typeToTitleMap[type]}</h1> : <h1>Работа с заказами</h1>}

      <DesktopNavigationMenu />

      {isFetching && (
        <div className="absolute top-[200px] left-1/2 -translate-x-1/2">
          <Spinner />
        </div>
      )}

      {!isFetching && (!data || !data?.pagedList || !data?.pagedList.length) && (
        <div className="flex flex-col text-center">
          Данные по вкладке отсутствуют или не найдены совпадения по поисковому запросу
        </div>
      )}

      {!isFetching &&
        data &&
        data?.pagedList &&
        data?.pagedList.map((order) => <AgentRequestCard key={order.id} order={order} />)}

      {!isFetching && data?.pagedList && data?.pagedList.length > 0 && (
        <Pagination
          page={data.currentPage}
          pageSize={data.pageSize}
          totalAmount={data.totalCount}
          displayedNumbers={8}
          onChangePage={handleChangePage}
        />
      )}
    </div>
  );
};

export default AgentRequestsPage;
