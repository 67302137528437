import { FC, SVGProps } from 'react';
import {
  AuraOfBohemiaLogo,
  BaltbetLogo,
  BetcityLogo,
  BristolLogo,
  HalvaPochtomatLogo,
  KariLogo,
  MegafonLogo,
  MegafonRetailLogo,
  MtsLogo,
  MvideoEldoradoLogo,
  OrangeBSLogo,
  OstinLogo,
  RostelekomLogo,
  SovcombankLogo,
  SportmasterLogo,
  YotaLogo,
} from 'assets/customers';

export interface Customer {
  id: number;
  name: string;
  logo: FC<SVGProps<SVGSVGElement>>;
}

export const Customers: Customer[] = [
  { id: 1, name: 'ПАО "Совкомбанк"', logo: SovcombankLogo },
  { id: 2, name: 'ООО "Ремо"', logo: BristolLogo },
  { id: 3, name: 'ПАО "Мегафон"', logo: MegafonLogo },
  { id: 4, name: 'АО "Мегафон Ритейл"', logo: MegafonRetailLogo },
  { id: 5, name: 'ООО "Скартел"', logo: YotaLogo },
  { id: 6, name: 'ООО "Ростелеком-Розничные системы"', logo: RostelekomLogo },
  { id: 7, name: 'АО "РТК"', logo: MtsLogo },
  { id: 8, name: 'ООО "ОРАНЖ БИЗНЕС СЕРВИСЕЗ"', logo: OrangeBSLogo },
  { id: 9, name: 'ООО "ЦТБ"', logo: HalvaPochtomatLogo },
  { id: 10, name: 'ООО "МВМ"', logo: MvideoEldoradoLogo },
  { id: 11, name: 'ООО "Эльдорадо"', logo: MvideoEldoradoLogo },
  { id: 12, name: 'ООО "Аура Посуды"', logo: AuraOfBohemiaLogo },
  { id: 13, name: 'ООО "Богемия Плюс К"', logo: AuraOfBohemiaLogo },
  { id: 14, name: 'ООО "Династия"', logo: AuraOfBohemiaLogo },
  { id: 15, name: 'ООО "Спортмастер"', logo: SportmasterLogo },
  { id: 16, name: 'ООО "ОСТИН"', logo: OstinLogo },
  { id: 17, name: 'ООО "Санторин"', logo: BaltbetLogo },
  { id: 18, name: 'ООО "Фортуна"', logo: BetcityLogo },
  { id: 19, name: 'ООО "Кари"', logo: KariLogo },
];
