import { PageWrapper } from 'ui/redesign/PageWrapper';
import { useNavigate } from 'react-router-dom';
import NotFoundIcon from 'assets/images/not-found.png';
import { FC } from 'react';
import { Button } from 'ui/redesign/Button';

const NotFound: FC = () => {
  const navigate = useNavigate();
  const handleNavigateToMain = () => navigate('/help');

  return (
    <PageWrapper title="Ошибка">
      <div className="max-w-[702px] rounded-[10px] bg-base py-7 px-4 shadow-3xl">
        <div className="flex w-full flex-col items-center justify-center">
          <img src={NotFoundIcon} alt="Страница не найдена" className="h-[165px] w-[165px]" />
          <h1 className="title-bold mb-[0.5rem] mt-5 text-text-100">404</h1>
          <p className="headline-normal mb-10 text-text-100">Данная страница не существует</p>

          <Button onClick={handleNavigateToMain}>Вернуться на главную</Button>
        </div>
      </div>
    </PageWrapper>
  );
};

export default NotFound;
