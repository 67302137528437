import { FC } from 'react';
import { Controller } from 'react-hook-form';
import { FieldTextareaProps } from './types';
import { Textarea } from 'ui/redesign/Textarea';

export const FieldTextarea: FC<FieldTextareaProps> = ({
  name,
  control,
  rules,
  label,
  errorMessage,
  onChange,
  clearErrors,
  maxLength,
  ...other
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const onChangeHandle = (value?: React.ChangeEvent<HTMLTextAreaElement>) => {
          const actualValue = value?.target.value;
          field.onChange(actualValue);
          onChange && onChange(actualValue);
          if (name.split('.')[2] === 'description') {
            clearErrors && clearErrors();
          }
          clearErrors && clearErrors(name);
        };
        return (
          <Textarea
            label={label}
            errorMessage={errorMessage}
            value={field.value}
            onChange={onChangeHandle}
            maxLength={maxLength}
            {...other}
          />
        );
      }}
    />
  );
};
