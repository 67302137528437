import { UseFormSetError } from 'react-hook-form';

export const checkName = (
  name: string | null,
  field: string,
  fieldRus: string,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const pattern = /^(?!.*[ \-']{2})(?!.*([А-Яа-яЁё])\1{2})[А-Яа-яЁё][А-Яа-яЁё',\- ]*[А-Яа-яЁё]$/;
  const nameErrorMessage = `${fieldRus} содержать не менее 2 и не более 200 символов`;
  const namePatternErrorMessage = `Допустимые символы для ввода [А-Я а-я ' -] без тройных повторов, минимальная длина 2 символа`;

  if (!name) {
    setError(field, {
      type: 'manual',
      message: nameErrorMessage,
    });
    toastErrors.push(nameErrorMessage);
  } else {
    name = name?.trim();
    if (name.length < 2) {
      setError(field, {
        type: 'manual',
        message: nameErrorMessage,
      });
      toastErrors.push(nameErrorMessage);
    }
    if (!pattern.test(name)) {
      setError(field, {
        type: 'manual',
        message: namePatternErrorMessage,
      });
      toastErrors.push(namePatternErrorMessage);
    }
    if (name.length === 2 && name[0].toLocaleLowerCase() === name[1].toLocaleLowerCase()) {
      setError(field, {
        type: 'manual',
        message: namePatternErrorMessage,
      });
      toastErrors.push(namePatternErrorMessage);
    }
  }
  return toastErrors;
};

export const checkBirthDate = (
  birthDay: string | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const birthDayErrorMessage = 'Дата рождения должна быть заполнена';
  if (!birthDay) {
    setError('birthDate', {
      type: 'manual',
      message: birthDayErrorMessage,
    });
    toastErrors.push(birthDayErrorMessage);
  }
  return toastErrors;
};
