import { analytics, logEvent } from 'firebase.js';
import { useEffect } from 'react';

export const useFirebase = () => {
  useEffect(() => {
    logEvent(analytics, 'app_visited', {
      app_visited: 'Пользователь посетил главную страницу',
    });
  }, []);
};
