import useDebounce from 'hooks/useDebounce';
import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { MultiValue } from 'react-select';
import { getExtendedSuggestions } from 'services';
import { ExtendedSuggestion, SettlementShort } from 'types';
import { Select } from 'ui/redesign/Select';
import { OptionType } from 'ui/redesign/Select/types';
import { SettlementsSelectProps } from './types';

export const CitiesSelect: FC<SettlementsSelectProps> = ({
  control,
  isMulti = false,
  name,
  label,
  onChange,
  setValue,
  isFormDisabled,
  ...rest
}) => {
  const [settlements, setSettlements] = useState<ExtendedSuggestion[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCities('');
    return () => {
      setIsLoading(false);
    };
  }, []);

  const getCities = async (template: string) => {
    setIsLoading(true);
    const response = await getExtendedSuggestions(template, 1);
    if (response.status === 200) {
      setSettlements(response.data);
    }
    setIsLoading(false);
  };

  const debouncedSettlements = useDebounce(getCities, 500);

  const getUniqueCities = (
    citiesArr: ExtendedSuggestion[],
    valueArr?: OptionType<SettlementShort>[]
  ) => {
    let uniqueCities = citiesArr;
    valueArr &&
      valueArr.forEach((value) => {
        uniqueCities = uniqueCities.filter(
          (city) => city.suggestion.data.fias_id !== value.value.fiasId
        );
      });

    return uniqueCities;
  };

  const transformSuggestionToSelectOption = (array: ExtendedSuggestion[]) => {
    return Array.isArray(array)
      ? array.map((item) => ({
          label: item.suggestion.value,
          value: item,
        }))
      : [];
  };

  return (
    <div className="field-select w-full max-w-[640px] px-[1px]">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Select
              name={name}
              variant="cities"
              value={field.value}
              label={label}
              isMulti
              isDisabled={isFormDisabled}
              onChange={(value, meta) => {
                field.onChange(value);
                onChange && onChange(value as MultiValue<OptionType<SettlementShort>>, meta);
              }}
              options={transformSuggestionToSelectOption(getUniqueCities(settlements, field.value))}
              isLoading={isLoading}
              onInputChange={(newValue: string) => {
                if (newValue.length < 3) {
                  return;
                }
                debouncedSettlements(newValue);
              }}
              {...rest}
            />
          );
        }}
      />
    </div>
  );
};
