import { AgentRequestActionType } from 'enums/agentRequestActionType';
import { AgentRequestStatus, AgentRequestStatusName } from 'enums/agentRequestStatus';
import { DateUTC, OrderFile } from 'types';

export interface AgentRequest {
  id: number;
  initiatorFullName: string;
  statusId: number;
  year: number;
  month: number;
  compensation: number;
  doneByExecutorAt: DateUTC;
  rejectionReason: string;
  files: OrderFile[];
  rowVersion: string;
  status: AgentRequestStatus;
  availableActions: AgentRequestActionType[];
}

export const agentRequestStatusById: { [p: number]: AgentRequestStatusName } = {
  [AgentRequestStatus.Iniciation]: AgentRequestStatusName.Iniciation,
  [AgentRequestStatus.Verification]: AgentRequestStatusName.Verification,
  [AgentRequestStatus.NeedsAdditionalWork]: AgentRequestStatusName.NeedsAdditionalWork,
  [AgentRequestStatus.InProgress]: AgentRequestStatusName.InProgress,
  [AgentRequestStatus.Declined]: AgentRequestStatusName.Declined,
  [AgentRequestStatus.ForPayment]: AgentRequestStatusName.ForPayment,
};
