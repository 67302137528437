import { Skeleton } from 'ui/redesign/Loaders/Skeleton';
import { areaInfoSkeletonBlocks, styles, wrapperSkeletonBlocks } from './constants';

export const OrderDetailsSkeleton = () => {
  return (
    <section className="flex flex-col gap-6">
      <Skeleton className="mb-10 h-6 w-[35%] rounded-sm" />

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Skeleton className="min-w-10 min-h-10 h-10 w-10 rounded-[50%]" />
          <div>
            <Skeleton className={styles.label} />
            <Skeleton className="mt-1 h-7 w-[250px] rounded-sm" />
          </div>
        </div>
        <div>
          <Skeleton className={styles.label} />
          <Skeleton className="mt-1 h-7 w-[100px] rounded-sm" />
        </div>
      </div>

      {wrapperSkeletonBlocks.map((_, index) => (
        <div key={index}>
          <Skeleton className={styles.label} />
          <Skeleton className={styles.value} />
        </div>
      ))}

      <div className="ml-2 flex flex-col gap-4">
        {areaInfoSkeletonBlocks.map((_, index) => (
          <div key={index} className="flex items-center gap-2">
            <Skeleton className="h-5 w-5" />
            <Skeleton className={styles.value} />
          </div>
        ))}
      </div>
    </section>
  );
};
