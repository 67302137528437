/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>1</td><td>OrdersBasic</td><td>Базовый отчет по заказам</td></tr><tr><td>2</td><td>OrdersAccounting</td><td>Отчет для бухгалтерии</td></tr></tbody></table></summary>
 */
export enum Outsourcing_SharedEnums_OutsourcingReportTypeEnum {
    '_1' = 1,
    '_2' = 2,
}
