/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>1</td><td>FixedPayment</td><td>Фиксированная оплата за единицу оборудования</td></tr><tr><td>2</td><td>HourlyPayment</td><td>Почасовая оплата (первый час/последующие часы)</td></tr><tr><td>3</td><td>FixedPaymentWithoutRegionalRate</td><td>Фиксированная оплата за единицу измерения</td></tr><tr><td>4</td><td>FixedPaymentWithAdditionalAndWithoutRegionalRate</td><td>Фиксированная оплата за единицу измерения c дополнительной оплатой за каждую последующую единицу</td></tr><tr><td>5</td><td>FixedHourlyRate</td><td>Фиксированная оплата за каждый час</td></tr></tbody></table></summary>
 */
export enum Outsourcing_SharedEnums_CalculationMethodEnum {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
}
