import { useEffect, useState } from 'react';

export const useTimer = (startSecond: number) => {
  const [timeTotal, setTimeTotal] = useState(startSecond);

  const resetTimer = (sec?: number) => {
    setTimeTotal(sec || startSecond);
  };

  useEffect(() => {
    let int: NodeJS.Timeout;
    if (timeTotal > 0) {
      int = setInterval(() => {
        setTimeTotal((sec) => (sec > 0 ? sec - 1 : 0));
      }, 1000);
    }
    return () => {
      clearInterval(int);
    };
  }, [timeTotal]);

  return [timeTotal, resetTimer] as const;
};
