/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>0</td><td>Hidden</td><td>Скрыто</td></tr><tr><td>1</td><td>Active</td><td>Активно</td></tr></tbody></table></summary>
 */
export enum Outsourcing_SharedEnums_DictionaryItemStatusEnum {
    '_0' = 0,
    '_1' = 1,
}
