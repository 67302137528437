import { FieldDatePicker } from 'components/redesign/FieldDatePicker';
import { FieldInput } from 'components/redesign/FieldInput';
import { FC } from 'react';
import { Step1Props } from './types';
import { FieldTextarea } from 'components/redesign/FieldTextArea';

export const Step1Fields: FC<Step1Props> = ({ control, isFormDisabled, errors, clearErrors }) => {
  return (
    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
      <FieldTextarea
        name="lastName"
        control={control}
        label="Фамилия"
        isClearable
        disabled={isFormDisabled}
        errorMessage={errors?.lastName?.message}
        maxLength={200}
        clearErrors={clearErrors}
      />
      <FieldTextarea
        name="firstName"
        control={control}
        label="Имя"
        isClearable
        disabled={isFormDisabled}
        errorMessage={errors?.firstName?.message}
        maxLength={200}
        clearErrors={clearErrors}
      />
      <FieldTextarea
        name="middleName"
        control={control}
        label="Отчество"
        isClearable
        disabled={isFormDisabled}
        errorMessage={errors?.middleName?.message}
        maxLength={200}
        clearErrors={clearErrors}
      />
      <FieldDatePicker
        name="birthDate"
        control={control}
        label="Дата рождения"
        disabled={isFormDisabled}
        errorMessage={errors?.birthDate?.message}
        maxDate={new Date()}
        clearErrors={clearErrors}
      />
      <FieldInput
        name="inn"
        control={control}
        label="ИНН"
        isClearable
        // disabled={isFormDisabled}
        disabled
        errorMessage={errors?.inn?.message}
      />
    </div>
  );
};
