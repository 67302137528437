import { CashIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { OrganizationType } from 'enums/organizationType';
import { subcontractorModerationRequestActions } from 'reduxStore/reducers/SubcontractorModerationSlice';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { subcontractorTypes } from './consts';
import { RadioGroup } from 'ui/RadioGroup';
import { useAppSelector } from 'hooks/useAppSelector';
import { getSubcontractorModerationRequestData } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorModerationRequestData';

export const RedesignSubcontractorModeration: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { organizationTypeId } = useAppSelector(getSubcontractorModerationRequestData);

  const [status, setStatus] = useState<OrganizationType>(
    organizationTypeId ?? OrganizationType.Individual
  );

  const clickHandler = () => {
    dispatch(subcontractorModerationRequestActions.setOrganizationTypeId(status));
    navigate('/moderation-request/step1');
  };

  return (
    <PageWrapper title="Заявка на модерацию">
      <div className="relative flex min-h-layout flex-col justify-between">
        <div className="mb-6 flex flex-col gap-6">
          <div>
            <CashIcon className="mr-3.5" />
            <span className="title2-bold text-text-100">Налоговый статус</span>
          </div>
          <div>
            <RadioGroup
              data={subcontractorTypes}
              value={status}
              onChange={setStatus}
              disabled={false}
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-end">
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={clickHandler}
            type="button"
            className="w-full max-w-[640px] sm:max-w-[150px]"
          >
            ДАЛЕЕ{' '}
          </Button>
        </div>
      </div>
    </PageWrapper>
  );
};
