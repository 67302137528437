import { ChevronIcon, ConfirmTimezoneIcon } from 'assets/icons';
import { FieldInput } from 'components/redesign/FieldInput';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FC, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { ProfileService } from 'services/ProfileService';
import { Button } from 'ui/redesign/Button';
import { timezoneMap } from './consts';
import { ChangeTimeZoneFormProps, ChangeTimezoneForm } from './types';

export const ChangeTimeZoneForm: FC<ChangeTimeZoneFormProps> = ({ timezone }) => {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
    setValue,
    watch,
  } = useForm<ChangeTimezoneForm>({
    mode: 'onChange',
    defaultValues: {
      transformedTimezone: numberToTimezone(timezone),
    },
  });
  const queryResultRef = useRef<ConfirmResponse | null>(null);
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();

  const { mutateAsync: changeTimezone } = useMutation(
    ['changeTimezone'],
    (val: any) =>
      ProfileService.changeTimezone({
        timeZone: val,
      }),
    {
      onSuccess: (data) => {
        if (data.data.isSuccess) {
          queryClient.invalidateQueries({ queryKey: ['getProfile'] });
          dispatch(modalActions.closeModal());
        } else {
          queryResultRef.current = data.data;
        }
      },
    }
  );

  function numberToTimezone(timezoneNumber: number): string {
    const timezone = timezoneMap[timezoneNumber];
    return timezone ? timezone : 'Invalid timezone value';
  }

  function timezoneToNumber(timezone: string): number {
    const newTimezone = Object.keys(timezoneMap).find(
      (key) => timezoneMap[parseInt(key)] === timezone
    );
    return newTimezone ? parseInt(newTimezone) : -1;
  }

  const confirmHandler: SubmitHandler<ChangeTimezoneForm> = async () => {
    const newTimezone = timezoneToNumber(getValues('transformedTimezone'));
    await changeTimezone(newTimezone);
  };

  const changeZone = (step: number) => {
    let newZone = timezoneToNumber(watch('transformedTimezone')) + step;
    if (newZone > 12) {
      newZone = 0;
    }
    if (newZone < 0) {
      newZone = 12;
    }
    return timezoneMap[newZone];
  };

  return (
    <div className="p-4 sm:p-0">
      <form onSubmit={handleSubmit(confirmHandler)}>
        <div className="my-4 text-center">
          <ConfirmTimezoneIcon />
        </div>
        <FieldInput
          name="transformedTimezone"
          control={control}
          label="Часовой пояс (UTC)"
          readOnly
          className="headline-normal -mr-10 border-0 border-b-2 border-solid border-light-50 text-text-100"
          icons={[
            {
              icon: <ChevronIcon className="fill-text-50 hover:fill-text-100" />,
              onClick: () => setValue('transformedTimezone', changeZone(-1)),
            },
            {
              icon: <ChevronIcon className="rotate-180 fill-text-50 hover:fill-text-100" />,
              onClick: () => setValue('transformedTimezone', changeZone(1)),
            },
          ]}
        />
        {errors && <div className="text-danger">{errors['transformedTimezone']?.message}</div>}
        <div className="mt-6 text-center sm:text-right">
          <Button disabled={isSubmitting} type="submit">
            ИЗМЕНИТЬ ЧАСОВОЙ ПОЯС
          </Button>
        </div>
      </form>
    </div>
  );
};
