import { useAppSelector } from 'hooks/useAppSelector';
import { FC } from 'react';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { Input } from 'ui/redesign/Input';

export const Step5Fields: FC = () => {
  const userProfile = useAppSelector(getProfile);

  return (
    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
      <Input value={userProfile.phoneNumber ?? ''} disabled label="Телефон" />
      <Input value={userProfile.email ?? ''} disabled label="Электронная почта" />
    </div>
  );
};
