import { NavLink } from 'react-router-dom';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import ProfileHead from './ProfileHead';
import ProfileMenuItem from './ProfileMenuItem';
import { profileLinks } from './constants';

export const Profile = () => {
  return (
    <PageWrapper title="Профиль">
      <div className="flex flex-col gap-6">
        <ProfileHead />
        {profileLinks.map(({ link, title, Icon }) => (
          <NavLink className="max-w-[600px]" key={link} to={link}>
            <ProfileMenuItem title={title} Icon={Icon} />
          </NavLink>
        ))}
      </div>
    </PageWrapper>
  );
};
