import { ESubcontractorModerationRequestStatusId } from 'enums/subcontractorModerationRequestStatus';
import { Button } from 'ui/redesign/Button';
import { ERequestHandler } from './consts';
import { ButtonsProps } from './types';
import { ButtonVariant } from 'ui/redesign/Button/constants';

export const Buttons = ({ status, submitHandler, id, isNavigateDisabled }: ButtonsProps) => {
  if (id) {
    if (status === ESubcontractorModerationRequestStatusId.Draft) {
      return (
        <div className="flex flex-col gap-6 sm:flex-row">
          <Button
            type="button"
            className="btn-dark flex w-full max-w-[640px] items-center justify-center gap-2 self-start sm:max-w-[300px]"
            onClick={() => submitHandler(ERequestHandler.MODERATION)}
            disabled={isNavigateDisabled}
          >
            ОТПРАВИТЬ НА МОДЕРАЦИЮ
          </Button>
          <Button
            type="button"
            className="btn-accent-pink flex w-full max-w-[640px] items-center justify-center gap-3 self-start sm:max-w-[240px]"
            onClick={() => submitHandler(ERequestHandler.DRAFT)}
            disabled={isNavigateDisabled}
            variant={ButtonVariant.SUCCESS}
          >
            ОБНОВИТЬ ЧЕРНОВИК
          </Button>
        </div>
      );
    } else if (status === ESubcontractorModerationRequestStatusId.ElaborationRequest) {
      return (
        <Button
          type="button"
          className="btn-accent-pink w-full max-w-[640px] sm:max-w-[300px]"
          onClick={() => submitHandler(ERequestHandler.MODERATION)}
          disabled={isNavigateDisabled}
        >
          ПРЕДОСТАВИТЬ УТОЧНЕНИЕ
        </Button>
      );
    } else {
      return null;
    }
  } else {
    return (
      <Button
        type="button"
        className="btn-accent-pink flex w-full max-w-[640px] items-center justify-center gap-3 self-start sm:max-w-[300px]"
        onClick={() => submitHandler(ERequestHandler.DRAFT)}
        variant={ButtonVariant.SUCCESS}
        disabled={isNavigateDisabled}
      >
        СОХРАНИТЬ ЧЕРНОВИК
      </Button>
    );
  }
};
