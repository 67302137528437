import { UseFormSetError } from 'react-hook-form';

export const checkСertificateElectricalSafetyScan = (
  certificateElectricalSafetyQuantity: number | null,
  certificateElectricalSafetyScan: OrderFile[] | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (!certificateElectricalSafetyQuantity && !certificateElectricalSafetyScan?.length) {
    return toastErrors;
  }
  if (certificateElectricalSafetyQuantity && !certificateElectricalSafetyScan?.length) {
    const scanErrorMessage = 'Необходимо приложить не более 5 файлов';
    setError('certificateElectricalSafetyScan', {
      type: 'manual',
      message: scanErrorMessage,
    });
    toastErrors.push(scanErrorMessage);
  }
  if (!certificateElectricalSafetyQuantity && certificateElectricalSafetyScan?.length) {
    const quantityErrorMessage = 'Количество сотрудников не может быть равно 0 и превышать 9999';
    setError('certificateElectricalSafetyQuantity', {
      type: 'manual',
      message: quantityErrorMessage,
    });
    toastErrors.push(quantityErrorMessage);
  }
  return toastErrors;
};

export const checkСertificateWorkHeightScan = (
  certificateWorkHeightQuantity: number | null,
  certificateWorkHeightScan: OrderFile[] | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (!certificateWorkHeightQuantity && !certificateWorkHeightScan?.length) {
    return toastErrors;
  }
  if (certificateWorkHeightQuantity && !certificateWorkHeightScan?.length) {
    const scanErrorMessage = 'Необходимо приложить не более 5 файлов';
    setError('certificateWorkHeightScan', {
      type: 'manual',
      message: scanErrorMessage,
    });
    toastErrors.push(scanErrorMessage);
  }
  if (!certificateWorkHeightQuantity && certificateWorkHeightScan?.length) {
    const quantityErrorMessage = 'Количество сотрудников не может быть равно 0 и превышать 9999';
    setError('certificateWorkHeightQuantity', {
      type: 'manual',
      message: quantityErrorMessage,
    });
    toastErrors.push(quantityErrorMessage);
  }
  return toastErrors;
};
