import { HistoryIcon } from 'assets/icons';
import { ModerationRequestStatusId } from 'enums/moderationRequestStatus';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FormControl } from 'pages/ModerationRequest/redesign/FormControl';
import { FormWrapper } from 'pages/ModerationRequest/redesign/FormWrapper';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSubcontractorModerationRequestData } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorModerationRequestData';
import { OrganizationType } from 'enums/organizationType';
import { subcontractorModerationRequestActions } from 'reduxStore/reducers/SubcontractorModerationSlice';
import { getSubcontractorNavigation } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorNavigation';
import { Step2Fields } from './Step2Fields';
import {
  checkEmail,
  checkInn,
  checkPhoneNumber,
  checkSubcontractorOgrn,
  checkSubcontractorOgrnip,
} from './consts';
import { Step2Payload } from './types';
import { toast } from 'react-toastify';
import { ERequestHandler } from '../finalStep/consts';

export const Step2Subcontractor = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const step = pathname.split('step')[1];

  const {
    legalAddress,
    address,
    phoneNumber,
    organizationTypeId,
    email,
    inn,
    ogrnip,
    innScan,
    ogrnipScan,
    ogrn,
    ogrnScan,
  } = useAppSelector(getSubcontractorModerationRequestData);
  const isNavigateDisabled = useAppSelector(getSubcontractorNavigation);
  const disableNavigate = (value: boolean) => {
    dispatch(subcontractorModerationRequestActions.setIsNavigateDisabled(value));
  };

  const innFile = innScan ? [innScan] : null;
  const ogrnipFile = ogrnipScan ? [ogrnipScan] : null;
  const ogrnFile = ogrnScan ? [ogrnScan] : null;

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<Step2Payload>({
    defaultValues: {
      legalAddress,
      address,
      phoneNumber,
      email,
      inn,
      ogrnip,
      ogrn,
      innScan: innFile,
      ogrnipScan: ogrnipFile,
      ogrnScan: ogrnFile,
    },
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<Step2Payload> = (data, event) => {
    clearErrors();
    const newInnScan = data.innScan ? (data as any).innScan[0] : null;
    const newOgrnipScan = data.ogrnipScan ? (data as any).ogrnipScan[0] : null;
    const newOgrnScan = data.ogrnScan ? (data as any).ogrnScan[0] : null;

    const toastErrors: string[] = [];

    if (data.phoneNumber) {
      checkPhoneNumber(data.phoneNumber, ERequestHandler.DRAFT, toastErrors, setError);
    }
    if (data.email) {
      checkEmail(data.email, 100, ERequestHandler.MODERATION, toastErrors, setError);
    }
    if (data.inn || newInnScan) {
      checkInn(
        data.inn,
        newInnScan,
        organizationTypeId,
        ERequestHandler.DRAFT,
        toastErrors,
        setError
      );
    }
    if ((data.ogrnip || newOgrnipScan) && organizationTypeId === OrganizationType.Individual) {
      checkSubcontractorOgrnip(
        data.ogrnip,
        newOgrnipScan,
        ERequestHandler.DRAFT,
        toastErrors,
        setError
      );
    }
    if (organizationTypeId === OrganizationType.LLC) {
      checkSubcontractorOgrn(data.ogrn, newOgrnScan, ERequestHandler.DRAFT, toastErrors, setError);
    }
    if (toastErrors.length) {
      const onlyUnique = new Set(toastErrors);
      Array.from(onlyUnique).forEach((message: string) => {
        toast.error(message);
      });
      return;
    }

    dispatch(
      organizationTypeId === OrganizationType.Individual
        ? subcontractorModerationRequestActions.setMainRequisites({
            ...data,
            innScan: newInnScan,
            ogrnipScan: newOgrnipScan,
          })
        : subcontractorModerationRequestActions.setLLCMainRequisites({
            ...data,
            innScan: newInnScan,
            ogrnScan: newOgrnScan,
          })
    );
    const nativeEvent = event?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter as HTMLButtonElement;
    if (isNavigateDisabled) {
      toast.error('Дождитесь удаления файла');
    } else if (submitter.name === 'forward') {
      const nextStep = +step + 1;
      navigate(`/moderation-request/step${nextStep}`);
    } else if (submitter.name === 'back') {
      const prevStep = +step - 1;
      prevStep
        ? prevStep && navigate(`/moderation-request/step${prevStep}`)
        : navigate(`/moderation-request`);
    }
  };

  return (
    <FormWrapper
      title="Основные реквизиты"
      status={ModerationRequestStatusId.Draft}
      Icon={HistoryIcon}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex min-h-form flex-col justify-between">
        <Step2Fields
          errors={errors}
          control={control}
          isFormDisabled={false}
          clearErrors={clearErrors}
          organizationTypeId={organizationTypeId}
          disableNavigate={disableNavigate}
        />
        <FormControl />
      </form>
    </FormWrapper>
  );
};
