import { SubcontractorType } from './types';

export const subcontractorTypes = [
  {
    label: 'ИП (индивидуальный предприниматель)',
    value: SubcontractorType.Individual,
  },
  {
    label: 'ООО (Общество с ограниченной ответственностью)',
    value: SubcontractorType.LLC,
  },
];
