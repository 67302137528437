import { useState, useMemo, Children } from 'react';

import useRange from './useRange';
import Button from './Button';
import { ReactComponent as LeftArrowIcon } from 'assets/left-arrow.svg';

interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  /**
   * Внешнее состояние компонента, полезно если мы используем несколько компонентов пагинации на странице с одним состоянием
   */
  page?: number;
  /**
   * Максимальное количество отображаемых элементов на странице
   */
  pageSize?: number;
  /**
   * Общее количество всех элементов
   */
  totalAmount?: number;
  /**
   * Количество нумерованных кнопок (включая "...") переключения страниц для отображения
   */
  displayedNumbers?: number;
  onChangePage?: (page: number) => void;
}

export const Pagination = ({
  children,
  page: pageProps,
  pageSize = 0,
  totalAmount = 0,
  displayedNumbers = 0,
  onChangePage,
  ...otherProps
}: PaginationProps) => {
  const [page, setPage] = useState(1);

  // Задаём приоритет для внешнего состояния, если оно имеется
  const currentPage = pageProps ?? page;

  // Определяем количество страниц
  const { itemsCount, pagesCount } = useMemo(() => {
    const childrenCount = Children.count(children) || totalAmount;
    // Если значение `count`  некорректно для вычисления присваиваем длину всего массива элементов
    const itemsCount = pageSize <= 0 ? childrenCount : pageSize;
    const pagesCount = Math.ceil(childrenCount / itemsCount);

    return { itemsCount, pagesCount };
  }, [children, pageSize, totalAmount]);

  // Определяем элементы для отображения на текущей странице
  const displayedChildren = useMemo(() => {
    return Children.toArray(children).filter((child, index) => {
      const from = (currentPage - 1) * itemsCount;
      const to = from + itemsCount;
      return index >= from && index < to;
    });
  }, [children, itemsCount, currentPage]);

  // Определяем диапазон отображаемых кнопок
  const range = useRange({
    page: currentPage,
    pagesCount,
    displayedNumbers,
  });

  const handleChangePage = (selectedPage: number) => {
    if (pageProps === undefined) setPage(selectedPage);
    onChangePage?.(selectedPage);
  };

  return (
    <div {...otherProps}>
      {displayedChildren}

      <div className="flex flex-wrap gap-3">
        {pagesCount > 1 && (
          <>
            <Button
              disabled={currentPage === 1}
              onClick={() => handleChangePage(currentPage - 1)}
              aria-label="Предыдущая страница"
            >
              <LeftArrowIcon
                className={`w-4 h-4 ${currentPage === 1 ? 'text-gray-medium' : 'text-black'}`}
              />
            </Button>

            {[...new Array(pagesCount)].map((item, index, array) => {
              const pageNumber = index + 1;

              const button = (label: string | number) => (
                <Button
                  key={pageNumber}
                  active={currentPage === pageNumber}
                  onClick={() => handleChangePage(pageNumber)}
                  disabled={label === '...'}
                >
                  {label}
                </Button>
              );

              if (pageNumber === 1) {
                return button(pageNumber);
              }

              if (pageNumber === 2) {
                const label = currentPage > 4 ? '...' : pageNumber;
                return button(label);
              }

              if (pageNumber >= range.from && pageNumber <= range.to) {
                return button(pageNumber);
              }

              if (pageNumber === array.length - 1) {
                const label = range.to < array.length - 1 ? '...' : pageNumber;
                return button(label);
              }

              if (pageNumber === array.length) {
                return button(pageNumber);
              }

              return null;
            })}

            <Button
              disabled={currentPage === pagesCount}
              onClick={() => handleChangePage(currentPage + 1)}
            >
              <LeftArrowIcon
                className={`w-4 h-4 rotate-180 ${
                  currentPage === pagesCount ? 'text-gray-medium' : 'text-black'
                }`}
              />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
