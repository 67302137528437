import { memo } from 'react';
import { Control, Controller, UseFormClearErrors } from 'react-hook-form';
import { CustomSelect, CustomSelectProps } from 'ui/CustomSelect';

interface FieldCustomSelectProps extends CustomSelectProps {
  control: Control;
  errorMessage?: string;
  clearErrors?: UseFormClearErrors<any>;
}

const FieldCustomSelect = ({
  name,
  label,
  onChange,
  control,
  errorMessage,
  options,
  clearErrors,
  ...rest
}: FieldCustomSelectProps) => {
  return (
    <div className="field-select">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <CustomSelect
              name={name}
              value={field.value}
              label={label}
              onChange={(value) => {
                field.onChange(value);
                onChange && onChange(value);
                clearErrors && clearErrors('serviceAreas');
              }}
              options={options}
              errorMessage={errorMessage}
              {...rest}
            />
          );
        }}
      />
    </div>
  );
};

export default memo(FieldCustomSelect);
