import { timestampYear } from 'const';
import { editRuLocale } from 'utils/helpers';

export const maxDateDefault = new Date(Date.now() + timestampYear);
export const variants = {
  initial: {
    opacity: 0,
    marginTop: 0,
  },
  animate: {
    opacity: 1,
    marginTop: 10,
  },
};
export const ruLocale = editRuLocale();
