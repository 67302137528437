import { ECitizenship } from 'enums/citizenship';
import { UseFormSetError } from 'react-hook-form';
import { RequestHandler } from '../finalStep/consts';

export const citizenships = [
  { label: 'Российская Федерация', value: ECitizenship.RussianFederation },
  { label: 'Республика Казахстан', value: ECitizenship.RepublicOfKazakhstan },
  { label: 'Республика Беларусь', value: ECitizenship.RepublicOfBelarus },
  { label: 'Киргизская Республика', value: ECitizenship.KyrgyzRepublic },
  { label: 'Республика Армения', value: ECitizenship.RepublicOfArmenia },
];

export const checkSeries = (
  series: string | null,
  requestHandler: RequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (!series && requestHandler === RequestHandler.DRAFT) {
    return toastErrors;
  }
  if (requestHandler === RequestHandler.MODERATION || series) {
    if (!series || series.replace(/[_ ]/g, '').length < 4) {
      const seriesErrorMessage = 'Серия паспорта должна состоять из 4 цифр';
      setError('series', {
        type: 'manual',
        message: seriesErrorMessage,
      });
      toastErrors.push(seriesErrorMessage);
    }
  }
  return toastErrors;
};

export const checkNumber = (
  number: string | null,
  requestHandler: RequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (!number && requestHandler === RequestHandler.DRAFT) {
    return toastErrors;
  }
  if (requestHandler === RequestHandler.MODERATION || number) {
    if (!number || number.replace(/_/g, '').length < 6) {
      const numberErrorMessage = 'Номер паспорта должен состоять из 6 цифр';
      setError('number', {
        type: 'manual',
        message: numberErrorMessage,
      });
      toastErrors.push(numberErrorMessage);
    }
  }
  return toastErrors;
};

export const checkUnitCode = (
  unitCode: string | null,
  requestHandler: RequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (!unitCode && requestHandler === RequestHandler.DRAFT) {
    return toastErrors;
  }
  if (unitCode || requestHandler === RequestHandler.MODERATION) {
    if (!unitCode || unitCode.replace(/[_-]/g, '').length < 6) {
      const unitCodeErrorMessage = 'Код подразделения должен состоять из 6 цифр';
      setError('unitCode', {
        type: 'manual',
        message: unitCodeErrorMessage,
      });
      toastErrors.push(unitCodeErrorMessage);
    }
  }
  return toastErrors;
};

export const checkDateOfIssue = (
  dateOfIssue: string | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const dateOfIssueErrorMessage = 'Дата выдачи должна быть заполнена';
  if (!dateOfIssue) {
    setError('dateOfIssue', {
      type: 'manual',
      message: dateOfIssueErrorMessage,
    });
    toastErrors.push(dateOfIssueErrorMessage);
  }
  return toastErrors;
};

export const checkIssueBy = (
  issuedBy: string | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const issuedByErrorMessage =
    'Поле "Кем выдан паспорт" должно быть заполнено и не превышать 250 символов';
  if (!issuedBy) {
    setError('issuedBy', {
      type: 'manual',
      message: issuedByErrorMessage,
    });
    toastErrors.push(issuedByErrorMessage);
  }
  return toastErrors;
};

export const checkBirthPlace = (
  birthPlace: string | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const birthPlaceErrorMessage =
    'Поле "Место рождения" должно быть заполнено и не превышать 255 символов';
  if (!birthPlace) {
    setError('birthPlace', {
      type: 'manual',
      message: birthPlaceErrorMessage,
    });
    toastErrors.push(birthPlaceErrorMessage);
  }
  return toastErrors;
};

export const checkRegistrationAddress = (
  registrationAddress: string | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const registrationAddressErrorMessage =
    'Поле "Адрес регистрации" должно быть заполнено и не превышать 255 символов';
  if (!registrationAddress) {
    setError('registrationAddress', {
      type: 'manual',
      message: registrationAddressErrorMessage,
    });
    toastErrors.push(registrationAddressErrorMessage);
  }
  return toastErrors;
};

export const checkPassportFiles = (
  files: OrderFile[] | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const filesErrorMessage = 'Приложите разворот с фотографией, страницы с пропиской';
  if (!files?.length) {
    setError('files', {
      type: 'manual',
      message: filesErrorMessage,
    });
    toastErrors.push(filesErrorMessage);
  }
  return toastErrors;
};

export const checkExecutorPhotos = (
  executorPhotos: OrderFile[] | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const executorPhotosErrorMessage =
    'Загрузите фото с паспортом, на котором видно ваше лицо и информация из паспорта';
  if (!executorPhotos?.length) {
    setError('executorPhotos', {
      type: 'manual',
      message: executorPhotosErrorMessage,
    });
    toastErrors.push(executorPhotosErrorMessage);
  }
  return toastErrors;
};
