import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AnyObjectSchema, boolean, object } from 'yup';

import { File } from 'ui/File';
import SimpleModal from 'ui/Modal/SimpleModal';
import { Spinner } from 'ui/Spinner';
import { FieldCheckbox } from './FieldCheckbox';

import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { monthsMap } from 'const';
import { AgentRequestStatus } from 'enums/agentRequestStatus';
import { useAppSelector } from 'hooks/useAppSelector';
import { getTimezone } from 'reduxStore/reducers/ProfileSlice/selectors/getTimezone';
import { fetchFile, formatAmount, getDateWithTimezone, handleFileDownload } from 'utils/helpers';
import { ROUBLE_SIGN } from 'utils/settings';
import { AgentRequest, agentRequestStatusById } from '../types';
import { toast } from 'react-toastify';

const schema: AnyObjectSchema = object().shape({
  checkbox: boolean().isTrue('Необходимо поставить отметку о согласии'),
});

export const RequestTab = ({
  agentRequest,
  refetch,
  isLoading,
}: {
  agentRequest: AgentRequest | undefined;
  refetch: () => void;
  isLoading: boolean;
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [decision, setDecision] = useState<'APPROVE' | 'DECLINE' | null>(null);

  const methods = useForm<{ checkbox: boolean }>({
    defaultValues: {
      checkbox: false,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    watch,
    control,
    formState: { errors },
    reset,
  } = methods;

  const timeZone = useAppSelector(getTimezone);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (decision) {
      handleModalOpen();
    }
    return () => {
      handleModalClose();
    };
  }, [decision]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const approveRequest = async () => {
    const requestBody = {
      requestId: agentRequest?.id,
      rowVersion: agentRequest?.rowVersion,
      acknowledged: true,
    };
    const response = await request(
      endpoints.AGENT_APPROVE_REQUEST.type,
      endpoints.AGENT_APPROVE_REQUEST.url(agentRequest?.id),
      {
        data: requestBody,
      }
    );
    if (response.status === 200) {
      handleModalClose();
      refetch();
    }
  };

  const declineRequest = async (rejectionReason: string) => {
    const requestBody = {
      requestId: agentRequest?.id,
      rowVersion: agentRequest?.rowVersion,
      rejectionReason,
    };
    const response = await request(
      endpoints.AGENT_DECLINE_REQUEST.type,
      endpoints.AGENT_DECLINE_REQUEST.url(agentRequest?.id),
      {
        data: requestBody,
      }
    );
    if (response.status === 200) {
      handleModalClose();
      refetch();
    }
  };

  const ApproveModal = () => {
    return (
      <form className="flex flex-col justify-center gap-8 px-4 pt-8 pb-4">
        <div>
          <FieldCheckbox
            control={control}
            errors={errors}
            label="Я подтверждаю, что согласен с условиями заявки"
            name="checkbox"
          />
        </div>
        <button
          className="btn-danger max-w-[128px] self-center"
          type="button"
          disabled={!watch('checkbox')}
          onClick={approveRequest}
        >
          Сохранить
        </button>
      </form>
    );
  };

  const DeclineModal = () => {
    const [textareaText, setTextareaText] = useState('');
    return (
      <div className="flex flex-col justify-center gap-8 px-4 pt-8 pb-4">
        <h3 className="m-0">Укажите причину отказа</h3>
        <textarea
          className="textarea text-xs"
          onChange={(e) => {
            setTextareaText(e.target.value);
          }}
          placeholder="Введите комментарий"
          rows={5}
          value={textareaText}
        />
        <button
          className="btn-danger max-w-[128px] self-center"
          type="button"
          disabled={textareaText === ''}
          onClick={() =>
            textareaText.length > 1000
              ? toast.error(`'Причина отказа' должно быть длиной не более 1000 символов`)
              : declineRequest(textareaText)
          }
        >
          Сохранить
        </button>
      </div>
    );
  };

  const ModalContent = () => {
    switch (decision) {
      case 'APPROVE':
        return <ApproveModal />;
      case 'DECLINE':
        return <DeclineModal />;
      default:
        return null;
    }
  };

  if (isLoading) {
    return <Spinner />;
  }
  if (agentRequest) {
    const {
      status,
      year,
      month,
      compensation,
      files,
      rejectionReason,
      initiatorFullName,
      doneByExecutorAt,
    } = agentRequest;
    return (
      <>
        <FormProvider {...methods}>
          <SimpleModal
            open={modalOpen}
            onClose={() => {
              handleModalClose();
              reset();
            }}
          >
            <ModalContent />
          </SimpleModal>

          <div className="mb-4">
            <h6>Инициатор</h6>
            <p className="text-base font-semibold leading-[20px]">{initiatorFullName}</p>
          </div>
          <div className="mb-4">
            <h6>Статус</h6>
            <p className="text-base font-semibold leading-[20px]">
              {agentRequestStatusById[status]}
            </p>
          </div>
          <div className="mb-4">
            <h6>Год</h6>
            <p className="text-base font-semibold leading-[20px]">{year}</p>
          </div>
          <div className="mb-4">
            <h6>Месяц</h6>
            <p className="text-base font-semibold leading-[20px]">{monthsMap[month]}</p>
          </div>
          <div className="mb-4">
            <h6>Вознаграждение</h6>
            <p className="text-base font-semibold leading-[20px]">
              {formatAmount(compensation)}&nbsp;{ROUBLE_SIGN}
            </p>
          </div>

          {files.length > 0 && (
            <div className="mb-4 max-w-[350px]">
              {agentRequest.files.map((file) => {
                return (
                  <File
                    file={file}
                    key={file.downloadFileIdHash}
                    onDownload={() => handleFileDownload(fetchFile(file.downloadFileIdHash))}
                  />
                );
              })}
            </div>
          )}

          {status === AgentRequestStatus.InProgress && (
            <>
              <button
                className="btn-dark mt-8 mr-6 w-full max-w-[288px] self-center"
                onClick={() => {
                  handleModalOpen();
                  setDecision('APPROVE');
                }}
                type="button"
              >
                Подтвердить
              </button>
              <button
                className="btn-accent-pink mt-8 w-full max-w-[288px] self-center"
                onClick={() => {
                  handleModalOpen();
                  setDecision('DECLINE');
                }}
                type="button"
              >
                Отклонить
              </button>
            </>
          )}
          {status === AgentRequestStatus.ForPayment && (
            <div className="mb-4">
              <h6>Дата подписания акта</h6>
              <p className="text-base font-semibold leading-[20px]">
                {getDateWithTimezone(doneByExecutorAt, timeZone, 'dd.MM.yyyy')}
              </p>
            </div>
          )}
          {status === AgentRequestStatus.Declined && (
            <div className="mb-4">
              <h6>Комментарий исполнителя</h6>
              <p className="text-base leading-[20px]">{rejectionReason}</p>
            </div>
          )}
        </FormProvider>
      </>
    );
  }

  return null;
};
