import { EditIcon, SuccessIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useMediaQuery from 'hooks/useMediaQuery';
import { FC } from 'react';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { Input } from 'ui/redesign/Input';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { IS_SMALL_DEVICE } from 'utils/settings';
import { ChangePhoneNumberForm } from './ChangePhoneNumberForm';
import { ConfirmEmailForm } from './ConfirmEmailForm';

export const ContactData: FC = () => {
  const userProfile = useAppSelector(getProfile);
  const dispatch = useAppDispatch();
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  const changePhoneHandler = () => {
    dispatch(
      modalActions.handleOpenModal({
        content: <ChangePhoneNumberForm currentPhoneNumber={userProfile.phoneNumber ?? ''} />,
        options: {
          title: 'Изменить номер телефона',
          modalClassName: 'w-full',
          withBackButton: isSmall,
        },
      })
    );
  };

  const changeEmailHandler = () => {
    dispatch(
      modalActions.handleOpenModal({
        content: (
          <ConfirmEmailForm email={userProfile?.email ?? ''} userId={String(userProfile.id)} />
        ),
        options: {
          title: 'Подтвердить E-mail',
          modalClassName: 'w-full sm:max-h-screen',
          withBackButton: isSmall,
        },
      })
    );
  };

  return (
    <PageWrapper title="Контактные данные">
      <form className="grid grid-cols-[minmax(0,600px)] gap-6">
        <Input
          value={userProfile?.phoneNumber ?? ''}
          label="Телефон"
          placeholder="Укажите номер телефона"
          mask="+7 (999) 999-99-99"
          isClearable={false}
          icons={
            userProfile.executorType === 1 ||
            userProfile.executorType === 2 ||
            userProfile.executorType === 4 ||
            userProfile.executorType === null
              ? [
                  {
                    icon: <EditIcon className="-ml-8" />,
                    onClick: changePhoneHandler,
                  },
                ]
              : []
          }
          readOnly
        />
        <div>
          <Input
            value={userProfile?.email ?? ''}
            label="Электронная почта"
            readOnly
            icons={
              userProfile.emailConfirmed
                ? [
                    {
                      icon: <SuccessIcon className="-ml-7" />,
                    },
                  ]
                : []
            }
          />
          {!userProfile.emailConfirmed && (
            <p
              className="callout-paragraphs-medium cursor-pointer text-primary-60"
              onClick={
                userProfile.executorType === 1 ||
                userProfile.executorType === 2 ||
                userProfile.executorType === 4 ||
                userProfile.executorType === null
                  ? changeEmailHandler
                  : undefined
              }
            >
              Отправить письмо с подтверждением
            </p>
          )}
        </div>
      </form>
    </PageWrapper>
  );
};
