import { AnimatePresence, motion } from 'framer-motion';
import { ValidationErorProps } from './types';
import { FC } from 'react';
import classNames from 'classnames';

const variants = {
  initial: {
    opacity: 0,
    marginTop: 0,
  },
  animate: {
    opacity: 1,
    marginTop: 5,
  },
};

export const ValidationError: FC<ValidationErorProps> = ({ errorMessage, className }) => {
  return (
    <AnimatePresence>
      {errorMessage && (
        <motion.p
          variants={variants}
          initial="initial"
          animate="animate"
          exit="initial"
          transition={{ duration: 0.33 }}
          className={classNames('callout-normal !text-danger', className)}
        >
          {errorMessage}
        </motion.p>
      )}
    </AnimatePresence>
  );
};
