import {
  MinusIcon,
  PathIcon,
  TravelIcon,
  ShapeIcon,
  MobileIcon,
  SettingsIcon,
  TelephoneIcon,
  UserIcon,
} from 'assets/icons';

export interface IStatusOption extends OptionModel {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const statusOptions: IStatusOption[] = [
  {
    Icon: PathIcon,
    label: 'Готов брать заявки',
    value: 'Готов брать заявки',
  },
  {
    Icon: MinusIcon,
    label: 'Не готов брать заявки',
    value: 'Не готов брать заявки',
  },
  {
    Icon: TravelIcon,
    label: 'В отпуске',
    value: 'В отпуске',
  },
  {
    Icon: ShapeIcon,
    label: 'Другое',
    value: 'Другое',
  },
];

export interface ProfileLink {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  link: string;
}

export const profileLinks: ProfileLink[] = [
  { link: '/profile/private', title: 'Личные данные', Icon: UserIcon },
  { link: '/profile/contacts', title: 'Контактные данные', Icon: MobileIcon },
  { link: '/profile/settings', title: 'Настройки', Icon: SettingsIcon },
  { link: '/profile/support', title: 'Техническая поддержка', Icon: TelephoneIcon },
];
