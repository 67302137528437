export enum OrderStatusesEnum {
  ExecutorNotAssigned = 1, // Исполнитель не назначен
  DispatcherAssigned = 2, // Диспетчер назначен
  InProgress = 3, // В работе
  Refused = 4, // От заявки отказались
  Review = 5, // На проверке
  WorkApproved = 6, // Выполнена
  Cancelled = 7, // Отменена
  CancelledWithPayment = 8,
  UnderInspection = 9, // Ожидает подтверждения
}
