import { styled } from 'styled-components';
import Slider from 'react-slick';

export const StyledSlider = styled(Slider)`
  max-width: 702px;
  box-shadow: 0 2px 39px 0 rgba(44, 63, 112, 0.05), 0 2px 22px 0 rgba(0, 0, 0, 0.05);
  padding-bottom: 40px;
  border-radius: 10px;

  .slick-dots {
    display: flex !important;
    align-items: center;
    gap: 8px;
    justify-content: center;
  }

  .slick-active {
    button {
      background: #4251da;
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 50;

    &.slick-disabled {
      cursor: default;

      svg {
        fill: #d9d9d9;
        color: #d9d9d9;
      }
    }

    &.slick-prev {
      left: 10%;
    }

    &.slick-next {
      right: 10%;
    }
  }
`;
