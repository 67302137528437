import { FC } from 'react';
import { PreviewProps } from './types';
import { Pdf } from './Pdf';

export const Preview: FC<PreviewProps> = ({ file }) => {
  switch (file.type) {
    case 'image/png':
    case 'image/jpg':
    case 'image/jpeg':
      return (
        <img
          src={`data:${file.type};base64,${file.content}`}
          className="mx-auto max-h-[80vh] w-fit overflow-y-auto object-contain"
          alt="image"
        />
      );
    case 'application/pdf':
      return <Pdf data={`data:application/pdf;base64,${file.content}`} />;
    default:
      return null;
  }
};
