import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExecutorTypeEnum } from 'enums/executorType';
import { ModerationRequestStatusId } from 'enums/moderationRequestStatus';
import { Step1Payload } from 'pages/ModerationRequest/ExecutorModeration/steps/step1/types';
import { Step2Payload } from 'pages/ModerationRequest/ExecutorModeration/steps/step2/types';
import { Step3Payload } from 'pages/ModerationRequest/ExecutorModeration/steps/step3/types';
import { Step4Payload } from 'pages/ModerationRequest/ExecutorModeration/steps/step4/types';
import { Step6Payload } from 'pages/ModerationRequest/ExecutorModeration/steps/step6/types';
import { Step7Payload } from 'pages/ModerationRequest/ExecutorModeration/steps/step7/types';
import { ModerationRequest } from 'services/ModerationRequestService/types';
import { ModerationRequestState } from './types';

const initialState: ModerationRequestState = {
  moderationRequest: JSON.parse(sessionStorage.getItem('moderationRequest') || 'null') || {
    id: 0,
    rowVersion: null,
    moderatedUserId: 0,
    recruiterId: 0,
    inn: null,
    bik: null,
    bankSettlement: null,
    mobilePhone: null,
    email: null,
    created: null,
    acceptsOffer: false,
    parentId: 0,
    birthDate: null,
    executorType: ExecutorTypeEnum.Selfemployed,
    status: ModerationRequestStatusId.Draft,
    fullName: null,
    citizenship: 1,
    pyrusUrl: null,
    passport: {
      series: null,
      number: null,
      dateOfIssue: null,
      issuedBy: null,
      unitCode: null,
      files: [],
      registrationAddress: null,
      birthPlace: null,
    },
    executorPhotos: {
      files: [],
    },
    selfemployedCertificate: {
      files: [],
    },
    educations: null,
    workExperiences: [],
    serviceCities: [],
    executorSpecializations: [],
    individualCertificate: {
      ogrnip: null,
      individualName: null,
      files: [],
    },
    userRegistration: '',
    recruiterFullName: null,
    checkLists: {},
    certificateElectricalSafety: {
      files: [],
    },
    certificateWorkHeight: {
      files: [],
    },
  },
  isFormDisabled: JSON.parse(sessionStorage.getItem('isFormDisabled') || 'null') || false,
  isNavigateDisabled: false,
};

export const moderationRequestSlice = createSlice({
  name: 'moderationRequest',
  initialState,
  reducers: {
    setModerationRequest: (state, action: PayloadAction<ModerationRequest>) => {
      state.moderationRequest = action.payload;

      sessionStorage.setItem('moderationRequest', JSON.stringify(action.payload));
    },
    setRequestStatus: (state, action: PayloadAction<ModerationRequestStatusId>) => {
      state.moderationRequest.status = action.payload;

      sessionStorage.setItem('moderationRequest', JSON.stringify(state.moderationRequest));
    },
    setIsFormDisabled: (state, action) => {
      state.isFormDisabled = action.payload;

      sessionStorage.setItem('isFormDisabled', JSON.stringify(action.payload));
    },

    setPrivateData: (state, action: PayloadAction<Step1Payload>) => {
      state.moderationRequest.fullName = `${action.payload.lastName} ${action.payload.firstName} ${action.payload.middleName}`;
      state.moderationRequest.firstName = action.payload.firstName;
      state.moderationRequest.lastName = action.payload.lastName;
      state.moderationRequest.middleName = action.payload.middleName;
      state.moderationRequest.inn = action.payload.inn;
      state.moderationRequest.birthDate = action.payload.birthDate;

      sessionStorage.setItem('moderationRequest', JSON.stringify(state.moderationRequest));
    },
    setPassportData: (state, action: PayloadAction<Step2Payload>) => {
      state.moderationRequest.passport.birthPlace = action.payload.birthPlace;
      state.moderationRequest.passport.dateOfIssue = action.payload.dateOfIssue;
      state.moderationRequest.passport.files = action.payload.files;
      state.moderationRequest.passport.issuedBy = action.payload.issuedBy;
      state.moderationRequest.passport.number = action.payload.number;
      state.moderationRequest.passport.series = action.payload.series;
      state.moderationRequest.passport.registrationAddress = action.payload.registrationAddress;
      state.moderationRequest.passport.unitCode = action.payload.unitCode;
      state.moderationRequest.executorPhotos.files = action.payload.executorPhotos;

      sessionStorage.setItem('moderationRequest', JSON.stringify(state.moderationRequest));
    },
    setSelfemployedBankProps: (state, action: PayloadAction<Step3Payload>) => {
      state.moderationRequest.bik = action.payload.bik;
      state.moderationRequest.bankSettlement = action.payload.bankSettlement;
      state.moderationRequest.selfemployedCertificate.files =
        action.payload.selfemployedCertificate;

      sessionStorage.setItem('moderationRequest', JSON.stringify(state.moderationRequest));
    },
    setIndividualBankProps: (state, action: PayloadAction<Step3Payload>) => {
      state.moderationRequest.bik = action.payload.bik;
      state.moderationRequest.bankSettlement = action.payload.bankSettlement;
      state.moderationRequest.individualCertificate.files =
        action.payload.individualCertificateFiles;
      state.moderationRequest.individualCertificate.ogrnip = action.payload.ogrnip;
      state.moderationRequest.individualCertificate.individualName = action.payload.individualName;

      sessionStorage.setItem('moderationRequest', JSON.stringify(state.moderationRequest));
    },
    setSettlementsAndSpecializations: (state, action: PayloadAction<Step4Payload>) => {
      state.moderationRequest.serviceCities = action.payload.serviceCities;
      state.moderationRequest.executorSpecializations = action.payload.executorSpecializations;
      state.moderationRequest.acceptsOffer = action.payload.acceptsOffer;

      sessionStorage.setItem('moderationRequest', JSON.stringify(state.moderationRequest));
    },
    setEducations: (state, action: PayloadAction<Step6Payload>) => {
      state.moderationRequest.educations = action.payload.educations;
      state.moderationRequest.workExperiences = action.payload.workExperiences;

      sessionStorage.setItem('moderationRequest', JSON.stringify(state.moderationRequest));
    },
    setCertificates: (state, action: PayloadAction<Step7Payload>) => {
      state.moderationRequest.certificateElectricalSafety.files =
        action.payload.certificateElectricalSafety;
      state.moderationRequest.certificateWorkHeight.files = action.payload.certificateWorkHeight;

      sessionStorage.setItem('moderationRequest', JSON.stringify(state.moderationRequest));
    },
    setExecutorType: (state, action: PayloadAction<ExecutorTypeEnum>) => {
      state.moderationRequest.executorType = action.payload;

      sessionStorage.setItem('moderationRequest', JSON.stringify(state.moderationRequest));
    },
    setIsNavigateDisabled: (state, action: PayloadAction<boolean>) => {
      state.isNavigateDisabled = action.payload;
    },
  },
});

export const { reducer: moderationRequestReducer, actions: moderationRequestActions } =
  moderationRequestSlice;
