import { FieldUploadFileInput } from 'components/redesign/FieldUploadFileInput';
import { FileBusinessType } from 'enums/fileBusinessType';
import { FC } from 'react';
import { FieldErrors } from 'react-hook-form';
import { Step7Props } from './types';

export const Step7Fields: FC<Step7Props> = ({
  control,
  errors,
  disableNavigate,
  isFormDisabled,
}) => {
  return (
    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
      <div>
        <p className="body-bold sm:headline-bold mb-4 text-text-100">
          Удостоверения по электробезопасности
        </p>
        <FieldUploadFileInput
          name={'certificateElectricalSafety'}
          control={control}
          errorMessage={(errors as FieldErrors)?.certificateElectricalSafety?.message}
          fileBusinessTypeId={FileBusinessType.CertificateElectricalSafety}
          disabled={isFormDisabled}
          uploadLimit={5}
          disableNavigate={disableNavigate}
        />
      </div>
      <div>
        <p className="body-bold sm:headline-bold mb-4 text-text-100">
          Удостоверения на допуск к работам на высоте
        </p>
        <FieldUploadFileInput
          name={'certificateWorkHeight'}
          control={control}
          errorMessage={(errors as FieldErrors)?.certificateWorkHeight?.message}
          disabled={isFormDisabled}
          fileBusinessTypeId={FileBusinessType.CertificateWorkHeight}
          uploadLimit={5}
          disableNavigate={disableNavigate}
        />
      </div>
    </div>
  );
};
