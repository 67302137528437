import { OrganizationType } from 'enums/organizationType';
import { RequestHandler } from 'pages/ModerationRequest/ExecutorModeration/steps/finalStep/consts';
import { UseFormSetError } from 'react-hook-form';
import { ERequestHandler } from '../finalStep/consts';

export const checkInn = (
  inn: string | null,
  innScan: OrderFile | null,
  organizationTypeId: OrganizationType,
  requestHandler: ERequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const setLLCInnError = () => {
    const llcErrorMessage = 'Введите валидный ИНН. ИНН должен состоять из 10 цифр';
    setError('inn', {
      type: 'manual',
      message: llcErrorMessage,
    });
    toastErrors.push(llcErrorMessage);
  };
  const setIndividualInnError = () => {
    const ipErrorMessage = 'Введите валидный ИНН. ИНН должен состоять из 12 цифр';
    setError('inn', {
      type: 'manual',
      message: ipErrorMessage,
    });
    toastErrors.push(ipErrorMessage);
  };
  const setInnScanError = () => {
    const scanErrorMessage = 'Необходимо приложить файл';
    setError('innScan', {
      type: 'manual',
      message: scanErrorMessage,
    });
    toastErrors.push(scanErrorMessage);
  };

  if (inn || innScan?.length || requestHandler === ERequestHandler.MODERATION) {
    if (organizationTypeId === OrganizationType.Individual) {
      if (inn === null) {
        setIndividualInnError();
      } else {
        inn = inn.replace(/_/g, '');
        if (inn.length < 12) {
          setIndividualInnError();
        }
        const innNumbers = inn.split('').map(Number);
        if (
          !(
            innNumbers[10] ===
              ((7 * innNumbers[0] +
                2 * innNumbers[1] +
                4 * innNumbers[2] +
                10 * innNumbers[3] +
                3 * innNumbers[4] +
                5 * innNumbers[5] +
                9 * innNumbers[6] +
                4 * innNumbers[7] +
                6 * innNumbers[8] +
                8 * innNumbers[9]) %
                11) %
                10 &&
            innNumbers[11] ===
              ((3 * innNumbers[0] +
                7 * innNumbers[1] +
                2 * innNumbers[2] +
                4 * innNumbers[3] +
                10 * innNumbers[4] +
                3 * innNumbers[5] +
                5 * innNumbers[6] +
                9 * innNumbers[7] +
                4 * innNumbers[8] +
                6 * innNumbers[9] +
                8 * innNumbers[10]) %
                11) %
                10
          )
        ) {
          setIndividualInnError();
        }
      }
      if (!innScan) {
        setInnScanError();
      }
    } else {
      if (inn === null) {
        setLLCInnError();
      } else {
        inn = inn.replace(/_/g, '');
        if (inn.length < 10) {
          setLLCInnError();
        }
        const innNumbers = inn.split('').map(Number);
        if (
          !(
            innNumbers[9] ===
            ((2 * innNumbers[0] +
              4 * innNumbers[1] +
              10 * innNumbers[2] +
              3 * innNumbers[3] +
              5 * innNumbers[4] +
              9 * innNumbers[5] +
              4 * innNumbers[6] +
              6 * innNumbers[7] +
              8 * innNumbers[8]) %
              11) %
              10
          )
        ) {
          setLLCInnError();
        }
      }
      if (!innScan || Object.keys(innScan).length === 0) {
        setInnScanError();
      }
    }
  }
  return toastErrors;
};

export const checkPhoneNumber = (
  phoneNumber: string | null,
  requestHandler: ERequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const phoneErrorMessage = 'Телефон не соответствует шаблону';
  if (phoneNumber) {
    phoneNumber = phoneNumber.replace(/_/g, '');
    if (phoneNumber.length < 16) {
      setError('phoneNumber', {
        type: 'manual',
        message: phoneErrorMessage,
      });
      toastErrors.push(phoneErrorMessage);
    }
  }
  if (requestHandler === ERequestHandler.MODERATION && phoneNumber !== null) {
    phoneNumber = phoneNumber.replace(/_/g, '');
    if (phoneNumber.length < 16) {
      setError('phoneNumber', {
        type: 'manual',
        message: phoneErrorMessage,
      });
      toastErrors.push(phoneErrorMessage);
    }
  }
  return toastErrors;
};

export const checkEmail = (
  email: string | null,
  length: number,
  requestHandler: ERequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  let isValid = false;
  const emailErrorMessage = 'Формат Email неверный или превышает 100 символов';
  if (requestHandler === ERequestHandler.DRAFT && !email?.trim()) {
    return;
  }

  if (email && email.length <= length) {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    isValid = emailRegex.test(email);

    if (isValid) {
      const arr = email.split('@');
      isValid = arr.length == 2 && arr[1].includes('.');
    }
  }

  if (!isValid) {
    setError('email', {
      type: 'manual',
      message: emailErrorMessage,
    });
    toastErrors.push(emailErrorMessage);
  }

  return toastErrors;
};

export const checkSubcontractorOgrnip = (
  ogrnip: string | null,
  ogrnipScan: OrderFile | null,
  requestHandler: ERequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const setOgrnipError = () => {
    const ogrnipErrorMessage = 'Введите валидный ОГРНИП. ОГРНИП должен состоять из 15 цифр';
    setError('ogrnip', {
      type: 'manual',
      message: ogrnipErrorMessage,
    });
    toastErrors.push(ogrnipErrorMessage);
  };
  const setIndividualCertificateFilesError = () => {
    const certificateErrorMessage = 'Необходимо приложить файл';
    setError('ogrnipScan', {
      type: 'manual',
      message: certificateErrorMessage,
    });
    toastErrors.push(certificateErrorMessage);
  };

  if (requestHandler === ERequestHandler.MODERATION || ogrnip || ogrnipScan?.length) {
    if (ogrnip === null) {
      setOgrnipError();
    } else {
      ogrnip = ogrnip.replace(/_/g, '');
      if (ogrnip.length < 15) {
        setOgrnipError();
      }
      if (ogrnip[0] !== '3') {
        setOgrnipError();
      }
      const targetCheckSum = Number.parseInt(ogrnip[14]);
      let checkSum = Number.parseInt(ogrnip.substring(0, 14));
      checkSum %= 13;
      if (checkSum > 9) {
        checkSum %= 10;
      }
      if (checkSum !== targetCheckSum) {
        setOgrnipError();
      }
    }

    if (!ogrnipScan) {
      setIndividualCertificateFilesError();
    }
  }

  return toastErrors;
};

export const checkSubcontractorOgrn = (
  ogrn: string | null,
  ogrnScan: OrderFile | null,
  requestHandler: ERequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const setOgrnError = () => {
    const ogrnErrorMessage = 'Введите валидный ОГРН. ОГРН должен состоять из 13 цифр';
    setError('ogrn', {
      type: 'manual',
      message: ogrnErrorMessage,
    });
    toastErrors.push(ogrnErrorMessage);
  };
  const setOgrnScanError = () => {
    const ogrnScanErrorMessage = 'Необходимо приложить файл';
    setError('ogrnScan', {
      type: 'manual',
      message: ogrnScanErrorMessage,
    });
    toastErrors.push(ogrnScanErrorMessage);
  };
  if (requestHandler === ERequestHandler.MODERATION || ogrn || ogrnScan?.length) {
    if (ogrn === null) {
      setOgrnError();
    } else {
      ogrn = ogrn.replace(/_/g, '');
      if (ogrn.length < 13) {
        setOgrnError();
      }
      if (!['1', '5'].includes(ogrn[0])) {
        setOgrnError();
      }
      const targetCheckSum = Number.parseInt(ogrn[12]);
      let checkSum = Number.parseInt(ogrn.substring(0, 12));
      checkSum %= 11;

      if (checkSum > 9) {
        checkSum %= 10;
      }
      if (checkSum !== targetCheckSum) {
        setOgrnError();
      }
    }
    if (!ogrnScan) {
      setOgrnScanError();
    }
  }
  return toastErrors;
};

export const checkLegalAddress = (
  legalAddress: string | null,
  requestHandler: ERequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (requestHandler === ERequestHandler.DRAFT) {
    return toastErrors;
  }
  if (!legalAddress) {
    const legalAddressErrorMessage =
      'Поле "Юридический адрес" должно быть заполнено и не превышать 1000 символов';
    setError('legalAddress', {
      type: 'manual',
      message: legalAddressErrorMessage,
    });
    toastErrors.push(legalAddressErrorMessage);
  }
  return toastErrors;
};

export const checkAddress = (
  address: string | null,
  requestHandler: ERequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (requestHandler === ERequestHandler.DRAFT) {
    return toastErrors;
  }
  if (!address) {
    const addressErrorMessage =
      'Поле "Фактический адрес" должно быть заполнено и не превышать 1000 символов';
    setError('address', {
      type: 'manual',
      message: addressErrorMessage,
    });
    toastErrors.push(addressErrorMessage);
  }
  return toastErrors;
};
