/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>1</td><td>Reserve</td><td>Резерв</td></tr><tr><td>2</td><td>Purchase</td><td>Закупка</td></tr><tr><td>3</td><td>Shipment</td><td>Отгрузка</td></tr></tbody></table></summary>
 */
export enum SpsManagement_SharedEnums_SpsSourceTypeEnum {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
}
