export enum ModerationRequestStatusId {
  // Заявка сохранена как черновик.
  Draft = 1,
  // Заявка отправлена на модерацию и ожидает модератора.
  SentToModeration = 2,
  // Заявка взята на рассмотрение модератором.
  ReviewStarted = 3,
  // Отправлен запрос на уточнение по заявке.
  ElaborationRequested = 4,
  // Отправлен ответ на запрос на уточнение.
  ElaborationProvided = 5,
  // Заявка не прошла модерацию.
  ModerationFailed = 6,
  // Заявка отправлена на скоринг.
  Scoring = 7,
  // Заявка получила отказ из-за скоринга.
  ScoringFailed = 8,
  // Заявка прошла скоринг.
  ScoringCompleted = 9,
  // Создан запрос на обучение
  Training = 10,
  // Обучение пройденно
  TrainingCompleted = 11,
  // Тестирование не пройденно
  TrainingFailed = 12,
  // Создается чек-лист
  CreatingUnderwriting = 13,
  // Повторное обучение
  ReTraining = 14,
  // Импортировано
  Imported = 15,
  // Архив
  Archive = 16,
}

export enum ModerationRequestStatusName {
  Draft = 'Черновик',
  SentToModeration = 'Отправлена на модерацию',
  ReviewStarted = 'Взята на рассмотрение',
  ElaborationRequested = 'Запрошено уточнение',
  ElaborationProvided = 'Уточнение предоставлено',
  ModerationFailed = 'Не прошла модерацию',
  Scoring = 'Скоринг',
  ScoringFailed = 'Скоринг провален',
  ScoringCompleted = 'Скоринг пройден',
  Training = 'На обучение',
  TrainingCompleted = 'Проверки пройдены',
  TrainingFailed = 'Обучение провалено',
  CreatingUnderwriting = 'Создается чек-лист',
  ReTraining = 'Повторное обучение',
  Imported = 'Импортировано',
  Archive = 'Архив',
}

export enum ModerationRequestStatus {
  Draft = 'Draft',
  SentToModeration = 'SentToModeration',
  ReviewStarted = 'ReviewStarted',
  ElaborationRequested = 'ElaborationRequested',
  ElaborationProvided = 'ElaborationProvided',
  ModerationFailed = 'ModerationFailed',
  Scoring = 'Scoring',
  ScoringFailed = 'ScoringFailed',
  ScoringCompleted = 'ScoringCompleted',
  Training = 'Training',
  TrainingCompleted = 'Обучение пройдено',
  TrainingFailed = 'TrainingCompleted',
  CreatingUnderwriting = 'CreatingUnderwriting',
  ReTraining = 'ReTraining',
  Imported = 'Imported',
  Archive = 'Archive',
}
