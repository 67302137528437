import { FC } from 'react';
import { ProgressBarStepsProps } from './types';
import useMediaQuery from 'hooks/useMediaQuery';
import { IS_SMALL_DEVICE } from 'utils/settings';

export const ProgressBarSteps: FC<ProgressBarStepsProps> = ({ currentStepNum, countSteps }) => {
  const progress = Math.round((currentStepNum / countSteps) * 100);
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  return (
    <div className="inline-block">
      <div className="body-bold !text-text-100">
        {isSmall && 'Этап '}
        {`${currentStepNum}/${countSteps}`}
      </div>
      <div className="mt-2 flex h-[4px] min-w-[164px] max-w-[218px] rounded-md bg-light-30">
        <div style={{ width: `${progress}%` }} className="h-[4px] rounded-md bg-primary-60" />
      </div>
    </div>
  );
};
