import { FC } from 'react';
import { FilterBlockProps } from './types';
import { FilterMobileIcon, IconFilter } from 'assets/icons';
import useMediaQuery from 'hooks/useMediaQuery';
import { IS_SMALL_DEVICE } from 'utils/settings';

export const FilterBlock: FC<FilterBlockProps> = ({ onFilterButtonClick, countFilters }) => {
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  return (
    <>
      {isSmall ? (
        <FilterMobileIcon className="absolute top-6 right-4" onClick={onFilterButtonClick} />
      ) : (
        <div className="flex justify-end border-b border-light-100 bg-base px-8 py-4">
          <button className="flex items-center gap-1" onClick={onFilterButtonClick}>
            <p className="callout-bold uppercase !text-text-100">Фильтр</p>
            <div className="relative">
              <IconFilter />
              {countFilters > 0 && (
                <span className="text-bold absolute top-[-10px] right-[-18px] flex h-4 w-4 items-center justify-center rounded-[50%] bg-danger text-[10px] text-white">
                  {countFilters}
                </span>
              )}
            </div>
          </button>
        </div>
      )}
    </>
  );
};
