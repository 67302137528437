import React from 'react';
import ReactInputMask from 'react-input-mask';
import { ValidationError } from 'ui/redesign/ValidationError';
import { classNames } from 'utils/helpers';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMessage?: string;
  customMask?: string;
  icons?: JSX.Element[];
  variant?: 'default' | 'filter';
  hint?: string;
}

export const Input = ({
  label,
  errorMessage,
  type,
  customMask,
  value = '',
  icons,
  variant = 'default',
  hint,
  ...other
}: InputProps) => {
  const mask =
    type === 'tel'
      ? '+7(999)999-99-99'
      : type === 'date'
      ? '99.99.9999'
      : customMask
      ? customMask
      : '';

  const RenderIcon = () => {
    if (other.disabled) {
      return null;
    }
    if (icons && variant === 'default') {
      return (
        <div className="absolute top-1 right-0 flex -translate-y-1/2 cursor-pointer items-center gap-2">
          {icons.map((icon) => icon)}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="grow-1">
      {label && <div className="label">{label}</div>}
      <div className="relative">
        <>
          {mask ? (
            <ReactInputMask
              value={value}
              {...other}
              className={classNames(
                variant === 'filter' ? 'input-filter' : 'input',
                errorMessage ? 'input-error' : '',
                other.disabled ? 'text-gray-dark' : '',
                other.className ? other.className : ''
              )}
              mask={mask}
            />
          ) : (
            <input
              value={value}
              {...other}
              className={classNames(
                variant === 'filter' ? 'input-filter' : 'input',
                errorMessage ? 'input-error' : '',
                other.disabled ? 'text-gray-dark' : '',
                other.className ? other.className : ''
              )}
            />
          )}
          {<RenderIcon />}
        </>
      </div>
      {hint && <span className="text-xs text-gray-medium">{hint}</span>}
      <ValidationError errorMessage={errorMessage ? errorMessage : ''} />
      {/* {errorMessage && <div className="error-message">{errorMessage}</div>} */}
    </div>
  );
};
