/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>1</td><td>CityAsRegion</td><td>Город как регион</td></tr><tr><td>2</td><td>Others</td><td>Прочие регионы</td></tr></tbody></table></summary>
 */
export enum GeoData_SharedEnums_RegionTypeEnum {
    '_1' = 1,
    '_2' = 2,
}
