import { ChevronIcon } from 'assets/icons';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useState } from 'react';
import { DropdownProps } from './types';

export const Dropdown: FC<DropdownProps> = ({ title, Icon, children, className }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classNames('flex flex-col', className)}>
      <div className="mb-6 flex items-center">
        <Icon onClick={toggleDropdown} className="cursor-pointer" />
        <span
          onClick={toggleDropdown}
          className="body-bold sm:title2-bold mx-3.5 flex-1 cursor-pointer sm:flex-none"
        >
          {title}
        </span>
        <ChevronIcon
          onClick={toggleDropdown}
          className={classNames('cursor-pointer fill-[#D1D1D6] transition-transform', {
            'rotate-180': isOpen,
          })}
        />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
