/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>0</td><td>Processing</td><td>Проверка в процессе</td></tr><tr><td>1</td><td>Failed</td><td>Проверка провалена</td></tr><tr><td>2</td><td>Success</td><td>Проверка успешна</td></tr></tbody></table></summary>
 */
export enum Onboarding_Domain_ModerationDetails_ScoringTypeStatusEnum {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
}
