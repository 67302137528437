/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>1</td><td>Selfemployed</td><td>СЗ</td></tr><tr><td>2</td><td>Individual</td><td>ИП</td></tr><tr><td>3</td><td>DRITS</td><td>ОРИТС</td></tr></tbody></table></summary>
 */
export enum Common_SharedEnums_ExecutorTypeEnum {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
}
