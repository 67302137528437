/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>1</td><td>OrdersNew</td><td>Новые</td></tr><tr><td>2</td><td>OrdersInProgress</td><td>В работе</td></tr><tr><td>3</td><td>OrdersCompleted</td><td>Завершенные</td></tr></tbody></table></summary>
 */
export enum Outsourcing_SharedEnums_OrderTabEnum {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
}
