import { FC } from 'react';
import { FieldDatePickerProps } from './types';
import { Controller } from 'react-hook-form';
import { DatePicker } from 'ui/redesign/DatePicker';

export const FieldDatePicker: FC<FieldDatePickerProps> = ({
  name,
  control,
  rules,
  label,
  errorMessage,
  onChange,
  disabled,
  clearErrors,
  ...other
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const onChangeHandle = (value?: Date | null) => {
          field.onChange(value);
          onChange && onChange(value);
          clearErrors && clearErrors(name);
        };
        return (
          <DatePicker
            label={label}
            errorMessage={errorMessage}
            value={typeof field.value === 'string' ? new Date(field.value) : field.value}
            onChange={onChangeHandle}
            disabled={disabled}
            {...other}
          />
        );
      }}
    />
  );
};
