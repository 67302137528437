import { FC } from 'react';
import { ProfileMenuItemProps } from './types';
import { ChevronIcon } from 'assets/icons';
import useMediaQuery from 'hooks/useMediaQuery';
import { IS_SMALL_DEVICE } from 'utils/settings';

const ProfileMenuItem: FC<ProfileMenuItemProps> = ({ Icon, title }) => {
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  return (
    <div className="flex cursor-pointer items-center justify-between gap-4 rounded-[0.625rem] hover:bg-light-30 sm:p-4 sm:shadow-profileMenuItem">
      <div>
        <Icon className="mr-4" />
        <span className="body-bold sm:title2-bold !text-text-100">{title}</span>
      </div>
      {isSmall && (
        <div>
          <ChevronIcon
            className={'-rotate-90 cursor-pointer fill-[#D1D1D6] transition-transform'}
          />
        </div>
      )}
    </div>
  );
};

export default ProfileMenuItem;
