export enum EExecutorSpecialization {
  // ИТ – инженер
  // Техническое обслуживание, Ремонтно-восстановительные работы, Плановая и аварийная замена ФН, Регистрация ККТ и прочие
  ITEngineer = 1,
  // Монтажные работы
  // Прокладка СКС, Замена ламп накаливания, светодиодных, энергосберегающих, Демонтаж ТВ - Монтаж ТВ, Сбор мебели, Ремонт мебели
  InstallationWork = 2,

  // Доставка
  // Доставка, Разгрузочно-погрузочные работы
  Delivery = 3,

  // Клининг
  // Установка информационных табличек, Мойка окон, витражей, входных групп, Мойка вывесок, баннеров, стоперов на объекте
  Cleaning = 4,
  // Агент
  // Комплектация документов по клиентам
  Agent = 5,
}
