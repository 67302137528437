import classNames from 'classnames';
import { FieldInput } from 'components/redesign/FieldInput';
import { FieldUploadFileInput } from 'components/redesign/FieldUploadFileInput';
import { ExecutorTypeEnum } from 'enums/executorType';
import { FileBusinessType } from 'enums/fileBusinessType';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { FC } from 'react';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { SelfEmployerHint } from './SelfEmployerHint';
import { Step3Props } from './types';
import { FieldTextarea } from 'components/redesign/FieldTextArea';

export const Step3Fields: FC<Step3Props> = ({
  control,
  errors,
  isFormDisabled,
  executorType,
  clearErrors,
  disableNavigate,
}) => {
  const dispatch = useAppDispatch();

  const showHint = () => {
    dispatch(
      modalActions.handleOpenModal({
        content: <SelfEmployerHint />,
        options: {
          title: 'Где взять справку о статусе самозанятого:',
        },
      })
    );
  };
  return (
    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
      <FieldInput
        name="bik"
        control={control}
        label="БИК"
        errorMessage={errors?.bik?.message}
        mask="999999999"
        isClearable
        disabled={isFormDisabled}
        clearErrors={clearErrors}
      />
      <FieldInput
        name="bankSettlement"
        control={control}
        label="Расчетный счет"
        errorMessage={errors?.bankSettlement?.message}
        mask="99999999999999999999"
        isClearable
        disabled={isFormDisabled}
        clearErrors={clearErrors}
      />
      {executorType === ExecutorTypeEnum.Selfemployed && (
        <div>
          <p className="headline-bold mb-4 text-text-100">
            Справка о постановке на учет в качестве самозанятого
          </p>
          <FieldUploadFileInput
            name={'selfemployedCertificate'}
            control={control}
            errorMessage={(errors as any)?.selfemployedCertificate?.message}
            disabled={isFormDisabled}
            fileBusinessTypeId={FileBusinessType.SelfemployedCertificate}
            clearErrors={clearErrors}
            disableNavigate={disableNavigate}
          />
          <p className={classNames('callout-normal mt-1.5 !text-text-50', {})}>
            Вложите справку о статусе самозанятого
          </p>
          <p className="mt-1.5 ">
            <span className="callout-bold cursor-pointer !text-primary-60" onClick={showHint}>
              Подробнее
            </span>
          </p>
        </div>
      )}
      {executorType === ExecutorTypeEnum.Individual && (
        <div className="flex flex-col gap-4">
          <p className="headline-bold text-text-100">Свидетельство о постановке на учет ИП</p>
          <FieldInput
            name="ogrnip"
            control={control}
            label="ОГРНИП"
            errorMessage={errors?.ogrnip?.message}
            isClearable
            disabled={isFormDisabled}
            mask="999999999999999"
            clearErrors={clearErrors}
          />
          <FieldTextarea
            name="individualName"
            control={control}
            label="Наименование ИП"
            errorMessage={errors?.individualName?.message}
            isClearable
            disabled={isFormDisabled}
            clearErrors={clearErrors}
            maxLength={150}
          />
          <p className="headline-bold text-text-100">Карточка ИП/ОГРНИП</p>
          <FieldUploadFileInput
            name={'individualCertificateFiles'}
            control={control}
            errorMessage={(errors as any)?.individualCertificateFiles?.message}
            disabled={isFormDisabled}
            fileBusinessTypeId={FileBusinessType.Onboarding__IndividualCertificate}
            clearErrors={clearErrors}
            disableNavigate={disableNavigate}
          />
        </div>
      )}
    </div>
  );
};
