import { ReactComponent as CompletedOrderIcon } from 'assets/completed-order.svg';
import { ReactComponent as NewOrderIcon } from 'assets/new-order.svg';
import { ReactComponent as InProgressOrderIcon } from 'assets/time-timer-arrow.svg';
import { ReactComponent as RevisionOrderIcon } from 'assets/time-timer.svg';
import { useAppSelector } from 'hooks/useAppSelector';
import useMediaQuery from 'hooks/useMediaQuery';
import { Link, useLocation } from 'react-router-dom';
import { getExecutorSpecializationIds } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorSpecializationIds';
import { getExecutorType } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorType';
import { getTabSettings } from 'reduxStore/reducers/ProfileSlice/selectors/getTabSettings';
import { ProfileSpecialization, ExecutorTypeEnum } from 'reduxStore/reducers/ProfileSlice/types';
import { SMALL_DEVICE_MEDIA_QUERY } from 'utils/settings';

interface MenuItem {
  name: string;
  path: string;
  icon: any;
  locator: string;
  showNotifications?: boolean;
  notifications?: number;
}

export const DesktopNavigationMenu = () => {
  const location = useLocation();
  const executorSpecializationIds = useAppSelector(getExecutorSpecializationIds);
  const executorType = useAppSelector(getExecutorType);

  const tabSettings = useAppSelector(getTabSettings);

  const isSmallDevice = useMediaQuery(SMALL_DEVICE_MEDIA_QUERY);

  const getNotifications = (tabId: number) => {
    return tabSettings.length > 0 ? tabSettings[tabId].ordersCount : 0;
  };

  const getShowNotifications = (tabId: number) => {
    return (
      tabSettings.length > 0 &&
      tabSettings[tabId].isVisibleCounter &&
      tabSettings[tabId].ordersCount > 0
    );
  };

  const getMenu = () => {
    const menu: MenuItem[] = [];
    if (executorSpecializationIds?.includes(ProfileSpecialization.Agent)) {
      menu.push(
        {
          name: 'В работе',
          path: '/agent/orders/inProgress',
          icon: InProgressOrderIcon,
          locator: 'navMenuInProgress',
        },
        {
          name: 'На доработке',
          path: '/agent/orders/revision',
          icon: RevisionOrderIcon,
          locator: 'navMenuInRevision',
        },
        {
          name: 'Завершенные',
          path: '/agent/orders/completed',
          icon: CompletedOrderIcon,
          locator: 'navMenuCompleted',
        }
      );
    }
    if (
      // Обычный, рядовой исполнитель
      executorType !== ExecutorTypeEnum.Subcontractor &&
      !executorSpecializationIds?.includes(ProfileSpecialization.Agent)
    ) {
      menu.unshift({
        name: 'Новые',
        path: '/orders/new',
        icon: NewOrderIcon,
        locator: 'navMenuNew',
        showNotifications: getShowNotifications(0),
        notifications: getNotifications(0),
      });
    }
    if (!executorSpecializationIds?.includes(ProfileSpecialization.Agent)) {
      menu.push(
        {
          name: 'В работе',
          path: '/orders/inProgress',
          icon: InProgressOrderIcon,
          locator: 'navMenuInProgress',
          showNotifications: getShowNotifications(1),
          notifications: getNotifications(1),
        },
        {
          name: 'Завершенные',
          path: '/orders/completed',
          icon: CompletedOrderIcon,
          locator: 'navMenuCompleted',
          showNotifications: getShowNotifications(2),
          notifications: getNotifications(2),
        }
      );
    }

    return menu;
  };

  const menuItems: MenuItem[] = getMenu();

  const activeTextCls = (pathname: string) =>
    location.pathname.includes(pathname) ? 'text-black active' : 'text-gray-medium';
  const activeBgCls = (pathname: string) =>
    location.pathname.includes(pathname) ? 'bg-gray-light' : '';
  const activeIconCls = (pathname: string) =>
    location.pathname.includes(pathname) ? 'text-black' : 'text-gray-dark';
  const activeNotificationCls = (pathname: string) =>
    location.pathname.includes(pathname) ? 'bg-blue' : 'bg-gray-medium';

  if (isSmallDevice) {
    return null;
  }

  return (
    <div className="mb-6 border-b border-b-gray-light">
      <ul className="flex-start nav-menu flex">
        {menuItems.map(({ name, path, icon: Icon, showNotifications, notifications, locator }) => (
          <Link
            className={`${activeTextCls(path)} ${activeBgCls(
              path
            )} relative flex items-center gap-[8px] rounded-tr-md rounded-tl-md px-4 py-2`}
            key={name}
            to={path}
            id={locator}
          >
            <div className="relative">
              <Icon className={`${activeIconCls(path)}`} />
            </div>
            {name}
            {showNotifications && notifications && (
              <div
                className={`flex h-[18px] w-[18px] items-center justify-center rounded-full font-montserrat text-xs font-semibold text-white ${activeNotificationCls(
                  path
                )}`}
              >
                {notifications}
              </div>
            )}
          </Link>
        ))}
      </ul>
    </div>
  );
};
