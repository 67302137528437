import { ReactComponent as CloudUpArrowIcon } from 'assets/cloud-up-arrow.svg';
import { ReactComponent as FileIcon } from 'assets/file.svg';
import { ReactComponent as RemoveIcon } from 'assets/remove.svg';
import { AxiosError, AxiosResponse } from 'axios';
import { ChangeEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { File } from 'ui/redesign/File';
import { FilePreview } from 'components/FilePreview';
import { Modal } from 'ui/Modal';

import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { Attach } from 'assets/icons';
import { FileBusinessType } from 'enums/fileBusinessType';
import useMediaQuery from 'hooks/useMediaQuery';
import { toast } from 'react-toastify';
import { FileErrorResponse, OrderFile } from 'types';
import Loader from 'ui/Loader';
import { deleteFile, fetchFile, handleFileDownload } from 'utils/helpers';
import { IS_SMALL_DEVICE, UploadFileExtensions } from 'utils/settings';
import { ValidationError } from 'ui/redesign/ValidationError';

export interface UploadInputProps {
  name: string;
  fileBusinessTypeId?: FileBusinessType; // Бизнес-сущность
  deleteLink?: boolean; // ???
  onChange?: (files: OrderFile[]) => void;
  label?: string;
  maxFileSize?: number;
  errorMessage?: string;
  disabled?: boolean;
  value?: OrderFile[];
  acceptExtensions?: string;
  hideDelFileButton?: boolean;
  uploadLimit?: number;
  info?: ReactElement;
  otherTextInfo?: string;
  isChat?: boolean;
}

function UploadInput({
  name,
  onChange,
  fileBusinessTypeId = 6,
  deleteLink = true,
  label = '',
  maxFileSize = 5,
  errorMessage,
  disabled = false,
  value,
  acceptExtensions = UploadFileExtensions.pdfAndImages,
  hideDelFileButton = false,
  uploadLimit,
  info,
  otherTextInfo,
  isChat = false,
}: UploadInputProps) {
  const currentValue = useMemo(() => value ?? [], [value]);
  const [uploadedFiles, setUploadedFiles] = useState<OrderFile[]>(currentValue); // Данные файлов с бэкенда
  const [showErrorMessage, setShowErrorMessage] = useState<string>('');
  const [fileModalOpen, setFileModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentFile, setCurrentFile] = useState<{ content: string; type: string } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  const fileInputRef = useRef<HTMLInputElement>(null);

  // Максимальный размер ОДНОГО файла
  const MAX_SIZE: number = maxFileSize * 1024 * 1024;

  useEffect(() => {
    setError(null);
    setShowErrorMessage('');
    setUploadedFiles(currentValue);
  }, [setUploadedFiles, currentValue]);

  const uploadFile = async (files: FileList) => {
    setIsLoading(true);
    const formData: FormData = new FormData();
    Array.from(files).forEach((file) => {
      const blob = new Blob([file], { type: file.type });
      formData.append('files', blob, file.name);
    });
    formData.append('FileBuisenessTypeId', String(fileBusinessTypeId));
    formData.append('DeleteLink', String(deleteLink));
    request<OrderFile[]>(endpoints.UPLOAD_FILE.type, endpoints.UPLOAD_FILE.url(), {
      data: formData,
    })
      .then((response) => {
        if (response.status >= 200 || response.status < 300) {
          onChange && onChange([...uploadedFiles, ...response.data]);
        }
      })
      .catch((err: AxiosError<FileErrorResponse>) => {
        if (err.response?.status === 413) {
          toast.error('Ошибка при отправке файлов');
        }
        if (err.response) {
          setError(err.response.data.detail);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setShowErrorMessage('');
    const _files = e.target.files;
    if (uploadLimit !== undefined && uploadLimit - currentValue.length < Number(_files?.length)) {
      setShowErrorMessage(`Количество прикрепляемых файлов не может быть больше ${uploadLimit}`);
      return;
    }
    if (_files && _files.length > 0) {
      for (let i = 0; i < _files.length; i++) {
        if (_files[i].size > MAX_SIZE) {
          setShowErrorMessage(
            `Один из выбранных файлов больше ${maxFileSize} мегабайт, выберите файл меньшего размера`
          );
          return;
        }
      }
      setShowErrorMessage('');
      uploadFile(_files);
    }
    e.target.value = '';
  };

  const handleFileDelete = async (file: OrderFile) => {
    const response = await deleteFile(file.deletedFileIdHash);
    if (response.status === 200) {
      const files = uploadedFiles.filter(
        (uFile) => uFile.deletedFileIdHash !== file.deletedFileIdHash
      );
      onChange && onChange([...files]);
    }
  };

  return (
    <>
      {currentFile && fileModalOpen && (
        <Modal
          open={fileModalOpen}
          onClose={() => setFileModalOpen(false)}
          modalClasses="flex flex-col lg:max-w-max h-max p-0"
          label="Просмотр"
        >
          <FilePreview file={currentFile} />
        </Modal>
      )}
      {isChat ? (
        <>
          {isSmall
            ? (uploadedFiles.length > 0 || isLoading) && (
                <div className="-mt-[15px] w-full bg-white p-3 text-xs outline-none">
                  <hr className="mb-2 text-[#E3E3E3]" />
                  <ul className="grid grid-cols-3 gap-2">
                    {uploadedFiles.map((file, index) => (
                      <li
                        key={index}
                        className="relative flex h-20 min-w-[30%] flex-col items-center justify-around rounded-lg bg-gray-light p-1 text-xs text-black"
                      >
                        <FileIcon />
                        <p className="w-full truncate text-center font-semibold">{file.fileName}</p>
                        <p className="text-normal">
                          {file.length}KB {file.fileName.split('.')[1].toUpperCase()}
                        </p>
                        <RemoveIcon
                          className="absolute -top-0 -right-1 cursor-pointer fill-[#848484] hover:fill-primary-60"
                          onClick={() => handleFileDelete(file)}
                        />
                      </li>
                    ))}
                  </ul>
                  {isLoading && <Loader show={isLoading} />}
                </div>
              )
            : (uploadedFiles.length > 0 || isLoading) && (
                <div className="max-h-[35vh] w-full overflow-y-scroll p-3">
                  <hr className="mb-2.5 text-gray" />
                  {uploadedFiles.map((file) => {
                    return (
                      <File
                        file={file}
                        key={file.downloadFileIdHash}
                        onDownload={() => handleFileDownload(fetchFile(file.downloadFileIdHash))}
                        onDelete={() => handleFileDelete(file)}
                        disabled={disabled}
                        isHideDeleteFileButton={hideDelFileButton}
                      />
                    );
                  })}
                  {isLoading && <Loader show={isLoading} />}
                </div>
              )}
          {showErrorMessage && <p className="px-3 text-red">{showErrorMessage}</p>}
          {error && <p className="px-3 text-red">{error}</p>}

          <div className={`relative flex flex-col`}>
            <div>
              <Attach
                onClick={handleButtonClick}
                className="absolute left-2 top-3 cursor-pointer fill-[#979797] hover:fill-primary-60"
              />
              <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                onChange={handleFileChange}
                id={`download-file-${fileBusinessTypeId}-${name}`}
                accept={acceptExtensions}
                multiple
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`relative flex flex-col items-center rounded border border-gray px-2.5 py-4 ${
              errorMessage && 'input-error'
            }`}
          >
            {info}
            {label && <h3 className="text-center">{label}</h3>}
            <label
              htmlFor={`download-file-${fileBusinessTypeId}-${name}`}
              className={`btn-accent mb-4 flex max-w-[256px] items-center justify-center ${
                (uploadLimit !== undefined && currentValue.length >= uploadLimit) || disabled
                  ? 'pointer-events-none bg-[#CCF4F4]'
                  : ''
              }`}
              role="button"
              tabIndex={0}
            >
              <CloudUpArrowIcon className="mr-4 text-white" />
              Добавить файлы
              <input
                type="file"
                className="hidden"
                id={`download-file-${fileBusinessTypeId}-${name}`}
                accept={acceptExtensions}
                multiple
                onChange={handleFileChange}
                name={name}
              />
            </label>
            <div className="text-center">
              <p className="mb-1">Максимальный размер файла: {maxFileSize} МБ.</p>
              <p>Допустимые типы: .jpg, .jpeg, .pdf, .png</p>
              {otherTextInfo && <p>{otherTextInfo}</p>}
              {showErrorMessage && <p className="mt-2 text-red">{showErrorMessage}</p>}
            </div>
            {(uploadedFiles.length > 0 || isLoading) && (
              <div className="mt-4 w-full">
                <hr className="mb-2.5 text-gray" />
                {uploadedFiles.map((file) => {
                  return (
                    <File
                      file={file}
                      key={file.downloadFileIdHash}
                      onDownload={() => handleFileDownload(fetchFile(file.downloadFileIdHash))}
                      onDelete={() => handleFileDelete(file)}
                      disabled={disabled}
                      isHideDeleteFileButton={hideDelFileButton}
                    />
                  );
                })}
                {isLoading && <Loader show={isLoading} />}
              </div>
            )}
            {error && <p className="error-message">{error}</p>}
          </div>
          {errorMessage && <ValidationError errorMessage={errorMessage} />}
        </>
      )}
    </>
  );
}

export default UploadInput;
