import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { ReceiptsNotFound, ReceiptsNotFoundMobile } from 'assets/icons';
import classNames from 'classnames';
import useMediaQuery from 'hooks/useMediaQuery';
import { NoneOrders } from 'pages/Orders/NoneOrders';
import { useState } from 'react';
import { NewPaymentOrder, PaymentBySumm, PaymentOrder, PaymentOrderRequest } from 'types';
import { formatAmount, getDateWithTimezone } from 'utils/helpers';
import { IS_MEDIUM_DEVICE } from 'utils/settings';
import { SelectedPayment } from './SelectedPayment';
import { StatusIcon } from './const';

interface PaymentPeriodProps {
  paymentOrders: PaymentBySumm[] | undefined;
  refetch: () => void;
}

export const Payments = ({ paymentOrders, refetch }: PaymentPeriodProps) => {
  const [selectedPayment, setSelectedPayment] = useState<NewPaymentOrder | null>(null);
  const isMedium = useMediaQuery(IS_MEDIUM_DEVICE);

  const getMyReceipt = async (id: number) => {
    const myReceipt = await request<NewPaymentOrder>(
      endpoints.GET_EXECUTOR_RECEIPT_BY_ID.type,
      endpoints.GET_EXECUTOR_RECEIPT_BY_ID.url(id)
    );
    setSelectedPayment(myReceipt.data);
  };

  const handleClose = () => {
    setSelectedPayment(null);
  };

  const handleClick = async (id: number) => {
    await getMyReceipt(id);
  };

  const uploadReceipt = async () => {
    if (selectedPayment?.files && selectedPayment?.files.length > 0) {
      const requestBody: PaymentOrderRequest = {
        paymentOrderId: selectedPayment.id,
        files: selectedPayment.files,
      };
      const receiptsResponse = await request<PaymentOrder>(
        endpoints.ADD_EXECUTOR_RECEIPT_FILE.type,
        endpoints.ADD_EXECUTOR_RECEIPT_FILE.url(),
        {
          data: requestBody,
        }
      );
      if (receiptsResponse.status >= 200 || receiptsResponse.status < 300) {
        await refetch();
        handleClose();
      }
    }
  };

  if (paymentOrders && paymentOrders?.length > 0) {
    return (
      <div>
        <div
          className={classNames('mb-16 max-w-[700px] rounded-lg sm:pb-0', {
            'border border-gray-light shadow-md': !selectedPayment,
          })}
        >
          {!selectedPayment &&
            paymentOrders.map((payment) => (
              <fieldset
                className="flex cursor-pointer justify-between !border-b border-gray-light bg-white p-4 py-4 hover:bg-light-50"
                key={payment.id}
                onClick={() => handleClick(payment.id)}
              >
                <div className="flex flex-grow flex-col justify-between gap-1">
                  {payment.description && (
                    <div className="mb-2 flex max-w-[95%] flex-col">
                      <span className="pb-1 !text-xs !font-normal !text-gray-medium">
                        Назначение
                      </span>
                      <span className="!font-normal">{payment.description}</span>
                    </div>
                  )}
                  {payment.date && (
                    <div className="mb-2 flex max-w-[95%] flex-col">
                      <span className="pb-1 !text-xs !font-normal !text-gray-medium">Дата</span>
                      <span className="!font-normal">
                        {getDateWithTimezone(payment.date, 0, 'dd.MM.yyyy')}
                      </span>
                    </div>
                  )}
                </div>
                <div className="flex min-w-[30%] flex-col items-end justify-center">
                  <span className="pb-1 font-semibold !text-text-100">
                    {formatAmount(payment.amount as number, { minimumFractionDigits: 2 })} руб.
                  </span>
                  <StatusIcon withoutText={true} status={payment.status} />
                </div>
              </fieldset>
            ))}
          {selectedPayment && (
            <SelectedPayment
              handleClose={handleClose}
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
              uploadReceipt={uploadReceipt}
            />
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="mt-4 max-w-[600px] sm:p-4">
        <NoneOrders
          icon={isMedium ? <ReceiptsNotFoundMobile /> : <ReceiptsNotFound />}
          heading="Платежные поручения не найдены"
          description="Измените настройки фильтра и попробуйте еще раз"
        />
      </div>
    );
  }
};
