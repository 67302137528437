/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>0</td><td>NotSet</td><td>Не задан</td></tr><tr><td>1</td><td>Passport</td><td>Паспорт</td></tr><tr><td>2</td><td>Education</td><td>Образование</td></tr><tr><td>3</td><td>WorkExperience</td><td>Опыт работы</td></tr><tr><td>4</td><td>ExecutorPhotos</td><td>Фото исполнителя</td></tr><tr><td>5</td><td>SelfemployedCertificate</td><td>Сертификат самозанятого</td></tr><tr><td>6</td><td>Other</td><td>Другое</td></tr><tr><td>7</td><td>Outsourcing__OutsourcingReport</td><td>Отчет из модуля Outsourcing</td></tr><tr><td>8</td><td>Outsourcing__OrdersMassEditResult</td><td>Результат массового редактирования заявок.</td></tr><tr><td>9</td><td>OrderWorkAct</td><td>АВР</td></tr><tr><td>10</td><td>Archive</td><td>Архив</td></tr></tbody></table></summary>
 */
export enum Files_SharedEnums_FileBusinessTypeEnum {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
    '_6' = 6,
    '_7' = 7,
    '_8' = 8,
    '_9' = 9,
    '_10' = 10,
}
