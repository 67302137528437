export enum ButtonVariant {
  PRIMARY = 'btn-primary',
  SUCCESS = 'btn-success',
  LIGHT = 'btn-light',
  OUTLINE = 'btn-outline', // в проекте именуется, как Border
  GHOST = 'btn-ghost',
}

export const buttonVariants = {
  [ButtonVariant.PRIMARY]:
    'bg-primary-60 text-white hover:bg-primary-80 active:bg-primary-100 disabled:bg-primary-40',
  [ButtonVariant.SUCCESS]:
    'bg-success-60 text-white hover:bg-success-80 active:bg-success-100 disabled:bg-success-40',
  [ButtonVariant.LIGHT]:
    'bg-light-30 text-text-100 hover:bg-light-50 active:bg-light-100 disabled:bg-light-30',
  [ButtonVariant.OUTLINE]:
    'border border-solid  border-text-50 bg-base text-text-100 hover:bg-light-30 active:bg-light-50 disabled:bg-base disabled:text-text-50',
  [ButtonVariant.GHOST]:
    '!text-primary-60 px-0 sm:px-8 min-w-[6rem] sm:min-w-[10rem] hover:!text-primary-80 active:!text-primary-100 disabled:!text-primary-40',
};
