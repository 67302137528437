import { AddIcon, TrashIcon } from 'assets/icons';
import { FileBusinessType } from 'enums/fileBusinessType';
import { FieldTextarea } from '../FieldTextArea';
import { FieldUploadFileInput } from '../FieldUploadFileInput';
import { FieldEducationProps } from './types';
import classNames from 'classnames';

export default function FieldEducation({
  name,
  control,
  fields,
  onAppend,
  onDelete,
  disabled,
  title,
  errors,
  clearErrors,
  disableNavigate,
}: FieldEducationProps) {
  return (
    <section className="flex flex-col rounded-lg py-4 px-2">
      <div className="flex flex-row items-center justify-between">
        <p className="body-bold sm:headline-bold">{title}</p>
        <AddIcon
          onClick={disabled ? undefined : onAppend}
          className={classNames({
            'cursor-not-allowed text-text-50': disabled,
            'cursor-pointer text-primary-60': !disabled,
          })}
        />
      </div>
      {fields.map((item, index) => {
        return (
          <div className="my-4 flex flex-col gap-8" key={item.id}>
            <FieldTextarea
              name={`${name}.${index}.description`}
              control={control}
              label="Описание"
              errorMessage={errors[`${name}${index}description`]?.message}
              isClearable
              disabled={disabled}
              maxLength={150}
              clearErrors={clearErrors}
            />
            <FieldUploadFileInput
              name={`${name}.${index}.files`}
              control={control}
              fileBusinessTypeId={
                name === 'workExperiences'
                  ? FileBusinessType.WorkExperience
                  : FileBusinessType.Education
              }
              maxFileSize={10}
              disabled={disabled}
              errorMessage={errors[`${name}${index}files`]?.message}
              clearErrors={clearErrors}
              disableNavigate={disableNavigate}
            />
          </div>
        );
      })}
      {fields.length > 0 && (
        <button
          type="button"
          className="mr-2 self-end"
          onClick={() => {
            clearErrors();
            onDelete(fields.length - 1);
          }}
          disabled={disabled}
        >
          <TrashIcon
            className={classNames({
              'cursor-not-allowed text-text-50': disabled,
              'cursor-pointer text-danger': !disabled,
            })}
          />
        </button>
      )}
    </section>
  );
}
