/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>1</td><td>AgentScheme</td><td>Агентская схема (прямая)</td></tr><tr><td>2</td><td>Offer</td><td>Оферта (стандартная)</td></tr></tbody></table></summary>
 */
export enum Common_SharedEnums_CalculationSchemeEnum {
    '_1' = 1,
    '_2' = 2,
}
