export enum ApplicationType {
  // Система
  Core = 1,
  // Онбординг
  Onboarding = 2,
  // Аутсорсинг
  Outsourcing = 3,
  // ЗИП
  SpsManagement = 4,
  // Биллинг
  Billing = 5,
  // Оповещения
  Notification = 6,
  // Агенты
  Agent = 7,
  // Пользователи
  Identity = 8,
  // Отчеты
  Reporting = 9,
  // Верификация
  Verification = 10,
  // Компании
  Company = 11,
}
