import { ReducersMapObject, configureStore } from '@reduxjs/toolkit';
import { RootState } from './types';
import { modalReducer } from './reducers/ModalSlice';
import { profileReducer } from './reducers/ProfileSlice';
import { moderationRequestReducer } from './reducers/ModerationRequestSlice';
import { subcontractorModerationRequestReducer } from './reducers/SubcontractorModerationSlice';

export function createReduxStore(initialState?: RootState) {
  const rootReducer: ReducersMapObject<RootState> = {
    modal: modalReducer,
    profile: profileReducer,
    moderationRequest: moderationRequestReducer,
    subcontractorModerationRequest: subcontractorModerationRequestReducer,
  };

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    preloadedState: initialState,
  });

  return store;
}
