import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { ProgressBarSteps } from 'ui/redesign/ProgressBarSteps';
import { FormControlProps } from './types';
import useMediaQuery from 'hooks/useMediaQuery';
import { IS_SMALL_DEVICE } from 'utils/settings';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';
import { useAppSelector } from 'hooks/useAppSelector';
import { getExecutorNavigation } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getExecutorNavigation';
import { getSubcontractorNavigation } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorNavigation';

export const FormControl = ({ errors = {} }: FormControlProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);
  const isSubcontractor = JSON.parse(sessionStorage.getItem('permissions') || 'null').includes(
    ProfilePermissions.VerificationQuestionary_Create
  );
  const isExecutorNavigateDisable = useAppSelector(getExecutorNavigation);
  const isSubconractorNavigateDisable = useAppSelector(getSubcontractorNavigation);
  const isNavigateDisabled = isSubcontractor
    ? isSubconractorNavigateDisable
    : isExecutorNavigateDisable;

  const step = pathname.split('step')[1];

  const stepForward = () => {
    const nextStep = +step + 1;
    nextStep < 8
      ? navigate(`/moderation-request/step${nextStep}`)
      : navigate(`/moderation-request/final-step`);
  };

  return (
    <div className="relative my-4">
      {isSmall ? (
        <>
          {(step === '6' || step === '7') && (
            <div>
              <Button
                variant={ButtonVariant.PRIMARY}
                onClick={stepForward}
                type="button"
                className="mb-10 w-full max-w-[640px]"
                disabled={isNavigateDisabled}
              >
                ПРОПУСТИТЬ
              </Button>
            </div>
          )}
          <div className="flex w-full items-end justify-between sm:justify-end">
            <ProgressBarSteps currentStepNum={+step} countSteps={isSubcontractor ? 4 : 7} />
            <div className="flex flex-row items-center">
              <button
                name="back"
                disabled={step == '1' || isNavigateDisabled}
                className={classNames(
                  'mr-3.5 ml-5 cursor-pointer rounded-md border border-solid !border-primary-60 py-[2px] px-4 text-lg text-primary-60 hover:bg-white active:bg-white',
                  {
                    'cursor-not-allowed !border-text-50 !text-text-50': step == '1',
                  }
                )}
              >
                &lt;
              </button>
              <button
                className="body-paragraph-medium !rounded-md border border-solid !border-primary-60 bg-primary-60 px-4 py-2 text-white"
                disabled={!!Object.keys(errors)?.length}
                name="forward"
              >
                ДАЛЕЕ
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          {(step === '6' || step === '7') && (
            <div className="absolute">
              <Button
                variant={ButtonVariant.PRIMARY}
                onClick={stepForward}
                type="button"
                disabled={isNavigateDisabled}
              >
                ПРОПУСТИТЬ
              </Button>
            </div>
          )}
          <div className="flex w-full items-end justify-end">
            <ProgressBarSteps currentStepNum={+step} countSteps={isSubcontractor ? 4 : 7} />
            <Button
              className={classNames(
                'mr-3.5 ml-8 cursor-pointer border-primary-60 text-primary-60 hover:bg-white active:bg-white',
                {
                  'cursor-not-allowed !border-gray': step == '1' || isNavigateDisabled,
                }
              )}
              variant={ButtonVariant.OUTLINE}
              disabled={step == '1' || isNavigateDisabled}
              name="back"
            >
              &lt;
            </Button>
            <Button
              name="forward"
              variant={ButtonVariant.PRIMARY}
              disabled={!!Object.keys(errors)?.length}
            >
              ДАЛЕЕ
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
