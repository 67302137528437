import classNames from 'classnames';
import { FC } from 'react';
import ReactInputMask from 'react-input-mask';
import { InputProps } from './types';
import { ValidationError } from '../ValidationError';
import { CloseIcon } from 'assets/icons';
import { useRenderIcons } from 'hooks/useRenderIcons';

export const Input: FC<InputProps> = ({
  type,
  mask,
  value = '',
  label,
  icons,
  errorMessage = '',
  disabled = false,
  iconWrapperClassName,
  onChange,
  isClearable = false,
  isOpen,
  maxLength,
  ...other
}) => {
  const renderIcons = useRenderIcons(
    disabled,
    isClearable
      ? [
          {
            icon: <CloseIcon className="-ml-4 text-text-50 hover:text-text-100" />,
            onClick: () =>
              onChange &&
              onChange({
                target: {
                  value: '',
                },
              } as any),
          },
          ...(icons ?? []),
        ]
      : [...(icons ?? [])],
    iconWrapperClassName
  );

  return (
    <div>
      <div
        className={classNames('flex', {
          'border-danger': errorMessage,
          'border-primary-60': isOpen,
        })}
      >
        <div className="flex grow flex-col justify-end pb-1">
          {label && <div className="body-normal mb-1 !text-text-50">{label}</div>}
          {mask ? (
            // Warning: findDOMNode is deprecated in StrictMode. https://github.com/sanniassin/react-input-mask/issues/239
            <ReactInputMask
              value={value}
              mask={mask}
              type={type}
              disabled={disabled}
              onChange={onChange}
              className={classNames(
                'headline-normal border-0 border-b-2 border-solid border-light-50 text-text-100 focus:border-primary-60',
                {
                  '!text-text-50': disabled,
                  '!border-light-50 ': disabled || other.readOnly,
                  'border-danger': errorMessage,
                }
              )}
              {...other}
            />
          ) : (
            <input
              className={classNames(
                'headline-normal border-0 border-b-2 border-solid border-light-50 !text-text-100 focus:border-primary-60',
                {
                  '!border-light-50 !text-text-50': disabled || other.readOnly,
                  'border-danger': errorMessage,
                }
              )}
              value={value}
              type={type}
              disabled={disabled}
              onChange={onChange}
              {...other}
            />
          )}
        </div>
        {renderIcons}
      </div>
      <ValidationError errorMessage={errorMessage} />
    </div>
  );
};
