import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SIDEBAR_OPEN_LOCALSTORAGE_NAME } from 'layout/Aside/constants';
import { Profile } from 'services/ProfileService/types';
import { ProfilePermissions, ProfileState } from './types';

const initialState: ProfileState = {
  profile: JSON.parse(sessionStorage.getItem('profile') || 'null'),
  permissions: JSON.parse(sessionStorage.getItem('permissions') || '[]'),
  tabSettings: JSON.parse(sessionStorage.getItem('tabSettings') || '[]'),
  isSidebarOpen: JSON.parse(localStorage.getItem(SIDEBAR_OPEN_LOCALSTORAGE_NAME) || 'false'),
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<Profile>) => {
      sessionStorage.setItem('profile', JSON.stringify(action.payload));
      state.profile = action.payload;
    },
    setPermissions: (state, action: PayloadAction<ProfilePermissions[]>) => {
      sessionStorage.setItem('permissions', JSON.stringify(action.payload));
      state.permissions = action.payload;
    },
    toggleSidebar: (state, action: PayloadAction<boolean>) => {
      sessionStorage.setItem(SIDEBAR_OPEN_LOCALSTORAGE_NAME, JSON.stringify(action.payload));
      state.isSidebarOpen = action.payload;
    },
  },
});

export const { reducer: profileReducer, actions: profileActions } = profileSlice;
