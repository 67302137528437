import { UsersIcon } from 'assets/icons';
import { ModerationRequestStatusId } from 'enums/moderationRequestStatus';
import { OrganizationType } from 'enums/organizationType';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FormControl } from 'pages/ModerationRequest/redesign/FormControl';
import { FormWrapper } from 'pages/ModerationRequest/redesign/FormWrapper';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { subcontractorModerationRequestActions } from 'reduxStore/reducers/SubcontractorModerationSlice';
import { getSubcontractorModerationRequestData } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorModerationRequestData';
import { getSubcontractorNavigation } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorNavigation';
import { Step1Fields } from './Step1Fields';
import {
  checkAreas,
  checkOrganizationName,
  checkScan,
  getServiceAreas,
  isCity,
  isFederal,
  isRegion,
} from './consts';
import { Step1Payload } from './types';

export const Step1Subcontractor = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    organizationName,
    egrulScan,
    serviceAreas,
    organizationTypeId,
    egripScan,
    serviceAreaTypeId,
  } = useAppSelector(getSubcontractorModerationRequestData);
  const isNavigateDisabled = useAppSelector(getSubcontractorNavigation);
  const disableNavigate = (value: boolean) => {
    dispatch(subcontractorModerationRequestActions.setIsNavigateDisabled(value));
  };

  const egrulFile = egrulScan ? [egrulScan] : null;
  const egripFile = egripScan ? [egripScan] : null;
  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm<Step1Payload>({
    defaultValues: {
      organizationName,
      egrulScan: egrulFile,
      egripScan: egripFile,
      serviceAreas,
      serviceAreaTypeId,
    },
    mode: 'onBlur',
  });

  const onSubmit = () => {
    handleSubmit((data) => {
      const areas = getServiceAreas(data);
      const newEgrulScan =
        organizationTypeId === OrganizationType.Individual
          ? null
          : data.egrulScan
          ? (data as any).egrulScan[0]
          : null;
      const newEgripScan =
        organizationTypeId === OrganizationType.LLC
          ? null
          : data.egripScan
          ? (data as any).egripScan[0]
          : null;

      const toastErrors: string[] = [];

      checkOrganizationName(data.organizationName, toastErrors, setError);
      if (organizationTypeId === OrganizationType.Individual) {
        checkScan(newEgripScan, 'egripScan', toastErrors, setError);
      } else {
        checkScan(newEgrulScan, 'egrulScan', toastErrors, setError);
      }
      checkAreas(areas, toastErrors, setError);
      if (toastErrors.length) {
        const onlyUnique = new Set(toastErrors);
        Array.from(onlyUnique).forEach((message: string) => {
          toast.error(message);
        });
        return;
      }
      dispatch(
        subcontractorModerationRequestActions.setCompanyData({
          ...data,
          egrulScan: newEgrulScan,
          egripScan: newEgripScan,
          serviceAreas: areas?.length ? areas : [],
        })
      );
      if (isNavigateDisabled) {
        toast.error('Дождитесь удаления файла');
      } else {
        navigate(`/moderation-request/step2`);
      }
    })();
  };

  useEffect(() => {
    const serviceAreasC = getValues('serviceAreasC') ?? [];
    const serviceAreasR = getValues('serviceAreasR') ?? [];
    const serviceAreasF = getValues('serviceAreasF') ?? [];

    if (serviceAreas?.length > 0) {
      for (let i = 0; i < serviceAreas.length; i++) {
        if (isCity(serviceAreas[i])) {
          setValue('serviceAreaTypeId', serviceAreas[i].serviceAreaTypeId);
          serviceAreasC.push({
            label: serviceAreas[i].objectFullName || '',
            value: {
              fiasId: serviceAreas[i].cityFiasId || '',
              objectFullName: serviceAreas[i].objectFullName || '',
            },
          });
          setValue('serviceAreasC', serviceAreasC);
        } else if (isRegion(serviceAreas[i])) {
          setValue('serviceAreaTypeId', serviceAreas[i].serviceAreaTypeId);
          serviceAreasR.push({
            label: serviceAreas[i].objectFullName || '',
            value: {
              fiasId: serviceAreas[i].regionFiasId || '',
              objectFullName: serviceAreas[i].objectFullName || '',
            },
          });
          setValue('serviceAreasR', serviceAreasR);
        } else if (isFederal(serviceAreas[i])) {
          setValue('serviceAreaTypeId', serviceAreas[i].serviceAreaTypeId);
          if (!serviceAreasF.includes(serviceAreas[i].federalDistrictId)) {
            serviceAreasF.push(serviceAreas[i].federalDistrictId);
            setValue('serviceAreasF', serviceAreasF);
          }
        }
      }
    }
  }, []);

  return (
    <FormWrapper
      title="Данные о компании"
      status={ModerationRequestStatusId.Draft}
      Icon={UsersIcon}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex min-h-form flex-col justify-between">
        <Step1Fields
          errors={errors}
          control={control}
          isFormDisabled={false}
          clearErrors={clearErrors}
          watch={watch}
          setValue={setValue}
          organizationTypeId={organizationTypeId}
          disableNavigate={disableNavigate}
        />
        <FormControl errors={errors} />
      </form>
    </FormWrapper>
  );
};
