import { FC, useState } from 'react';
import { PdfProps } from './types';
import Button from 'components/Pagination/Button';
import { ReactComponent as LeftArrowIcon } from 'assets/left-arrow.svg';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import classNames from 'classnames';

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

export const Pdf: FC<PdfProps> = ({ data }) => {
  const [numberPages, setNumberPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages: numberPages }: { numPages: number }) {
    setNumberPages(numberPages);
  }

  const prevPageColor = pageNumber === 1 ? 'text-gray-medium' : 'text-black';
  const nextPageColor = pageNumber === numberPages ? 'text-gray-medium' : 'text-black';

  return (
    <div className="w-full p-4">
      {numberPages && numberPages > 1 ? (
        <div className="flex items-center justify-center gap-4 ">
          <Button
            onClick={() => setPageNumber((prevState) => prevState - 1)}
            disabled={pageNumber === 1}
          >
            <div className={classNames('flex items-center justify-center gap-2', prevPageColor)}>
              <LeftArrowIcon className={classNames('h-4 w-4', prevPageColor)} /> Предыдущая
            </div>
          </Button>
          <Button
            onClick={() => setPageNumber((prevState) => prevState + 1)}
            disabled={pageNumber === numberPages}
          >
            <div className={classNames('flex items-center justify-center gap-2', nextPageColor)}>
              Следующая
              <LeftArrowIcon className={classNames('h-4 w-4 rotate-180', nextPageColor)} />
            </div>
          </Button>
        </div>
      ) : null}
      <div className="max-h-[600px] p-1">
        <Document
          file={data}
          onLoadSuccess={onDocumentLoadSuccess}
          options={{ workerSrc: 'pdf.worker.min.js' }}
          className="object-fit mb-1"
        >
          <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
        </Document>
      </div>
      {/* <p>
        Страница {pageNumber} из {numberPages}
      </p> */}
    </div>
  );
};
