import { UseFormSetError } from 'react-hook-form';
import { ERequestHandler } from '../finalStep/consts';

export const vatRadio = [
  {
    label: 'С НДС',
    value: true,
  },
  {
    label: 'Без НДС',
    value: false,
  },
];

export const checkKPP = (
  kpp: string | null,
  requestHandler: ERequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (!kpp && requestHandler === ERequestHandler.DRAFT) {
    return toastErrors;
  }
  const kppErrorMessage = 'Неверный формат КПП. КПП должен состоять из 9 цифр';
  if (kpp) {
    kpp = kpp.replace(/_/g, '');
    if (kpp.length < 9) {
      setError('kpp', {
        type: 'manual',
        message: kppErrorMessage,
      });
      toastErrors.push(kppErrorMessage);
    }
  } else {
    setError('kpp', {
      type: 'manual',
      message: kppErrorMessage,
    });
    toastErrors.push(kppErrorMessage);
  }
  return toastErrors;
};

export const checkOKPO = (
  okpo: string | null,
  requestHandler: ERequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const okpoErrorMessage = 'Неверный формат ОКПО. ОКПО должен состоять из 8 цифр';
  if (!okpo && requestHandler === ERequestHandler.DRAFT) {
    return toastErrors;
  }
  if (okpo) {
    okpo = okpo.replace(/_/g, '');
    if (okpo.length < 8) {
      setError('okpo', {
        type: 'manual',
        message: okpoErrorMessage,
      });
      toastErrors.push(okpoErrorMessage);
    }
  } else {
    setError('okpo', {
      type: 'manual',
      message: okpoErrorMessage,
    });
    toastErrors.push(okpoErrorMessage);
  }
  return toastErrors;
};

export const checkOKATO = (
  okato: string | null,
  requestHandler: ERequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (!okato && requestHandler === ERequestHandler.DRAFT) {
    return toastErrors;
  }
  if (requestHandler === ERequestHandler.MODERATION || okato) {
    const okatoErrorMessage = 'Неверный формат ОКАТО. ОКАТО должен состоять из 11 цифр';
    if (!okato || okato.replace(/_/g, '').length < 11) {
      setError('okato', {
        type: 'manual',
        message: okatoErrorMessage,
      });
      toastErrors.push(okatoErrorMessage);
    }
  }
  return toastErrors;
};

export const checkBikAndBankSettlement = (
  bankSettlement: string | null,
  bik: string | null,
  requestHandler: ERequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
): string[] => {
  const setBankSettlementError = () => {
    const bankSettlementErrorMessage =
      'Неверный формат расчетного счета или БИК. Расчетный счет должен состоять из 20 цифр';
    setError('bankSettlement', {
      type: 'manual',
      message: bankSettlementErrorMessage,
    });
    toastErrors.push(bankSettlementErrorMessage);
  };
  const setBikError = () => {
    const bikErrorMessage =
      'Неверный формат расчетного счета или БИК. БИК должен состоять из 9 цифр';
    setError('bik', {
      type: 'manual',
      message: bikErrorMessage,
    });
    toastErrors.push(bikErrorMessage);
  };
  if (requestHandler === ERequestHandler.DRAFT && !bankSettlement && !bik) {
    return toastErrors;
  }

  if (bankSettlement === null) {
    setBankSettlementError();
  } else {
    bankSettlement = bankSettlement.replace(/_/g, '');
  }

  if (bankSettlement === null || bankSettlement.replace(/_/g, '').length < 20) {
    setBankSettlementError();
  } else {
    bankSettlement = bankSettlement.replace(/_/g, '');
  }

  if (bik === null || bik.replace(/_/g, '').length < 9) {
    setBikError();
  } else {
    bik = bik.replace(/_/g, '');
  }
  const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];

  const bikAndAccount = bik!.substring(bik!.length - 3) + bankSettlement;
  if (bikAndAccount.length !== 23) {
    setBankSettlementError();
    setBikError();
  }

  let bikSum = 0;
  for (let i = 0; i < bikAndAccount.length; i++) {
    const digit = Number.parseInt(bikAndAccount[i], 10);
    if (Number.isNaN(digit)) {
      setBankSettlementError();
      setBikError();
    }
    bikSum += digit * coefficients[i];
  }

  if (bikSum % 10 !== 0) {
    setBankSettlementError();
    setBikError();
  }

  return toastErrors;
};

export const checkBankName = (
  bankName: string | null,
  requestHandler: ERequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  if (requestHandler === ERequestHandler.DRAFT) {
    return toastErrors;
  }
  if (!bankName) {
    const bankNameErrorMessage =
      'Поле "Полное наименование банка" должно быть заполнено и не превышать 150 символов';
    setError('bankName', {
      type: 'manual',
      message: bankNameErrorMessage,
    });
    toastErrors.push(bankNameErrorMessage);
  }
  return toastErrors;
};
