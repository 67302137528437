import { Controller, Control, FieldErrors } from 'react-hook-form';

import { Checkbox, CheckboxProps } from './Checkbox';

import useFieldArrayControl from '../useFieldErrorMessage';

interface FieldCheckboxProps extends CheckboxProps {
  control: Control<any>;
  errors?: {
    [name: string]: FieldErrors;
  };
}

export const FieldCheckbox = ({
  name,
  label,
  control,
  errors,
  onChange,
  disabled,
}: FieldCheckboxProps) => {
  const errorMessage = useFieldArrayControl(name, errors);

  return (
    <div className="field-checkbox">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Checkbox
            label={label}
            name={name}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
              onChange && onChange(val);
            }}
            errorMessage={errorMessage}
            disabled={disabled}
          />
        )}
      />
    </div>
  );
};
