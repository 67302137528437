export enum RefuseReasonEnum {
  'Некорректная дата' = 1,
  'Некорректная сумма' = 2,
  'Некорректное ФИО' = 3,
  'Другое' = 4,
}

interface RefuseReason {
  refuseReason: RefuseReasonEnum;
  refuseComment: string | null;
}

export interface NewPaymentOrder {
  id: number;
  created: string;
  number: string | null;
  date: string;
  amount: number;
  fullName: string | null;
  description: string | null;
  files?: OrderFile[];
  status: PaymentOrderEnum;
  refuseReasons: RefuseReason[] | null;
}

export enum PaymentOrderEnum {
  'Ожидает загрузки' = 1,
  'Ожидает проверки' = 2,
  'Чек принят' = 3,
  'Чек не принят' = 4,
}
