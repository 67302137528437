import { ValidationError } from 'ui/redesign/ValidationError';

interface FieldTextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  errorMessage?: string;
}

export const Textarea = ({ label, errorMessage, ...other }: FieldTextareaProps) => {
  return (
    <div className="field-textarea">
      {label && <div className="label">{label}</div>}
      <textarea
        className={`textarea ${errorMessage && 'textarea-error'}`}
        rows={other.disabled ? undefined : 3}
        {...other}
      />
      {errorMessage && <ValidationError errorMessage={errorMessage} />}
    </div>
  );
};
