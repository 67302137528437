import {
  addHours,
  compareAsc,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
  parse,
  subDays,
  subHours,
} from 'date-fns';
import { AxiosError, AxiosResponse } from 'axios';
import { OrderFilters } from 'pages/Orders/Filters';
import { OrderTabType } from 'pages/Orders/types';
import {
  ErrorResponse,
  ExtendedSuggestion,
  OrderByFilterRequest,
  OrderFileContent,
  SettlementShort,
  StaticFileDownloadType,
} from 'types';
import { StatusByType, Statuses, StatusForOrderFilters } from 'types/status';
import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { StaticFiles, userManager } from 'utils/settings';
import { toast } from 'react-toastify';
import { OptionType } from 'ui/Select';
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn';
import { FileSizePart, chatMonthValues, monthValues } from 'const';
import { ru } from 'date-fns/locale';
import { MultiValue, SingleValue } from 'react-select';

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export const getDateWithTimezone = (date: string, timeZone?: number, frmt = 'dd.MM.yyyy HH:mm') => {
  if (timeZone) {
    return format(addHours(new Date(date), timeZone), frmt);
  }
  return format(new Date(date), frmt);
};

export const getDateWithoutUTC = (date: string) => {
  return date.replace(/ \(UTC\+\d+\)/gi, '');
};

export const getFileType = (mime: string): string => {
  const fileMap: { [p: string]: string } = {
    'image/png': 'png',
    'image/jpeg': 'jpeg',
    'application/pdf': 'pdf',
  };
  return fileMap[mime];
};

export const transformToSelectOptions = <T>(
  array: T[] | undefined,
  keyLabel: keyof T,
  keyValue?: keyof T,
  actualClients?: any
) => {
  let result = Array.isArray(array)
    ? array.map((item) => ({
        label: item[keyLabel],
        value: keyValue ? item[keyValue] : item,
      }))
    : [];
  if (actualClients?.length > 0) {
    const valueSet: any = new Set(actualClients?.map((item: any) => item.value));
    result = result.filter((item) => !valueSet.has(item.value));
  }
  return result;
};

// https://github.com/date-fns/date-fns/issues/229#issuecomment-416190283
const formatDistance = (from: Date, to: Date) => {
  const result: {
    days: number;
    hours: number;
    minutes: number;
  } = { days: 0, hours: 0, minutes: 0 };
  const now = from;
  let future = to;

  const days = differenceInDays(future, now);
  result['days'] = Math.abs(days);
  future = subDays(future, days);

  const hours = differenceInHours(future, now);
  result['hours'] = Math.abs(hours);
  future = subHours(future, hours);

  const minutes = differenceInMinutes(future, now);
  result['minutes'] = Math.abs(minutes);

  return result;
};

export const formatOrderRemainingTime = (timeISO: string, timeZone?: number) => {
  if (timeISO) {
    const now = new Date();
    const UTC0 = subHours(new Date(), Math.abs(now.getTimezoneOffset() / 60));
    const currentUserUTCTimezoneDate = addHours(UTC0, timeZone || 0);
    const parsed = parse(
      getDateWithTimezone(timeISO, timeZone),
      'dd.MM.yyyy HH:mm',
      currentUserUTCTimezoneDate
    );
    const { days, hours, minutes } = formatDistance(parsed, currentUserUTCTimezoneDate);
    const compare = compareAsc(parsed, new Date());
    return `${compare < 0 ? '-' : ''}${days} д. ${hours} ч. ${minutes} м.`;
  }
  return '';
};

export const isVisibleInStatus = (statusGroup: keyof typeof StatusByType, statusId: number) => {
  return StatusByType[statusGroup].includes(statusId);
};

export const orderByFilterRequestSkeleton: OrderByFilterRequest = {
  pageNumber: 1,
  pageSize: 25,
  tab: 1,
  searchParameters: {
    creationDateTimeStart: null,
    creationDateTimeEnd: null,
    clientIds: [],
    cityFiasIds: [],
    customId: '',
    areDependentObjectsIncluded: null,
  },
};

const tabIdByName: { [k in OrderTabType]: number } = {
  new: 1,
  inProgress: 2,
  completed: 3,
};

const prepareFilterData = (val: MultiValue<OptionType> | SingleValue<OptionType>) => {
  return val instanceof Array ? val.map((x) => x.value) : [val?.value];
};

export const getFilter = (
  type: OrderTabType = 'new',
  body?: OrderFilters,
  page = 1,
  pageSize = 250
) => {
  const _body = orderByFilterRequestSkeleton;
  _body.searchParameters = {
    creationDateTimeStart:
      body && body.create_date && body.create_date.startDate
        ? new Date(body.create_date.startDate).toISOString()
        : null,
    creationDateTimeEnd:
      body && body.create_date && body.create_date.endDate
        ? new Date(body.create_date.endDate).toISOString()
        : null,
    clientIds: body?.clients
      ? prepareFilterData(body?.clients as MultiValue<OptionType> | SingleValue<OptionType>)
      : [],
    customId: body?.customId ? body.customId : '',
    cityFiasIds: body?.cities
      ? prepareFilterData(body.cities as MultiValue<OptionType> | SingleValue<OptionType>)
      : [],
    areDependentObjectsIncluded: false,
  };
  _body.tab = tabIdByName[type];
  _body.pageSize = pageSize;
  _body.pageNumber = page;

  return _body;
};

export const getStatusesByType = (typeStatus: keyof typeof StatusByType) =>
  StatusForOrderFilters[typeStatus].statuses.map((id) => Statuses[id]);

export const fetchFile = async (hash: string): Promise<AxiosResponse<OrderFileContent>> => {
  return await request(endpoints.DOWNLOAD_FILE.type, endpoints.DOWNLOAD_FILE.url(hash));
};

export const deleteFile = async (hash: string) => {
  return await request(endpoints.DELETE_FILE.type, endpoints.DELETE_FILE.url(hash));
};

export const downloadStaticFile = async (type: StaticFiles) => {
  return await request<StaticFileDownloadType>(
    endpoints.STATIC_FILE_DOWNLOAD.type,
    endpoints.STATIC_FILE_DOWNLOAD.url(type)
  );
};

export const getFileUrl = (file: OrderFileContent) => {
  const fileType = getFileType(file.contentType);
  const chars = window.atob(file.content);
  const bytes = new Array(chars.length);
  for (let i = 0; i < chars.length; i++) {
    bytes[i] = chars.charCodeAt(i);
  }
  const blob = new Blob([new Uint8Array(bytes)], { type: fileType });
  return window.URL.createObjectURL(blob);
};

export const handleFileDownload = async (callback: Promise<AxiosResponse<OrderFileContent>>) => {
  const response = await callback;
  const file = response.data;
  const url = getFileUrl(file);
  const link = document.createElement('a');
  link.href = url;
  link.download = file.fileName;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const handleFilePreview = async (
  callback: Promise<AxiosResponse>,
  handleSetFile: (file: { content: string; type: string }) => void,
  handleSetModalOpen: (open: boolean) => void
) => {
  const response = await callback;
  const file = response.data;
  handleSetFile({
    content: file.content,
    type: file.contentType,
  });
  handleSetModalOpen(true);
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const formatAmount = (amount: number, options?: Intl.NumberFormatOptions): string =>
  new Intl.NumberFormat('ru-RU', options).format(amount);

export function logoutHandler() {
  sessionStorage.removeItem('moderationRequest');
  sessionStorage.removeItem('subcontractorModeration');
  sessionStorage.removeItem('isFormDisabled');
  sessionStorage.removeItem('isSubcontractorFormDisabled');
  sessionStorage.removeItem('permissions');
  sessionStorage.removeItem('navigationHistory');
  sessionStorage.removeItem('profile');

  userManager.createSignoutRequest().then((resp) => {
    ['userProfile', 'tabSettings', 'permissions', 'timeZone'].forEach((key) =>
      sessionStorage.removeItem(key)
    );
    userManager.signoutRedirect(resp.url);
  });
}

export function getCookie(name: string) {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const errorRequestToastHandler = (error: AxiosError<ErrorResponse>) => {
  toast.error(error.response?.data.detail, {
    position: toast.POSITION.TOP_LEFT,
  });
};

export const getUniqueCities = (
  citiesArr: ExtendedSuggestion[],
  valueArr?: OptionType<SettlementShort>[]
) => {
  let uniqueCities = citiesArr;

  valueArr &&
    valueArr.forEach((value) => {
      uniqueCities = uniqueCities.filter(
        (city) => city.suggestion.data.fias_id !== value.value.fiasId
      );
    });

  return uniqueCities;
};

export const editRuLocale = () => {
  const buildMonths = buildLocalizeFn({
    values: monthValues,
    defaultWidth: 'wide',
    formattingValues: monthValues,
    defaultFormattingWidth: 'wide',
  });

  if (ru.localize) {
    ru.localize.month = buildMonths;
  }
  return ru;
};

export const editChatRuLocale = () => {
  const buildMonths = buildLocalizeFn({
    values: chatMonthValues,
    defaultWidth: 'wide',
    formattingValues: chatMonthValues,
    defaultFormattingWidth: 'wide',
  });

  if (ru.localize) {
    ru.localize.month = buildMonths;
  }
  return ru;
};

export const viewFileSize = (size: number): string => {
  const partKb = Math.floor(size / FileSizePart.KB);
  const partMb = Math.floor(size / FileSizePart.MB);
  const partGb = Math.floor(size / FileSizePart.GB);
  if (partKb > 1 && partMb < 1) {
    return partKb + ' KB';
  } else if (partMb > 1 && partGb < 1) {
    return partMb + ' MB';
  } else if (partGb > 1) {
    return partGb + ' GB';
  } else {
    return size + ' B';
  }
};
