import React from 'react';

export default function Loader({ show }: { show: boolean }) {
  if (!show) {
    return null;
  } else {
    return (
      <div className="mt-2 flex items-center justify-center gap-2">
        <p className="text-xs"></p>
        <div className="relative h-1 w-full overflow-hidden">
          <div className="absolute z-10 h-1 w-16 animate-load rounded bg-primary-60"></div>
          <div className="absolute top-0 left-0 h-1 w-full rounded bg-light-50"></div>
        </div>
      </div>
    );
  }
}
