export enum ESubcontractorModerationRequestStatusId {
  // Черновик
  Draft = 1,
  // Модерация
  SendToVerification = 2,
  // Доработка
  ElaborationRequest = 3,
  // Модерация
  ElaborationProvided = 4,
  // Подписание
  Signing = 5,
  // Успешная регистрация
  Registered = 6,
  // Отказ
  Refused = 7,
}

export enum ESubcontractorModerationRequestStatusName {
  // Черновик
  Draft = 'Черновик',
  // Модерация
  SendToVerification = 'Модерация',
  // Доработка
  ElaborationRequest = 'Доработка',
  // Модерация
  ElaborationProvided = 'Модерация',
  // Подписание
  Signing = 'Подписание',
  // Успешная регистрация
  Registered = 'Успешная регистрация',
  // Отказ
  Refused = 'Отказ',
}
