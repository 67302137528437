import { OrganizationType } from 'enums/organizationType';
import { ESubcontractorCheckListType } from 'enums/subcontractorCheckListType';
import * as yup from 'yup';

export enum ERequestHandler {
  DRAFT = 'DRAFT',
  MODERATION = 'MODERATION',
  SECONDARY = 'SECONDARY',
}

export const mapChecklistField: { [p: string]: keyof typeof ESubcontractorCheckListType } = {
  organizationName: 'OrganizationName',
  egrulScan: 'Egrul',
  egripScan: 'Egrip',
  serviceAreaTypeId: 'ServiceAreaType',
  legalAddress: 'LegalAddress',
  address: 'Address',
  phoneNumber: 'PhoneNumber',
  email: 'Email',
  inn: 'Inn',
  innScan: 'InnScan',
  ogrn: 'Ogrn',
  ogrnScan: 'OgrnScan',
  ogrnip: 'Ogrnip',
  ogrnipScan: 'OgrnipScan',
  bankSettlement: 'BankSattelment',
  bankName: 'BankName',
  bik: 'Bik',
  withVat: 'WithVAT',
  kpp: 'Kpp',
  okato: 'Okato',
  okpo: 'Okpo',
  files: 'OtherSubcontractorDocs',
  certificateElectricalSafetyScan: 'CertificateElectricalSafetyScan',
  certificateWorkHeightScan: 'CertificateWorkHeightScan',
};

export const EmailValidator = (value: any): boolean => {
  if (value?.length === 0) {
    return true;
  }

  let isValid = false;

  if (value?.length <= 100) {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    isValid = emailRegex.test(value);

    if (isValid) {
      const arr = value?.split('@');
      isValid = arr.length == 2 && arr[1].includes('.');
    }
  }

  return isValid;
};
