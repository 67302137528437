export enum ChatUserGroup {
  DispCenterService = 1,
  DispCenterSPS = 2,
  LogistCenter = 3,
  ExecutorCenter = 4,
}

export enum ChatUserGroupName {
  DispCenterService = 'Диспетчерский центр',
  DispCenterSPS = 'Диспетчерский центр',
  LogistCenter = 'Логист',
  ExecutorCenter = 'Исполнитель',
}

export const chatUserGroupNameById: { [p: number]: ChatUserGroupName } = {
  [ChatUserGroup.DispCenterService]: ChatUserGroupName.DispCenterService,
  [ChatUserGroup.DispCenterSPS]: ChatUserGroupName.DispCenterSPS,
  [ChatUserGroup.LogistCenter]: ChatUserGroupName.LogistCenter,
  [ChatUserGroup.ExecutorCenter]: ChatUserGroupName.ExecutorCenter,
};
