export enum ExecutorTypeEnum {
  // Самозанятый (СЗ)
  Selfemployed = 1,
  // Индивидуальный предприниматель (ИП)
  Individual = 2,
  // Отделе Регионального ИТ Сервиса (ОРИТС)
  DRITS = 3,
  // Субподрядчик
  Subcontractor = 4,
}
