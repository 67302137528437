import { IInfoItem } from 'components/InfoCard/types';
import { Settings } from 'react-slick';
import { IconArrowLeft } from 'assets/icons';
import { ReactNode } from 'react';
import FirstSlide from 'assets/images/help-slide-1.png';
import SecondSlide from 'assets/images/help-slide-2.png';
import { Link } from 'react-router-dom';
import { downloadStaticFile, handleFileDownload } from 'utils/helpers';
import { StaticFiles } from 'utils/settings';

export const sliderSettings: Settings = {
  dots: true,
  arrows: true,
  infinite: false,
  prevArrow: (
    <button className="absolute top-1/2 left-0">
      <IconArrowLeft className="transition-colors duration-300" />
    </button>
  ),
  customPaging(): ReactNode {
    return <button className="h-3 w-3 rounded-[50%] bg-light-100 transition-colors duration-300" />;
  },
  nextArrow: (
    <button className="absolute top-1/2 right-0">
      <IconArrowLeft className="rotate-180 transition-colors duration-300" />
    </button>
  ),
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const sliderData: Array<IInfoItem & { id: number }> = [
  {
    id: 1,
    img: FirstSlide,
    text: (
      <p>
        Для получения полного доступа к платформе заполните{' '}
        <Link to={`/moderation-request/`} className="text-primary-80">
          анкету
        </Link>
        {/* <a href="">анкету</a>{' '} */}
      </p>
    ),
  },
  {
    id: 2,
    img: SecondSlide,
    text: (
      <p>
        Ознакомьтесь с{' '}
        <span
          onClick={() => handleFileDownload(downloadStaticFile(StaticFiles.Platform_Instruction))}
          className="cursor-pointer text-primary-80"
        >
          инструкцией
        </span>{' '}
        по работе на площадке M-Pro
      </p>
    ),
  },
];
