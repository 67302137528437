import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { FieldNumberInput } from 'components/FieldNumberInput';

import { ReactComponent as LeftArrowIcon } from 'assets/left-arrow.svg';
import { useAppSelector } from 'hooks/useAppSelector';
import useMediaQuery from 'hooks/useMediaQuery';
import { getTimezone } from 'reduxStore/reducers/ProfileSlice/selectors/getTimezone';
import { changeUserTimezone } from 'services';
import { User } from 'types';
import { SMALL_DEVICE_MEDIA_QUERY } from 'utils/settings';

export const ChangeTimezone = () => {
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();

  const timeZone = useAppSelector(getTimezone);
  const isSmallDevice = useMediaQuery(SMALL_DEVICE_MEDIA_QUERY);

  const { control, watch } = useForm<User>({
    defaultValues: {
      timeZone: timeZone || 0,
    },
  });

  const changeTimezoneHandler = async () => {
    setDisabled(true);
    const response = await changeUserTimezone({ timeZone: watch('timeZone') });
    if (response.status === 200) {
      navigate(-1);
    } else {
      setDisabled(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between gap-4">
        <h1>Изменение часового пояса</h1>
        {!isSmallDevice && (
          <button
            type="button"
            className="flex items-center justify-center gap-3 self-start rounded-md border border-gray-light px-3 py-1 text-center text-xs text-black transition-[background] delay-100 hover:bg-gray-light focus-visible:border-transparent focus-visible:outline-gray-light"
            onClick={() => navigate(-1)}
          >
            <LeftArrowIcon className="text-black" onClick={() => navigate(-1)} />
            Назад
          </button>
        )}
      </div>
      <form>
        <FieldNumberInput name="timeZone" control={control} label="Часовой пояс" />
      </form>
      <button
        className="btn-accent mt-8 w-full max-w-[288px] self-center"
        onClick={changeTimezoneHandler}
        disabled={disabled}
      >
        Изменить часовой пояс
      </button>
    </div>
  );
};
