import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ReactComponent as LeftArrowIcon } from 'assets/left-arrow.svg';

import { Annotation2, AnnotationVariant } from 'ui/Annotation/Annotation';
import { FieldInput } from 'components/FieldInput';

import { confirmEmail, sendEmailConfirmCode } from 'services';
import { useTimer } from 'hooks/useTimer';
import { ProfileChangeResponse } from 'types';
import useMediaQuery from 'hooks/useMediaQuery';
import { SMALL_DEVICE_MEDIA_QUERY } from 'utils/settings';
import { useAppSelector } from 'hooks/useAppSelector';
import { getProfileID } from 'reduxStore/reducers/ProfileSlice/selectors/getProfileID';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';

interface ConfirmEmailForm {
  email: string | null;
  confirmCode: string;
}

export const ConfirmEmail = () => {
  const queryResultRef = useRef<ProfileChangeResponse | null>(null);
  const navigate = useNavigate();

  const userId = useAppSelector(getProfileID);
  const userProfile = useAppSelector(getProfile);

  const isSmallDevice = useMediaQuery(SMALL_DEVICE_MEDIA_QUERY);

  const {
    control,
    formState: { errors },
    watch,
    setError,
  } = useForm<ConfirmEmailForm>({
    defaultValues: {
      email: userProfile?.email,
      confirmCode: '',
    },
  });

  const TIMEOUT = 4 * 60 + 59;

  const [timer, resetTimer] = useTimer(0);

  useEffect(() => {
    sendConfirmEmailCodeHandler();
  }, []);

  const email = watch('email');

  const sendConfirmEmailCodeHandler = async () => {
    const response = await sendEmailConfirmCode();
    if (response.status === 200) {
      queryResultRef.current = response.data;
      resetTimer(TIMEOUT);
    }
  };

  const confirmEmailHandler = async () => {
    const response = await confirmEmail({
      email: email || '',
      code: watch('confirmCode'),
      userId: String(userId) || '0',
    });
    if (response.status === 200) {
      if (response.data.isSuccess) {
        navigate(-1);
      } else {
        queryResultRef.current = response.data;
        setError('confirmCode', { type: 'required', message: ' ' });
        return;
      }
    }
  };

  const CodeRequestTimeout = ({ timer }: { timer: number }) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer - minutes * 60;
    return (
      <p className="mt-2 text-xs text-gray-medium">
        Повторно отправить код через {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds} сек.
      </p>
    );
  };

  return (
    <div>
      <div className="flex justify-between gap-4">
        <h1>Подтверждение электронной почты</h1>
        {!isSmallDevice && (
          <button
            type="button"
            className="flex items-center justify-center gap-3 self-start rounded-md border border-gray-light px-3 py-1 text-center text-xs text-black transition-[background] delay-100 hover:bg-gray-light focus-visible:border-transparent focus-visible:outline-gray-light"
            onClick={() => navigate(-1)}
          >
            <LeftArrowIcon className="text-black" onClick={() => navigate(-1)} />
            Назад
          </button>
        )}
      </div>
      {queryResultRef.current && (
        <Annotation2
          variant={
            queryResultRef.current.isSuccess ? AnnotationVariant.TEAL : AnnotationVariant.RED
          }
        >
          <p>{queryResultRef.current.message}</p>
        </Annotation2>
      )}
      <form>
        <div className="mb-4">
          <FieldInput
            name="email"
            control={control}
            type="email"
            label="Электронная почта:"
            disabled
          />
        </div>
        <div>
          <FieldInput
            name="confirmCode"
            control={control}
            errors={errors}
            label="Код подтверждения:"
          />
          {timer > 0 && <CodeRequestTimeout timer={timer} />}
        </div>
      </form>
      <>
        <button
          className="btn-accent mt-8 w-full max-w-[288px] self-center"
          disabled={!watch('confirmCode').length}
          onClick={confirmEmailHandler}
        >
          Подтвердить
        </button>
        <button
          className="mt-6 w-full max-w-[288px] self-center text-xs text-blue disabled:text-blue-light"
          disabled={timer > 0}
          onClick={sendConfirmEmailCodeHandler}
        >
          Повторно отправить код
        </button>
      </>
    </div>
  );
};
