import { FC } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { Preview } from './Preview';
import { FilePreviewProps } from './types';

export const FilePreview: FC<FilePreviewProps> = ({ file }) => {
  return <Preview file={file} />;
};
