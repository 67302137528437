import { useState } from 'react';
import { OptionProps } from './types';
import { CheckBoxIcon } from 'assets/icons';

export const Option = ({
  label,
  name,
  value,
  active,
  id = '',
  onChange,
  disabled,
}: OptionProps) => {
  const [checked, setChecked] = useState(active);

  const handleChange = (value: any) => {
    setChecked(!checked);
    onChange && onChange(value);
  };

  const defaultClasses = 'after:border-text-50';
  const disabledClasses = 'after:bg-gray-light after:border-gray-medium';
  const checkedClasses = 'after:border-text-50';
  const checkedDisabledClasses = 'after:border-gray-medium after:bg-gray-light';

  const _class =
    checked && disabled
      ? checkedDisabledClasses
      : checked
      ? checkedClasses
      : disabled
      ? disabledClasses
      : defaultClasses;

  return (
    <label
      className={`hover:bg-fff flex cursor-pointer gap-2 p-2 transition-all hover:bg-light-30 ${
        disabled ? 'pointer-events-none' : ''
      }`}
      htmlFor={`option_${id}`}
    >
      <span
        className={`flex items-center justify-center after:block after:h-5 after:w-5 after:rounded after:border after:transition-all ${_class}`}
      >
        {checked && disabled ? (
          <CheckBoxIcon className="absolute h-6 w-6 fill-primary-60 text-white" />
        ) : checked ? (
          <CheckBoxIcon className="absolute h-6 w-6 text-white" />
        ) : null}
      </span>
      <span className="body-normal">{label}</span>
      <input
        className="input-default absolute appearance-none "
        type="checkbox"
        id={`option_${id}`}
        checked={checked}
        onChange={() => handleChange(id)}
        value={value}
        name={name}
        disabled={disabled}
      />
    </label>
  );
};
