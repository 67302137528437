import { useEffect, useState } from 'react';
import { formatOrderRemainingTime } from 'utils/helpers';

export const useTimeRemaining = ({
  targetDate = '',
  timeZone,
  ms = 1000 * 60,
}: {
  targetDate?: string;
  timeZone?: number;
  ms?: number;
}) => {
  const [remainingTime, setRemainingTime] = useState(
    () => formatOrderRemainingTime(targetDate, timeZone) || null
  );

  useEffect(() => {
    let int: number;
    if (targetDate) {
      int = window.setInterval(() => {
        const time = formatOrderRemainingTime(targetDate, timeZone);
        setRemainingTime(time);
      }, ms);
    }

    return () => {
      clearInterval(int);
    };
  }, [ms, targetDate, timeZone]);

  return { remainingTime };
};
