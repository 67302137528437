import { Controller, Control, FieldErrors, RegisterOptions } from 'react-hook-form';

import NumberInput, { NumberInputProps } from 'ui/NumberInput';

import useFieldErrorMessage from 'hooks/useFieldErrorMessage';

interface FieldNumberInputProps extends NumberInputProps {
  name: string;
  control: Control<any>;
  errors?: {
    [name: string]: FieldErrors;
  };
  rules?: RegisterOptions;
  controlIcons?: boolean;
}

export const FieldNumberInput = ({
  name,
  label,
  control,
  errors,
  rules,
  onChange,
  controlIcons,
  ...other
}: FieldNumberInputProps) => {
  const errorMessage = useFieldErrorMessage(name, errors);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <NumberInput
          label={label}
          errorMessage={errorMessage}
          value={field.value}
          controlIcons={controlIcons}
          onChange={(val) => {
            field.onChange(val);
            onChange && onChange(val);
          }}
          {...other}
        />
      )}
    />
  );
};
