import { MapMarkerRedIcon, MapUserMarkerIcon } from 'assets';
import { ReactComponent as MinusIcon } from 'assets/minus.svg';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { ReactComponent as SendMessageIcon } from 'assets/send-message.svg';
import { useEffect, useRef, useState } from 'react';
import { Map, Placemark, YMaps } from 'react-yandex-map';

import { Spinner } from 'ui/Spinner';

import { Order } from 'types';
import { YANDEX_API_KEY } from 'utils/settings';

interface IMapProps {
  order: Order;
}

function YaMap({ order }: IMapProps) {
  const [userGeo, setUserGeo] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const mapRef = useRef<any>(null);

  const officeCoords = [order.serviceObjectGeoLat, order.serviceObjectGeoLon].map(Number);

  useEffect(() => {
    try {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setUserGeo([pos.coords.latitude, pos.coords.longitude]);
        },
        () => {
          throw new Error('Failed to obtain coordinates');
        },
        { timeout: 100000, enableHighAccuracy: true, maximumAge: 2000 }
      );
    } catch (err) {
      console.log(err);
    }
  }, []);

  const changeZoom = (isPlus: boolean) => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom() + (isPlus ? 1 : -1);
      mapRef.current.setZoom(newZoom, { duration: 200 });
    }
  };

  const showUserLocation = () => {
    if (mapRef.current) {
      mapRef.current.panTo(userGeo, 16);
    }
  };

  return (
    <>
      <div className="relative w-full">
        <YMaps query={{ apikey: YANDEX_API_KEY }}>
          <Map
            onLoad={() => {
              setIsLoading(false);
            }}
            onError={() => {
              setIsLoading(false);
            }}
            defaultState={{
              center: officeCoords.map(Number),
              zoom: 13,
            }}
            instanceRef={(instance) => {
              instance ? (mapRef.current = instance) : null;
            }}
            className="h-[300px] w-full lg:max-w-[900px]"
          >
            <div className="absolute right-2 top-1/2 z-10 -translate-y-1/2">
              <button
                onClick={() => changeZoom(true)}
                className="group mb-2 flex h-[46px] w-[46px] items-center justify-center rounded-lg border border-gray-light bg-white shadow-lg"
              >
                <PlusIcon className="text-gray-dark transition group-hover:text-black" />
              </button>
              <button
                onClick={() => changeZoom(false)}
                className="group flex h-[46px] w-[46px] items-center justify-center rounded-lg border border-gray-light bg-white shadow-lg"
              >
                <MinusIcon className="text-gray-dark transition group-hover:text-black" />
              </button>
            </div>
            <div className="absolute right-2 bottom-2 z-10">
              <button
                onClick={showUserLocation}
                className="group flex h-[46px] w-[46px] items-center justify-center rounded-full border border-gray-light bg-white shadow-lg"
              >
                <SendMessageIcon className="text-gray-dark transition group-hover:text-black" />
              </button>
            </div>
            <Placemark
              geometry={userGeo}
              options={{
                iconLayout: 'default#image',
                iconImageHref: MapUserMarkerIcon,
                iconImageSize: [24, 24],
                iconImageOffset: [-12, -12],
              }}
            />
            <Placemark
              geometry={officeCoords}
              properties={{
                hintContent: order.serviceObjectName,
                balloonContentHeader: order.serviceObjectName,
                balloonContentBody: order.serviceObjectAddress,
              }}
              modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
              options={{
                iconLayout: 'default#image',
                iconImageHref: MapMarkerRedIcon,
                iconImageSize: [45, 57],
                iconImageOffset: [-22, -57],
              }}
            />
          </Map>
        </YMaps>
      </div>
      {isLoading && (
        <div className="mx-auto w-12">
          <Spinner />
        </div>
      )}
    </>
  );
}

export default YaMap;
