import classNames from 'classnames';
import useMediaQuery from 'hooks/useMediaQuery';
import { FC, useMemo } from 'react';
import ReactSelect, { GroupBase, StylesConfig } from 'react-select';
import { IS_MEDIUM_DEVICE } from 'utils/settings';
import { ValidationError } from '../ValidationError';
import {
  defaultStyles,
  filterMobileStyles,
  filterStyles,
  isColouredStyles,
  isMultiStyles,
  variantWithIconsStyles,
} from './styles';
import { OptionType, SelectProps } from './types';
import { isMultiComponents, variantWithIconsComponents } from './utils';

export const Select: FC<SelectProps> = ({
  placeholder = '',
  onChange,
  isMulti = false,
  isColoured = false,
  options,
  value,
  variant,
  errorMessage = '',
  className = '',
  label,
  isClearable = false,
  closeMenuOnSelect = false,
  ...props
}) => {
  const isMedium = useMediaQuery(IS_MEDIUM_DEVICE);

  const { components: selectComponents, styles: selectStyles } = useMemo(() => {
    let components;
    let styles: StylesConfig<OptionType, boolean, GroupBase<OptionType>> = defaultStyles;

    if (isMulti) {
      components = isMultiComponents;
      styles = isMultiStyles;
    }

    if (isColoured) {
      styles = isColouredStyles;
    }

    switch (variant) {
      case 'withIcons':
        components = variantWithIconsComponents;
        styles = variantWithIconsStyles;
        break;
      case 'filter':
        components = isMultiComponents;
        styles = isMedium ? filterMobileStyles : filterStyles;
        break;
      default:
        break;
    }

    return {
      components,
      styles,
    };
  }, [variant, isMulti]);

  return (
    <div className="flex grow flex-col justify-end overflow-visible">
      {label && (
        <div
          className={classNames('body-normal mb-1 !text-text-50', {
            '!text-primary-60': label === 'Города обслуживания',
          })}
        >
          {label}
        </div>
      )}
      <ReactSelect
        value={value}
        isMulti={isMulti}
        isClearable={isClearable}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={false}
        options={options}
        noOptionsMessage={() => <p>Нет доступных для выбора значений</p>}
        loadingMessage={() => <p>Подождите, идёт загрузка...</p>}
        placeholder={placeholder}
        onChange={onChange}
        components={selectComponents}
        styles={selectStyles}
        className={classNames(
          className,
          'headline-normal border-b-2 border-solid hover:border-primary-60 focus:border-b-2',
          {
            'rounded-[0.625rem] shadow-profileMenuItem': variant === 'withIcons',
            'border-b-2 border-light-50': isMulti,
            'border-danger': errorMessage && isMulti,
          }
        )}
        {...props}
      />
      <ValidationError errorMessage={errorMessage} />
    </div>
  );
};
