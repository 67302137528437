/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Название для админа</th></thead><tbody><tr><td>1</td><td>AllRequest</td><td>Все заявки</td></tr><tr><td>2</td><td>SentToModeration</td><td>Заявки на модерацию</td></tr><tr><td>3</td><td>MyReviewUnderwriting</td><td>Заявки в моем рассмотрении</td></tr><tr><td>4</td><td>Rejection</td><td>Отклоненные заявки</td></tr><tr><td>5</td><td>Training</td><td>Заявки на обучение</td></tr><tr><td>6</td><td>ReTraining</td><td>Заявки на повторное обучение</td></tr></tbody></table></summary>
 */
export enum Onboarding_SharedEnums_ModerationRequestTabTypeEnum {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
    '_6' = 6,
}
