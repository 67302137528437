/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th></thead><tbody><tr><td>0</td><td>ADDRESS</td></tr><tr><td>1</td><td>AS_IS</td></tr><tr><td>2</td><td>BIRTHDATE</td></tr><tr><td>3</td><td>EMAIL</td></tr><tr><td>4</td><td>IGNORE</td></tr><tr><td>5</td><td>NAME</td></tr><tr><td>6</td><td>PASSPORT</td></tr><tr><td>7</td><td>PHONE</td></tr><tr><td>8</td><td>VEHICLE</td></tr></tbody></table></summary>
 */
export enum Dadata_Model_StructureType {
    '_0' = 0,
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
    '_6' = 6,
    '_7' = 7,
    '_8' = 8,
}
