import { useEffect, useState } from 'react';
import { RadioGroup as RG } from '@headlessui/react';
import classNames from 'classnames';

export type RadioType = {
  label: string;
  value: string | number | boolean;
  disabled?: boolean;
};

export interface RadioGroupProps {
  data: RadioType[];
  label?: string;
  onChange?: (val: any) => void;
  value?: any;
  errorMessage?: string;
  disabled?: boolean;
}

export const RadioGroup = ({
  label,
  data,
  onChange,
  value,
  errorMessage,
  disabled,
}: RadioGroupProps) => {
  const [selected, setSelected] = useState(String(value));

  useEffect(() => {
    value !== null && value !== undefined && setSelected(value);
  }, [value]);

  const onChangeHandler = (val: string) => {
    setSelected(val);
    onChange && onChange(val);
  };

  const defaultClasses = 'hover:border-primary-60 hover:border-4 border-text-50 border';
  const activeClasses = 'hover:border-primary-60 hover:border-4';
  const disabledClasses = '';
  const checkedClasses = 'border-primary-60 border-4';
  const checkedDisabledClasses = 'border-text-50 border-4';
  return (
    <div className="field-radio-group">
      {label && <div className="label">{label}</div>}
      <RG value={selected} onChange={onChangeHandler} className="flex flex-col flex-wrap gap-3">
        {data.map(({ label, value, disabled: dataDisabled }) => {
          return (
            <RG.Option
              key={label}
              value={value}
              className="group flex focus-visible:outline-none"
              disabled={dataDisabled || disabled}
            >
              {({ checked, active, disabled }) => {
                const _class =
                  checked && disabled
                    ? checkedDisabledClasses
                    : checked
                    ? checkedClasses
                    : disabled
                    ? disabledClasses
                    : active
                    ? activeClasses
                    : defaultClasses;
                return (
                  <div className="flex cursor-pointer items-center focus-visible:outline-none">
                    <span
                      className={`fake-radio group-focus-visible:ring-4group-focus-visible:ring-teal-light ${_class}`}
                    />
                    <span
                      className={classNames('body-normal sm:headline-normal', {
                        '!text-text-100': checked,
                        '!text-text-50': !checked,
                      })}
                    >
                      {label}
                    </span>
                  </div>
                );
              }}
            </RG.Option>
          );
        })}
      </RG>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};
