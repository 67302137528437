/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>1</td><td>ServiceType</td><td>Вид услуги</td></tr><tr><td>2</td><td>Equipment</td><td>Оборудование</td></tr><tr><td>3</td><td>IndividualSla</td><td>Индивидуальный SLA</td></tr><tr><td>4</td><td>Description</td><td>Описание заявки</td></tr><tr><td>5</td><td>Contacts</td><td>Контакты</td></tr></tbody></table></summary>
 */
export enum Outsourcing_SharedEnums_OrdersMassEditTypeEnum {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
}
