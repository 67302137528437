import { UseFormSetError } from 'react-hook-form';
import { RequestHandler } from '../finalStep/consts';

export const checkExecutorOgrnip = (
  ogrnip: string | null,
  individualName: string | null,
  individualCertificateFiles: OrderFile[] | null,
  requestHandler: RequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const setOgrnipError = () => {
    const ogrnipErrorMessage = 'Введите валидный ОГРНИП. ОГРНИП должен состоять из 15 цифр';
    setError('ogrnip', {
      type: 'manual',
      message: ogrnipErrorMessage,
    });
    toastErrors.push(ogrnipErrorMessage);
  };
  const setIndividualNameError = () => {
    const individualNameErrorMessage =
      '«Наименование ИП» должно быть зафиксировано и не превышать 150 символов';
    setError('individualName', {
      type: 'manual',
      message: individualNameErrorMessage,
    });
    toastErrors.push(individualNameErrorMessage);
  };
  const setIndividualCertificateFilesError = () => {
    const certificateErrorMessage = 'Необходимо приложить 1 или более файлов';
    setError('individualCertificateFiles', {
      type: 'manual',
      message: certificateErrorMessage,
    });
    toastErrors.push(certificateErrorMessage);
  };
  if (
    requestHandler === RequestHandler.DRAFT &&
    !ogrnip &&
    !individualName &&
    !individualCertificateFiles?.length
  ) {
    return toastErrors;
  }
  if (ogrnip === null) {
    setOgrnipError();
  } else {
    ogrnip = ogrnip.replace(/_/g, '');
    if (ogrnip.length < 15) {
      setOgrnipError();
    }
    if (ogrnip[0] !== '3') {
      setOgrnipError();
    }
    const targetCheckSum = Number.parseInt(ogrnip[14]);
    let checkSum = Number.parseInt(ogrnip.substring(0, 14));
    checkSum %= 13;
    if (checkSum > 9) {
      checkSum %= 10;
    }
    if (checkSum !== targetCheckSum) {
      setOgrnipError();
    }
  }
  if (individualName === null || individualName.trim().length < 1) {
    setIndividualNameError();
  }
  // if (individualCertificateFiles === null || individualCertificateFiles.length < 1) {
  if (!individualCertificateFiles?.length) {
    setIndividualCertificateFilesError();
  }
  return toastErrors;
};

export const checkBikAndBankSettlement = (
  bankSettlement: string | null,
  bik: string | null,
  requestHandler: RequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
): string[] => {
  const setBankSettlementError = () => {
    const bankSettlementErrorMessage =
      'Неверный формат расчетного счета или БИК. Расчетный счет должен состоять из 20 цифр';
    setError('bankSettlement', {
      type: 'manual',
      message: bankSettlementErrorMessage,
    });
    toastErrors.push(bankSettlementErrorMessage);
  };
  const setBikError = () => {
    const bikErrorMessage =
      'Неверный формат расчетного счета или БИК. БИК должен состоять из 9 цифр';
    setError('bik', {
      type: 'manual',
      message: bikErrorMessage,
    });
    toastErrors.push(bikErrorMessage);
  };
  if (requestHandler === RequestHandler.DRAFT && !bankSettlement && !bik) {
    return toastErrors;
  }
  if (bankSettlement === null) {
    setBankSettlementError();
  } else {
    bankSettlement = bankSettlement.replace(/_/g, '');
  }

  if (bankSettlement === null || bankSettlement.replace(/_/g, '').length < 20) {
    setBankSettlementError();
  } else {
    bankSettlement = bankSettlement.replace(/_/g, '');
  }

  if (bik === null || bik.replace(/_/g, '').length < 9) {
    setBikError();
  } else {
    bik = bik.replace(/_/g, '');
    const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];

    const bikAndAccount = bik.substring(bik.length - 3) + bankSettlement;
    if (bikAndAccount.length !== 23) {
      setBankSettlementError();
      setBikError();
    }

    let bikSum = 0;
    for (let i = 0; i < bikAndAccount.length; i++) {
      const digit = Number.parseInt(bikAndAccount[i], 10);
      if (Number.isNaN(digit)) {
        setBankSettlementError();
        setBikError();
      }
      bikSum += digit * coefficients[i];
    }

    if (bikSum % 10 !== 0) {
      setBankSettlementError();
      setBikError();
    }
  }
  
  return toastErrors;
};

export const checkSelfemployedCertificate = (
  files: OrderFile[] | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const filesErrorMessage = 'Необходимо приложить 1 или более файлов';
  if (!files?.length) {
    setError('selfemployedCertificate', {
      type: 'manual',
      message: filesErrorMessage,
    });
    toastErrors.push(filesErrorMessage);
  }
  return toastErrors;
};