import { EditIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FC } from 'react';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { getTimezone } from 'reduxStore/reducers/ProfileSlice/selectors/getTimezone';
import { Input } from 'ui/redesign/Input';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { ChangeTimeZoneForm } from './ChangeTimeZoneForm';
import { timezoneMap } from './ChangeTimeZoneForm/consts';
import './style.css';

export const ProfileSettings: FC = () => {
  const timeZone = useAppSelector(getTimezone);
  const dispatch = useAppDispatch();

  const changeTimezoneHandler = () => {
    dispatch(
      modalActions.handleOpenModal({
        content: <ChangeTimeZoneForm timezone={timeZone} />,
        options: {
          title: 'Изменить часовой пояс',
          modalClassName: 'w-full',
        },
      })
    );
  };

  function numberToTimezone(timezoneNumber: number): string {
    const timezone = timezoneMap[timezoneNumber];
    return timezone ? timezone : 'Invalid timezone value';
  }

  return (
    <PageWrapper title="Настройки">
      <div className="grid grid-cols-[minmax(0,600px)] gap-8">
        <Input
          value={numberToTimezone(timeZone)}
          label="Часовой пояс (UTC)"
          placeholder="Укажите часовой пояс (UTC)"
          onClick={changeTimezoneHandler}
          className="headline-normal -mr-6 border-0 border-b-2 border-solid border-light-50 text-text-100"
          readOnly
          icons={[
            {
              icon: <EditIcon />,
              onClick: changeTimezoneHandler,
            },
          ]}
        />
      </div>
    </PageWrapper>
  );
};
