import { FC } from 'react';
import { NoneOrdersProps } from './types';
import { IconArrowLeft } from 'assets/icons';
import classNames from 'classnames';

export const NoneOrders: FC<NoneOrdersProps> = ({ heading, icon, onButtonClick, description }) => {
  return (
    <section
      className={classNames(
        'flex w-full items-center gap-2 rounded-[10px] bg-base p-6 shadow-3xl sm:grid sm:grid-cols-none-orders-card-no-link sm:items-end',
        {
          'sm:none-orders-card-no-link': !onButtonClick,
        }
      )}
    >
      <div className="flex h-full items-center justify-center sm:w-full">{icon}</div>
      <div>
        <h3 className="headline-bold text-text-100">{heading}</h3>
        <p className="text-text-100">{description}</p>
      </div>
      {onButtonClick && (
        <button className="flex items-center gap-2 text-primary-60" onClick={onButtonClick}>
          Перейти <IconArrowLeft className="rotate-180" />
        </button>
      )}
    </section>
  );
};
