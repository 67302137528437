import { bodyColumnLoadings, bodyRowItemLoadings, headLoadings } from './constants';
import { Skeleton } from '../../Loaders/Skeleton';

export const TableLoading = () => {
  return (
    <div className="h-[60vh] w-full overflow-hidden rounded-[6px]  ">
      <div className="grid h-[10%] grid-cols-6 items-center gap-[1px] border-b-[1px] border-gray">
        {headLoadings.map((_, index) => (
          <Skeleton key={index} className="h-full w-full" />
        ))}
      </div>
      <div className="h-[90%]">
        {bodyColumnLoadings.map((_, index) => (
          <div
            key={index}
            className="grid h-[20%] grid-cols-6 items-center gap-[1px] border-b-[1px] border-gray-light"
          >
            {bodyRowItemLoadings.map((_, index) => (
              <Skeleton key={index} className="h-full w-full" />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
