import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

interface SimpleModalProps {
  open: boolean;
  children: React.ReactNode;
  onClose?: () => void;
  modalClasses?: string;
}

export default function SimpleModal({
  open,
  onClose,
  children,
  modalClasses = '',
}: SimpleModalProps) {
  useEffect(() => {
    const { classList } = document.body;
    if (open) {
      classList.add('fixed-container');
    } else {
      classList.remove('fixed-container');
    }

    return () => {
      classList.remove('fixed-container');
    };
  }, [open]);

  const root = document.getElementById('root');
  const modal = (
    <div
      className="fixed top-0 left-0 bottom-0 flex flex-col w-full p-2 z-40 overflow-y-auto bg-gray-dark/[0.5]"
      onClick={onClose}
    >
      <dialog
        open={open}
        onClick={(e) => e.stopPropagation()}
        className={`flex flex-col w-full p-0 relative z-50 rounded-xl shadow-sm overflow-auto ${modalClasses}`}
      >
        {children}
      </dialog>
    </div>
  );
  if (open) {
    return root && createPortal(modal, root);
  }
  return null;
}
