import { memo, useEffect } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { ReactComponent as CloseIcon } from 'assets/close.svg';

import { FieldSelect } from 'components/FieldSelect';

import {
  currentMonth,
  currentYear,
  months,
  receiptsFilterDefaultValues,
  years1S,
  yearsByPeriod,
} from './const';
import { FormFilterValues } from '.';
import { toast } from 'react-toastify';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';

interface FilterModalProps {
  control: Control<any>;
  open: boolean;
  onSubmit: () => void;
  onReset: () => void;
  onClose: () => void;
  type: '1s' | 'by-period';
  watch: UseFormWatch<FormFilterValues>;
}

function FilterModal({ control, open, onSubmit, onReset, onClose, type, watch }: FilterModalProps) {
  const years = type === '1s' ? years1S : yearsByPeriod;
  const actualMonths =
    currentYear === watch('year').value
      ? months.filter((month) => month.value <= currentMonth + 1)
      : months;

  useEffect(() => {
    if (open) {
      document.body.classList.add('fixed-container');
    } else {
      document.body.classList.remove('fixed-container');
    }
    return () => {
      document.body.classList.remove('fixed-container');
    };
  }, [open]);

  return (
    <dialog className="fixed top-0 z-30 m-0 h-screen w-full p-4" open={open}>
      <form
        className="flex h-full flex-col"
        onSubmit={(e) => {
          e.preventDefault();
          const currentDate = new Date();
          const selectedDate = new Date(watch('year').value, watch('month').value - 1);
          if (selectedDate < currentDate) {
            onSubmit();
            onClose();
          } else {
            toast.error('Некорректная дата');
          }
        }}
      >
        <div className="mb-3 flex items-start justify-between">
          <h1>Фильтры</h1>
          <CloseIcon onClick={onClose} className="h-5 w-5 cursor-pointer p-[3px] text-gray-dark" />
        </div>
        <div className="grow">
          <div className="mb-4">
            <FieldSelect
              name="year"
              control={control}
              placeholder="Год"
              options={years}
              variant="filter"
              defaultValue={receiptsFilterDefaultValues.year}
            />
          </div>
          <div className="mb-4">
            <FieldSelect
              name="month"
              control={control}
              placeholder="Месяц"
              options={actualMonths}
              variant="filter"
              defaultValue={receiptsFilterDefaultValues.month}
            />
          </div>
        </div>

        {/* <>
          <button className="btn-accent mb-6 w-full max-w-[288px] self-center" type="submit">
            Применить фильтры
          </button>
          <button
            className="btn-ghost w-full max-w-[288px] self-center"
            type="button"
            onClick={onReset}
          >
            Сбросить фильтры
          </button>
        </> */}
        <>
          <Button className="btn-accent mb-6 w-full max-w-[288px] self-center" type="submit">
            ПРИМЕНИТЬ ФИЛЬТРЫ
          </Button>
          <Button
            variant={ButtonVariant.OUTLINE}
            className="btn-ghost mb-10 w-full max-w-[288px] self-center"
            type="button"
            onClick={onReset}
          >
            СБРОСИТЬ ФИЛЬТРЫ
          </Button>
        </>
      </form>
    </dialog>
  );
}

export default memo(FilterModal);
