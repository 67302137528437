import FieldEducation from 'components/redesign/FieldEducation';
import { FormHandlers } from 'pages/ModerationRequest/types';
import { FC } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Step6Props } from './types';
import { ExecutorTypeEnum } from 'enums/executorType';

export const Step6Fields: FC<Step6Props> = ({
  control,
  errors,
  watch,
  setError,
  clearErrors,
  isFormDisabled,
  executorType,
  disableNavigate,
}) => {
  const formHandlers: FormHandlers = { setError, clearErrors, watch, errors };

  const {
    fields: workExperienceFields,
    append: workExperienceAppend,
    remove: workExperienceRemove,
  } = useFieldArray({
    control,
    name: 'workExperiences',
  });

  const {
    fields: primaryEducationFields,
    append: primaryEducationAppend,
    remove: primaryEducationRemove,
  } = useFieldArray({
    control,
    name: 'primaryEducation',
  });

  const {
    fields: secondaryEducationFields,
    append: secondaryEducationAppend,
    remove: secondaryEducationRemove,
  } = useFieldArray({
    control,
    name: 'secondaryEducation',
  });

  const workExperienceAppendHandler = () => {
    workExperienceAppend({ id: 0, description: '', files: [] });
  };

  const primaryEducationAppendHandler = () => {
    primaryEducationAppend({ id: null, description: '', files: [], isPrimary: true });
  };

  const secondaryEducationAppendHandler = () => {
    secondaryEducationAppend({ id: null, description: '', files: [], isPrimary: false });
  };

  return (
    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
      <div className="flex flex-col">
        {executorType === ExecutorTypeEnum.Selfemployed && (
          <>
            <FieldEducation
              name={'primaryEducation'}
              control={control}
              fields={primaryEducationFields}
              onAppend={primaryEducationAppendHandler}
              onDelete={primaryEducationRemove}
              formHandlers={formHandlers}
              title={'Основное образование'}
              disabled={isFormDisabled}
              errors={errors}
              clearErrors={clearErrors}
              disableNavigate={disableNavigate}
            />
            <FieldEducation
              name={'secondaryEducation'}
              control={control}
              fields={secondaryEducationFields}
              onAppend={secondaryEducationAppendHandler}
              onDelete={secondaryEducationRemove}
              formHandlers={formHandlers}
              title={'Доп. образование'}
              disabled={isFormDisabled}
              errors={errors}
              clearErrors={clearErrors}
              disableNavigate={disableNavigate}
            />
          </>
        )}
        <FieldEducation
          name={'workExperiences'}
          control={control}
          fields={workExperienceFields}
          onAppend={workExperienceAppendHandler}
          onDelete={workExperienceRemove}
          title={executorType === ExecutorTypeEnum.Selfemployed ? 'Опыт работы' : 'Добавить'}
          formHandlers={formHandlers}
          errors={errors}
          disabled={isFormDisabled}
          clearErrors={clearErrors}
          disableNavigate={disableNavigate}
        />
      </div>
    </div>
  );
};
