import { useRef } from 'react';

interface UseRangeParam {
  page: number;
  pagesCount: number;
  displayedNumbers: number;
}

/**
 * Определяем диапазон отображаемых кнопок
 * @returns
 */
export default function useRange({ page, pagesCount, displayedNumbers }: UseRangeParam) {
  const currentDisplayedNumbers = displayedNumbers - 4; // 2 обязательных страницы, 2 точки

  const range = useRef({ from: page, to: page + currentDisplayedNumbers });

  // Действия вначале списка
  if (range.current.from === 1) {
    range.current.to = currentDisplayedNumbers + 1;
  }

  if (page < range.current.from) {
    range.current.from = page;
    range.current.to = page + currentDisplayedNumbers - 1;
  }

  // Действия вконце списка
  if (range.current.to === pagesCount) {
    range.current.from = pagesCount - currentDisplayedNumbers;
  }

  if (page > range.current.to) {
    range.current.from = page - currentDisplayedNumbers + 1;
    range.current.to = page;
  }

  return range.current;
}
