export enum AgentRequestStatus {
  // Инициация
  Iniciation = 1,
  // Проверка
  Verification = 2,
  // Доработка
  NeedsAdditionalWork = 3,
  // В работе Агента
  InProgress = 4,
  // Отклонена Агентом
  Declined = 5,
  // На оплату
  ForPayment = 6,
}
export enum AgentRequestStatusName {
  // Инициация
  Iniciation = 'Инициация',
  // Проверка
  Verification = 'Проверка',
  // Доработка
  NeedsAdditionalWork = 'Доработка',
  // В работе Агента
  InProgress = 'В работе',
  // Отклонена Агентом
  Declined = 'Отправлено в работу Инициатору',
  // На оплату
  ForPayment = 'На оплату',
}
