import { Tab } from '@headlessui/react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { Dispatch, ReactNode, SetStateAction } from 'react';

interface TabGroupProps {
  tabItems: { title: string; permissions?: boolean; counter?: number }[];
  tabPanelsContent: ReactNode[];
  setSelectedIndex?: Dispatch<SetStateAction<number>>;
  selectedIndex?: number;
}

export default function TabGroup({
  tabItems,
  tabPanelsContent,
  selectedIndex,
  setSelectedIndex,
}: TabGroupProps) {
  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
      <Tab.List
        className={classNames(
          'flex w-full items-center justify-around border-light-50 sm:justify-start sm:gap-5 sm:border-b-[2px]',
          {
            '!justify-start': tabItems.length === 1,
          }
        )}
      >
        {tabItems.map((item, i) => {
          if (item.permissions !== undefined && !item.permissions) {
            return null;
          }
          return (
            <Tab key={i} className="focus-visible:outline-none sm:mx-2">
              {({ selected }) => (
                <span
                  className={`box-border px-4 py-2
                    ${
                      selected
                        ? 'caloout-medium sm:headline-medium relative inline-block w-full text-primary-60 md:rounded-tr-lg md:rounded-tl-lg'
                        : 'caloout-normal sm:headline-normal text-text-50'
                    }
                  `}
                >
                  {/* {i > 0 ? item.title : 'Данные по заявке'} */}
                  {item.title ? item.title : 'Данные по заявке'}
                  {item.counter && (
                    <span
                      className={`rounded-xl px-1 text-white ${
                        selected ? 'bg-blue' : 'bg-text-50'
                      }`}
                    >
                      {item.counter}
                    </span>
                  )}
                  {selected && (
                    <motion.div
                      className="absolute left-0 bottom-[-2px] h-[2px] w-full rounded-[10px] bg-primary-60"
                      layoutId="underline"
                    />
                  )}
                </span>
              )}
            </Tab>
          );
        })}
      </Tab.List>
      <Tab.Panels>
        {tabPanelsContent.map((panel, _index) => (
          <Tab.Panel className="focus-visible:outline-none" key={_index}>
            <>{panel}</>
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}
