import { ModerationRequestStatusId } from 'enums/moderationRequestStatus';
import { ExecutorTypeEnum } from 'reduxStore/reducers/ProfileSlice/types';

export const defaultRequest = {
  id: 0,
  rowVersion: null,
  moderatedUserId: 0,
  recruiterId: 0,
  inn: null,
  bik: null,
  bankSettlement: null,
  mobilePhone: null,
  email: null,
  created: null,
  acceptsOffer: false,
  parentId: 0,
  birthDate: '',
  executorType: ExecutorTypeEnum.Selfemployed,
  status: ModerationRequestStatusId.Draft,
  fullName: null,
  citizenship: 1,
  pyrusUrl: null,
  passport: {
    series: null,
    number: null,
    dateOfIssue: '',
    issuedBy: null,
    unitCode: null,
    files: [],
    registrationAddress: null,
    birthPlace: null,
  },
  executorPhotos: {
    files: [],
  },
  selfemployedCertificate: {
    files: [],
  },
  educations: null,
  workExperiences: [],
  serviceCities: [],
  executorSpecializations: [],
  individualCertificate: {
    ogrnip: null,
    individualName: null,
    files: [],
  },
  userRegistration: '',
  recruiterFullName: null,
  checkLists: {},
  certificateElectricalSafety: {
    files: [],
  },
  certificateWorkHeight: {
    files: [],
  },
};

// export const commonErrorMessage =
//   'Во время выполнения запроса произошла ошибка. Обратитесь к администратору';
export const commonErrorMessage = 'Валидация не пройдена';
export const electricalErrorMessage =
  'Количество сотрудников с сертификатом по электробезопасности должно быть больше 0';
export const workHeightErrorMessage =
  'Количество сотрудников с допуском к работам на высоте должно быть больше 0';
