import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { FieldInput } from 'components/FieldInput';
import { Annotation2, AnnotationVariant } from 'ui/Annotation/Annotation';

import { ReactComponent as LeftArrowIcon } from 'assets/left-arrow.svg';
import { useAppSelector } from 'hooks/useAppSelector';
import useMediaQuery from 'hooks/useMediaQuery';
import { useTimer } from 'hooks/useTimer';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { changePhoneNumber, sendCodeToNewPhone, sendPhoneCode } from 'services';
import { ProfileChangeResponse } from 'types';
import { SMALL_DEVICE_MEDIA_QUERY } from 'utils/settings';

interface ChangePhoneForm {
  phoneNumber: string;
  confirmCode: string;
}

type FormState = 'INIT' | 'CODE_SENT';

export const ChangePhone = () => {
  const [state, setState] = useState<FormState>('INIT');
  const [disabled, setDisabled] = useState(false);
  const queryResultRef = useRef<ProfileChangeResponse | null>(null);
  const navigate = useNavigate();

  const userProfile = useAppSelector(getProfile);
  const isSmallDevice = useMediaQuery(SMALL_DEVICE_MEDIA_QUERY);

  const {
    control,
    formState: { errors },
    watch,
    setError,
  } = useForm<ChangePhoneForm>({
    defaultValues: {
      phoneNumber: userProfile?.phoneNumber || '',
      confirmCode: '',
    },
  });

  const TIMEOUT = 4 * 60 + 59;

  const [timer, resetTimer] = useTimer(0);

  const currentPhone = watch('phoneNumber').replace(/\D+/g, '');
  const profilePhone = userProfile?.phoneNumber?.replace(/\D+/g, '');

  const isPhoneEqual = currentPhone === profilePhone || currentPhone.length !== 11;

  const sendCodeToNewPhoneHandler = async () => {
    const response = await sendCodeToNewPhone({ newPhoneNumber: watch('phoneNumber') });
    if (response.status === 200) {
      setState('CODE_SENT');
      queryResultRef.current = response.data;
      resetTimer(TIMEOUT);
    }
  };

  const sendPhoneCodeHandler = async () => {
    const response = await sendPhoneCode({ phoneNumber: watch('phoneNumber') });
    if (response.status === 200) {
      queryResultRef.current = response.data;
      resetTimer(TIMEOUT);
    }
  };

  const confirmPhoneHandler = async () => {
    setDisabled(true);
    const response = await changePhoneNumber({
      newPhoneNumber: watch('phoneNumber'),
      code: watch('confirmCode'),
    });
    if (response.status === 200) {
      if (response.data.isSuccess) {
        navigate(-1);
      } else {
        queryResultRef.current = response.data;
        setError('confirmCode', { type: 'required', message: ' ' });
        setDisabled(false);
        return;
      }
    }
  };

  const CodeRequestTimeout = ({ timer }: { timer: number }) => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer - minutes * 60;
    return (
      <p className="mt-2 text-xs text-gray-medium">
        Повторно отправить код через {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds} сек.
      </p>
    );
  };

  return (
    <div>
      <div className="flex justify-between gap-4">
        <h1>Изменение номера телефона</h1>
        {!isSmallDevice && (
          <button
            type="button"
            className="flex items-center justify-center gap-3 self-start rounded-md border border-gray-light px-3 py-1 text-center text-xs text-black transition-[background] delay-100 hover:bg-gray-light focus-visible:border-transparent focus-visible:outline-gray-light"
            onClick={() => navigate(-1)}
          >
            <LeftArrowIcon className="text-black" onClick={() => navigate(-1)} />
            Назад
          </button>
        )}
      </div>
      {state === 'CODE_SENT' && (
        <>
          {queryResultRef.current && (
            <Annotation2
              variant={
                queryResultRef.current.isSuccess ? AnnotationVariant.TEAL : AnnotationVariant.RED
              }
            >
              <p>{queryResultRef.current.message}</p>
            </Annotation2>
          )}
        </>
      )}
      <form>
        <div className="mb-4">
          <FieldInput
            name="phoneNumber"
            control={control}
            type="tel"
            label="Номер телефона:"
            disabled={state === 'CODE_SENT'}
          />
        </div>
        {state === 'CODE_SENT' && (
          <div>
            <FieldInput
              name="confirmCode"
              control={control}
              errors={errors}
              label="Код подтверждения:"
            />
            {timer > 0 && <CodeRequestTimeout timer={timer} />}
          </div>
        )}
      </form>
      {state === 'INIT' && (
        <>
          <button
            className="btn-accent mt-8 w-full max-w-[288px] self-center"
            disabled={isPhoneEqual}
            onClick={sendCodeToNewPhoneHandler}
          >
            Отправить код подтверждения
          </button>
        </>
      )}
      {state === 'CODE_SENT' && (
        <>
          <button
            className="btn-accent mt-8 w-full max-w-[288px] self-center"
            disabled={!watch('confirmCode').length || disabled}
            onClick={confirmPhoneHandler}
          >
            Подтвердить номер телефона
          </button>
          <button
            className="mt-6 w-full max-w-[288px] self-center text-xs text-blue disabled:text-blue-light"
            disabled={timer > 0}
            onClick={sendPhoneCodeHandler}
          >
            Отправить код
          </button>
        </>
      )}
    </div>
  );
};
