import { OrderTypesEnum } from '../enums';
import { Tab } from './types';

export const tabs: Tab[] = [
  { link: '/orders/new', orderType: OrderTypesEnum.new, name: 'Новые' },
  { link: '/orders/inProgress', orderType: OrderTypesEnum.inProgress, name: 'В работе' },
  { link: '/orders/completed', orderType: OrderTypesEnum.completed, name: 'Завершённые' },
];

export const subcontractorTabs: Tab[] = [
  { link: '/orders/inProgress', orderType: OrderTypesEnum.inProgress, name: 'В работе' },
  { link: '/orders/completed', orderType: OrderTypesEnum.completed, name: 'Завершённые' },
];
