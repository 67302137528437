import { GroupBase, StylesConfig } from 'react-select';
import { OptionType } from './types';

export const defaultStyles: StylesConfig<OptionType, boolean, GroupBase<OptionType>> = {
  indicatorSeparator: () => {
    return {
      display: 'none',
    };
  },
  indicatorsContainer: (base) => ({
    ...base,
    padding: 0,
    height: '34px',
  }),
  dropdownIndicator: (base) => ({
    ...base,
  }),
  menu: (provided) => ({
    ...provided,
  }),
  menuList: (base) => ({
    ...base,
  }),
  option: (base, state) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: state.isFocused ? '#F1F1F2' : '',
    color: '#2E2E2E',
  }),
  singleValue: (base) => ({
    ...base,
    textOverflow: 'clip',
    whiteSpace: 'normal',
    color: '#2E2E2E',
    lineHeight: '1rem',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0',
    maxHeight: '200px',
    overflowY: 'auto',
  }),
  input: (base) => ({
    ...base,
    padding: 0,
  }),
  container: (base) => ({
    ...base,
    marginTop: '-6px',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 18,
  }),
  control: (base, state) => ({
    ...base,
    border: 'none',
    borderRadius: 0,
    lineHeight: '0.875rem',
    minHeight: '33px',
    borderBottom: state.isFocused ? '1px solid #E4E4E5' : '1px solid #E4E4E5',
    boxShadow: state.isFocused ? 'none' : 'none',
    backgroundColor: 'white',
  }),
};

export const variantWithIconsStyles: StylesConfig<OptionType, boolean, GroupBase<OptionType>> = {
  ...defaultStyles,
  container: (base) => ({
    ...base,
    fontSize: '14px',
  }),
  option: (base, state) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: state.isFocused ? '#F1F1F2' : '',
    color: '#2E2E2E',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0.875rem 1rem',
    color: '#fff',
  }),
  input: (base) => ({
    ...base,
    color: 'transparent',
  }),
  control: (base) => ({
    ...base,
    borderColor: 'transparent !important',
    boxShadow: 'none !important',
    '&:hover': {
      border: 'none',
    },
    backgroundColor: 'white',
  }),
};

export const isMultiStyles: StylesConfig<OptionType, boolean, GroupBase<OptionType>> = {
  ...defaultStyles,
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#F1F1F2' : '',
    fontSize: 18,
    padding: '.5rem .75rem',
    color: '#2E2E2E !important',
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    cursor: 'pointer',
  }),
  multiValue: (base) => ({
    ...base,
    borderRadius: '24px',
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: '#000',
    paddingTop: '3px',
    paddingBottom: '3px',
    borderRadius: '24px',
    '&:hover': {
      backgroundColor: '#9FA0A7',
      color: '#000',
    },
  }),
  container: (base) => ({
    ...base,
    color: '#BFBFBF',
    marginTop: '-6px',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 18,
  }),
  control: (base) => ({
    ...base,
    cursor: 'pointer',
    borderColor: 'transparent !important',
    boxShadow: 'none !important',
    backgroundColor: 'white',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0 !important',
    maxHeight: '250px !important',
    overflowY: 'auto',
  }),
};

export const isColouredStyles: StylesConfig<OptionType, boolean, GroupBase<OptionType>> = {
  ...defaultStyles,
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? '#F1F1F2' : '',
    cursor: 'pointer',
    color: 'black',
  }),
  container: (base) => ({
    ...base,
    borderRadius: '10px',
  }),
  control: (base) => ({
    ...base,
    backgroundColor: '#1626B6',
    color: '#fff',
    borderRadius: '10px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '10px',
    color: '#fff',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#fff',
    textAlign: 'end',
    fontSize: '16px',
    fontWeight: '500',
  }),
  input: (base) => ({
    ...base,
    color: 'transparent',
    cursor: 'pointer',
  }),
  singleValue: (base) => ({
    ...base,
    color: '#fff',
    textAlign: 'end',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#fff',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
    },
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: '34px',
  }),
};

export const filterStyles: StylesConfig<OptionType, boolean, GroupBase<OptionType>> = {
  ...defaultStyles,
  valueContainer: (base) => ({
    ...base,
    maxHeight: '200px',
    overflowY: 'scroll',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#F1F1F2' : '',
    fontSize: 18,
    padding: '.5rem .75rem',
    color: '#2E2E2E !important',
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    cursor: 'pointer',
  }),
  multiValue: (base) => ({
    ...base,
    borderRadius: '24px',
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: '#000',
    paddingTop: '3px',
    paddingBottom: '3px',
    borderRadius: '24px',
    '&:hover': {
      backgroundColor: '#9FA0A7',
      color: '#000',
    },
  }),
};

export const filterMobileStyles: StylesConfig<OptionType, boolean, GroupBase<OptionType>> = {
  ...filterStyles,
  valueContainer: (base) => ({
    ...base,
    maxHeight: '70px',
    overflowY: 'scroll',
  }),
};

export const filterNoteStyles: StylesConfig<OptionType, boolean, GroupBase<OptionType>> = {
  ...filterStyles,
  valueContainer: (base) => ({
    ...base,
    maxHeight: '200px',
    overflowY: 'scroll',
  }),
};

export const backgroundTypes = {
  default: 'transparent',
  accent: '#F1F1F2',
};

export const multiOptionStyle = {
  alignItems: 'center',
  backgroundColor: backgroundTypes['default'],
  color: 'inherit',
  display: 'flex ',
};
