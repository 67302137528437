import React from 'react';
import {
  Controller,
  Control,
  FieldErrors,
  RegisterOptions,
  UseFormClearErrors,
} from 'react-hook-form';

import { Input, InputProps } from 'ui/Input';

import useFieldErrorMessage from 'hooks/useFieldErrorMessage';
import { CloseIcon } from 'assets/icons';

interface FieldInputProps extends InputProps {
  name: string;
  label: string;
  control: Control<any>;
  errors?: {
    [name: string]: FieldErrors;
  };
  rules?: RegisterOptions;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  clearErrors?: UseFormClearErrors<any>;
}

export const FieldInput = ({
  name,
  label,
  control,
  errors,
  rules,
  onChange,
  clearErrors,
  ...other
}: FieldInputProps) => {
  const errorMessage = useFieldErrorMessage(name, errors);
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Input
          label={label}
          errorMessage={errorMessage}
          value={field.value}
          onChange={(val) => {
            field.onChange(val);
            onChange && onChange(val);
            clearErrors && clearErrors(name);
          }}
          icons={[
            <CloseIcon
              key={1}
              className="text-text-50 hover:text-text-100"
              onClick={() => {
                field.onChange('');
              }}
            />,
          ]}
          {...other}
        />
      )}
    />
  );
};
