import { request } from 'api';
import { ModerationRequest } from './types';

export const ModerationRequestService = {
  getCurrentModerationRequest() {
    return request<ModerationRequest>('get', '/api/v4/Onboarding/ModerationRequests/Current');
  },

  getCurrentModerationRequestWithCheckLists() {
    return request<ModerationRequest>('get', '/api/v4/Onboarding/ModerationRequests/Current');
  },

  createModerationRequest(body: ModerationRequest) {
    return request<ModerationRequest>('post', '/api/v4/Onboarding/ModerationRequests', {
      data: body,
    });
  },
  createSecondaryModerationRequest(body: ModerationRequest) {
    return request<ModerationRequest>(
      'post',
      '/api/v4/Onboarding/ModerationRequests/CreateSecondary',
      {
        data: body,
      }
    );
  },
  draftModerationRequest(body: ModerationRequest) {
    return request<ModerationRequest>(
      'post',
      `/api/v4/Onboarding/ModerationRequests/${body.id}/Draft`,
      {
        data: body,
      }
    );
  },
  sendModerationRequest(body: ModerationRequest) {
    return request<ModerationRequest>(
      'post',
      `/api/v4/Onboarding/ModerationRequests/${body.id}/Send`,
      {
        data: body,
      }
    );
  },
  elaborateModerationRequest(body: ModerationRequest) {
    return request<ModerationRequest>(
      'post',
      `/api/v4/Onboarding/ModerationRequests/${body.id}/Elaboration`,
      {
        data: body,
      }
    );
  },
};
