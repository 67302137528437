import useDebounce from 'hooks/useDebounce';
import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { MultiValue } from 'react-select';
import { Settlement, SettlementShort } from 'types';
import { Select } from 'ui/redesign/Select';
import { OptionType } from 'ui/redesign/Select/types';
import { transformToSelectOptions } from 'utils/helpers';
import { CommonSettlementsResponse, SettlementsSelectProps } from './types';
import { endpoints } from 'api/endpoints';
import { request } from 'api';

export const SettlementsSelect: FC<SettlementsSelectProps> = ({
  control,
  isMulti = false,
  name,
  label,
  onChange,
  setValue,
  isFormDisabled,
  errorMessage,
  clearErrors,
  ...rest
}) => {
  const [settlements, setSettlements] = useState<Settlement[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCities('');
    return () => {
      setIsLoading(false);
    };
  }, []);

  const getCities = async (template: string) => {
    setIsLoading(true);
    const response = await request<CommonSettlementsResponse>(
      endpoints.GET_COMMON_SETTLEMENTS.type,
      endpoints.GET_COMMON_SETTLEMENTS.url(template)
    );
    if (response.status === 200) {
      setSettlements(response.data.pagedList);
    }
    setIsLoading(false);
  };

  const debouncedSettlements = useDebounce(getCities, 500);

  const getUniqueCities = (citiesArr: Settlement[], valueArr?: OptionType<Settlement>[]) => {
    let uniqueCities = citiesArr;

    valueArr &&
      valueArr.forEach((value) => {
        uniqueCities = uniqueCities.filter((city) => city.fiasId !== value.value.fiasId);
      });

    return uniqueCities;
  };

  return (
    <div className="field-select w-full max-w-[640px]">
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Select
              name={name}
              variant="cities"
              value={field.value}
              label={label}
              isMulti
              isDisabled={isFormDisabled}
              errorMessage={errorMessage}
              onChange={(value, meta) => {
                field.onChange(value);
                onChange && onChange(value as MultiValue<OptionType<SettlementShort>>, meta);
                clearErrors && clearErrors(name);
              }}
              options={transformToSelectOptions<Settlement>(
                getUniqueCities(settlements, field.value),
                'objectFullName'
              )}
              isLoading={isLoading}
              onInputChange={(newValue: string) => {
                if (newValue.length < 3) {
                  return;
                }
                debouncedSettlements(newValue);
              }}
              {...rest}
            />
          );
        }}
      />
    </div>
  );
};
