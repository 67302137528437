import React, { useState } from 'react';
import { ReactComponent as CheckIcon } from 'assets/check.svg';

export interface CheckboxProps {
  label: string;
  name: string;
  value?: boolean;
  id?: any;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  errorMessage?: string;
}

export const Checkbox = ({
  label,
  name,
  value,
  id = '',
  onChange,
  disabled,
  errorMessage,
  ...other
}: CheckboxProps) => {
  const [checked, setChecked] = useState(value || false);

  const onChangeHandler = (checked: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(checked.target.checked);
    onChange && onChange(checked.target.checked);
  };

  const defaultClasses = 'after:border-gray-medium';
  const disabledClasses = 'after:bg-gray-light after:border-gray-medium';
  const checkedClasses = 'after:border-teal';
  const checkedDisabledClasses = 'after:border-gray-medium after:bg-gray-light';

  const _class =
    checked && disabled
      ? checkedDisabledClasses
      : checked
      ? checkedClasses
      : disabled
      ? disabledClasses
      : defaultClasses;

  return (
    <>
      <label
        className={`check-hover flex cursor-pointer items-start transition-all ${
          disabled ? 'pointer-events-none' : ''
        }`}
        htmlFor={`checkbox${id}`}
      >
        <span
          className={`mr-2 mt-0.5 flex items-center justify-center after:block after:h-4 after:w-4 after:rounded after:border after:transition-all ${_class}`}
        >
          {checked && disabled ? (
            <CheckIcon className="absolute h-3 w-3 text-gray-medium" />
          ) : checked ? (
            <CheckIcon className="absolute h-3 w-3 text-teal" />
          ) : null}
        </span>
        <span className="text-xs">{label}</span>
        <input
          className="input-default absolute appearance-none"
          type="checkbox"
          id={`checkbox${id}`}
          checked={checked}
          onChange={onChangeHandler}
          name={name}
          {...other}
        />
      </label>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </>
  );
};
