const api_prefixes = {
  mainRequests: 'Outsource/MainRequests',
};

enum EndpointKeys {
  // Файлы
  'DOWNLOAD_FILE',
  'DELETE_FILE',
  'UPLOAD_FILE',
  'GENERATE_DOCUMENT',
  // Пользователь
  'GET_PROFILE',
  'GET_USER_PERMISSIONS',
  // Склады, доставка, города, адреса
  'GET_WAREHOUSES',
  'GET_DELIVERY_ADDRESSES',
  'GET_COMMON_SETTLEMENTS',
  'GET_EXTENDED_SUGGESTIONS',
  'GET_COMMON_SETTLEMENT_BY_SUGGESTION',
  'GET_REGION_BY_SUGGESTION',
  'GET_ALL_FEDERAL_DISTRICTS',
  // Чеки
  'UPDATE_EXECUTOR_RECEIPTS',
  'GET_MY_EXECUTOR_RECEIPTS',
  'ADD_EXECUTOR_RECEIPT_FILE',
  'GET_EXECUTOR_RECEIPT_BY_ID',
  'ADD_FILE_BY_PERIOD',
  'GET_RECEIPTS_BY_PERIOD',
  // Заказы
  'GET_ORDER_DETAILS',
  'GET_ORDERS',
  'COMPLETED_BY_EXECUTOR',
  'CANCELED_BY_EXECUTOR',
  'REFUSED_BY_EXECUTOR',
  'BECOME_EXECUTOR',
  'NEARBY_REQUESTS',
  // ЗИПы
  'GET_LOCAL_SPS',
  'GET_SPS_REQUEST_BY_ENTITY',
  'GET_SPS_REQUEST_BY_ENTITY_WITH_ACTIONS',
  'GET_SPS_NOMENCLATURES',
  'GET_SPS_NOMENCLATURES_BY_TEMPLATE',
  'CREATE_SPS_REQUEST',
  'EDIT_SPS_REQUEST',
  'SET_SPS_AMOUNT_USED',
  // Разное
  'GET_PROJECTS_SHORT',
  'GET_LEGAL_ENTITIES_SHORT',
  // 'GET_AVAILABLE_ACTIONS',
  'GET_TAB_SETTINGS',
  // Модерация
  'MODERATION_REQUEST_CREATE',
  'MODERATION_REQUEST_GET_CURRENT',
  'MODERATION_REQUEST_UPDATE_DRAFT',
  'MODERATION_REQUEST_SEND_TO_MODERATION',
  'MODERATION_REQUEST_SEND_ELABORATION',
  'MODERATION_REQUEST_CREATE_SECONDARY',
  // Чат
  'CHAT_ORDER_GET',
  'CHAT_ORDER_SEND_MESSAGE',
  'CHAT_ORDER_VIEW_MESSAGE',
  'CHAT_MODERATION_GET',
  'CHAT_MODERATION_SEND_MESSAGE',
  'CHAT_MODERATION_VIEW_MESSAGE',
  'CHAT_CAN_SEND_MESSAGE',
  'CHAT_JOIN',
  'CHAT_JOIN_BY_ENTITY',
  'START_CHAT_IN_ORDER',
  // Агенты
  'AGENT_GET_REQUESTS',
  'AGENT_GET_REQUEST_DETAILS',
  'AGENT_APPROVE_REQUEST',
  'AGENT_DECLINE_REQUEST',
  // Профиль
  'PROFILE_SEND_PHONE_CODE',
  'PROFILE_SEND_CODE_TO_NEW_PHONE',
  'PROFILE_SEND_EMAIL_CONFIRM_CODE',
  'PROFILE_CONFIRM_EMAIL',
  'PROFILE_CHANGE_PHONE',
  'PROFILE_CHANGE_TIMEZONE',
  'PROFILE_GET_USER_COMMON_SETTLEMENTS',
  'PROFILE_UPDATE_USER_COMMON_SETTLEMENTS',
  'GET_SITE_CONTACTS',
  // Субподрядчик, анкета
  'SUBCONTRACTOR_MODERATION_CURRENT',
  'SUBCONTRACTOR_MODERATION_CREATE',
  'SUBCONTRACTOR_MODERATION_UPDATE',
  'SUBCONTRACTOR_MODERATION_SEND_TO_VERIFY',
  'SUBCONTRACTOR_MODERATION_CURRENT_WITH_CHECKLIST',
  'SUBCONTRACTOR_MODERATION_REQUEST_SEND_ELABORATION',
  // Статические файлы
  'STATIC_FILE_GET',
  'STATIC_FILE_DOWNLOAD',
  'STATIC_FILE_COLLECTION',
}

interface Endpoint {
  url: (...args: any[]) => string;
  type: 'POST' | 'GET' | 'PUT' | 'DELETE';
}

export const endpoints: { [key in keyof typeof EndpointKeys]: Endpoint } = {
  GET_PROFILE: {
    url: () => '/api/v1/Users/Current',
    type: 'GET',
  },
  GET_ORDERS: {
    url: () => `/api/v2/${api_prefixes.mainRequests}/ByFilter`,
    type: 'POST',
  },
  GET_ORDER_DETAILS: {
    url: (id) => `/api/v2/${api_prefixes.mainRequests}/${id}`,
    type: 'GET',
  },
  DOWNLOAD_FILE: {
    url: (hash) => `/api/v1/File/Download/${hash}`,
    type: 'GET',
  },
  DELETE_FILE: {
    url: (hash) => `/api/v1/File/Delete/${hash}`,
    type: 'DELETE',
  },
  UPLOAD_FILE: {
    url: () => '/api/v1/File',
    type: 'POST',
  },
  COMPLETED_BY_EXECUTOR: {
    url: (orderId: number) => `/api/v2/${api_prefixes.mainRequests}/${orderId}/CompletedByExecutor`,
    type: 'POST',
  },
  CANCELED_BY_EXECUTOR: {
    url: (orderId: number) =>
      `/api/v2/${api_prefixes.mainRequests}/${orderId}/CancellationByExecutor`,
    type: 'POST',
  },
  REFUSED_BY_EXECUTOR: {
    url: (orderId: number) => `/api/v2/${api_prefixes.mainRequests}/${orderId}/RefuseByExecutor`,
    type: 'POST',
  },
  GENERATE_DOCUMENT: {
    url: (orderId: number) => `/api/v2/${api_prefixes.mainRequests}/${orderId}/GenerateDocument`,
    type: 'POST',
  },
  GET_PROJECTS_SHORT: {
    url: () => '/api/v1/Projects/GetProjectsShort',
    type: 'GET',
  },
  BECOME_EXECUTOR: {
    url: (orderId: number) => `/api/v2/${api_prefixes.mainRequests}/${orderId}/BecomeExecutor`,
    type: 'POST',
  },
  NEARBY_REQUESTS: {
    url: (id, mainRequestId, pageNumber = 1, pageSize = 25) =>
      `/api/v2/Outsource/MainRequests/${id}/NearbyRequests?&MainRequestId=${mainRequestId}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
    type: 'GET',
  },
  GET_LOCAL_SPS: {
    url: (projectId) => `/api/v1/Outsourcing/Sps/${projectId}/GetLocalSps`,
    type: 'GET',
  },
  UPDATE_EXECUTOR_RECEIPTS: {
    url: () => '/api/v1/Billing/ExecutorReceipts/AddFile',
    type: 'POST',
  },
  // GET_EXECUTOR_RECEIPTS: {
  //   url: (userId, year, months) =>
  //     `/api/v1/Billing/ExecutorReceipts?UserId=${userId}&Year=${year}${months ? `&${months}` : ''}`,
  //   type: 'GET',
  // },
  GET_MY_EXECUTOR_RECEIPTS: {
    url: (year, month) => `/api/v2/Billing/ExecutorReceipts/My?Year=${year}&month=${month}`,
    type: 'GET',
  },
  GET_EXECUTOR_RECEIPT_BY_ID: {
    url: (id) => `/api/v2/Billing/ExecutorReceipts/${id}`,
    type: 'GET',
  },
  GET_SPS_REQUEST_BY_ENTITY: {
    url: (entityID, entityTypeID) =>
      `/api/v1/SpsManagement/SpsRequests/ByEntity?EntityId=${entityID}&EntityTypeId=${entityTypeID}`,
    type: 'GET',
  },
  ADD_EXECUTOR_RECEIPT_FILE: {
    url: () => `/api/v2/Billing/ExecutorReceipts/AddFile`,
    type: 'POST',
  },
  GET_SPS_REQUEST_BY_ENTITY_WITH_ACTIONS: {
    url: (entityID, entityTypeID) =>
      `/api/v1/SpsManagement/SpsRequests/ByEntityWithActions?EntityId=${entityID}&EntityTypeId=${entityTypeID}`,
    type: 'GET',
  },
  GET_SPS_NOMENCLATURES: {
    url: () => '/api/v1/SpsManagement/SpsNomenclatures/GetSpsNomenclatures',
    type: 'GET',
  },
  GET_SPS_NOMENCLATURES_BY_TEMPLATE: {
    url: (template) =>
      `/api/v1/SpsManagement/SpsNomenclatures/SpsNomenclaturesByTemplate?Template=${template}&PageNumber=1&PageSize=25`,
    type: 'GET',
  },
  GET_WAREHOUSES: {
    url: () => '/api/v1/SpsManagement/Warehouse/GetForExecutor',
    type: 'GET',
  },
  GET_DELIVERY_ADDRESSES: {
    url: (orderId: number) => `/api/v1/SpsManagement/DeliveryAddress/${orderId}/GetForExecutor`,
    type: 'GET',
  },
  CREATE_SPS_REQUEST: {
    url: (orderId) => `/api/v1/SpsManagement/SpsRequests/Create/${orderId}`,
    type: 'POST',
  },
  EDIT_SPS_REQUEST: {
    url: () => `/api/v1/SpsManagement/SpsRequests/Edit`,
    type: 'POST',
  },
  GET_USER_PERMISSIONS: {
    url: () => '/api/v1/Users/CurrentPermissions',
    type: 'GET',
  },
  GET_COMMON_SETTLEMENTS: {
    url: (template, pageNumber = 1, pageSize = 25, federalDistrictsIds, regionIds) =>
      `/api/v1/GeoData/CommonSettlements/ByTemplate?${
        federalDistrictsIds ? `${federalDistrictsIds}` : ''
      }${regionIds ? `${regionIds}` : ''}${
        template ? `&Template=${template}` : ''
      }&PageNumber=${pageNumber}&PageSize=${pageSize}`,
    type: 'GET',
  },
  // GET_AVAILABLE_ACTIONS: {
  //   url: (orderId) => `/api/v1/Outsourcing/Orders/${orderId}/Actions`,
  //   type: 'GET',
  // },
  SET_SPS_AMOUNT_USED: {
    url: () => `/api/v1/SpsManagement/SpsRequests/SetSpsRequestItemsAmountUsed`,
    type: 'POST',
  },
  MODERATION_REQUEST_CREATE: {
    url: () => '/api/v1/Onboarding/ModerationRequests',
    type: 'POST',
  },
  MODERATION_REQUEST_GET_CURRENT: {
    url: () => '/api/v1/Onboarding/ModerationRequests/Current',
    type: 'GET',
  },
  MODERATION_REQUEST_UPDATE_DRAFT: {
    url: (id: number) => `/api/v1/Onboarding/ModerationRequests/${id}/Draft`,
    type: 'POST',
  },
  MODERATION_REQUEST_SEND_TO_MODERATION: {
    url: (id: number) => `/api/v1/Onboarding/ModerationRequests/${id}/Send`,
    type: 'POST',
  },
  MODERATION_REQUEST_SEND_ELABORATION: {
    url: (id: number) => `/api/v1/Onboarding/ModerationRequests/${id}/Elaboration`,
    type: 'POST',
  },
  MODERATION_REQUEST_CREATE_SECONDARY: {
    url: () => '/api/v1/Onboarding/ModerationRequests/CreateSecondary',
    type: 'POST',
  },
  CHAT_ORDER_GET: {
    url: (orderId: number) => `/api/v2/${api_prefixes.mainRequests}/${orderId}/Chat`,
    type: 'GET',
  },
  CHAT_ORDER_SEND_MESSAGE: {
    url: (orderId: number) => `/api/v2/${api_prefixes.mainRequests}/${orderId}/Chat/SendMessage`,
    type: 'POST',
  },
  CHAT_ORDER_VIEW_MESSAGE: {
    url: (messageId: number) =>
      `/api/v2/${api_prefixes.mainRequests}/${messageId}/Chat/ViewedMessage`,
    type: 'POST',
  },
  CHAT_MODERATION_GET: {
    url: (applicationId, entityId, entityType) =>
      `/api/v1/Chats?Application=${applicationId}&EntityId=${entityId}&EntityType=${entityType}`,
    type: 'GET',
  },
  CHAT_MODERATION_SEND_MESSAGE: {
    url: () => '/api/v1/Chats/Messages',
    type: 'POST',
  },
  CHAT_MODERATION_VIEW_MESSAGE: {
    url: (chatId: number, messageId) => `/api/v1/Chats/${chatId}/Messages/${messageId}/View`,
    type: 'POST',
  },
  CHAT_CAN_SEND_MESSAGE: {
    url: (applicationId, entityId, entityType) =>
      `/api/v1/Chats/CanSendMessage?Application=${applicationId}&EntityId=${entityId}&EntityType=${entityType}`,
    type: 'GET',
  },
  CHAT_JOIN: {
    url: (chatId: number) => `/api/v1/Chats/${chatId}/Join`,
    type: 'POST',
  },
  CHAT_JOIN_BY_ENTITY: {
    url: (applicationId, entityId, entityType) =>
      `/api/v1/Chats/JoinByEntity?Application=${applicationId}&EntityId=${entityId}&EntityType=${entityType}`,
    type: 'POST',
  },
  START_CHAT_IN_ORDER: {
    url: (id: number) => `/api/v1/Outsourcing/Orders/${id}/Chat`,
    type: 'POST',
  },
  GET_TAB_SETTINGS: {
    url: () => '/api/v2/Outsourcing/Orders/GetCardTabSettings',
    type: 'GET',
  },
  AGENT_GET_REQUESTS: {
    url: () => '/api/v1/Agent/Requests/GetByFilter',
    type: 'POST',
  },
  AGENT_GET_REQUEST_DETAILS: {
    url: (id) => `/api/v1/Agent/Requests/${id}`,
    type: 'GET',
  },
  AGENT_APPROVE_REQUEST: {
    url: (requestId: number) => `/api/v2/Agent/Requests/${requestId}/Approve`,
    type: 'POST',
  },
  AGENT_DECLINE_REQUEST: {
    url: (requestId: number) => `/api/v2/Agent/Requests/${requestId}/Decline`,
    type: 'POST',
  },
  PROFILE_SEND_PHONE_CODE: {
    url: () => '/api/v1/Users/SendPhoneCode',
    type: 'POST',
  },
  PROFILE_SEND_CODE_TO_NEW_PHONE: {
    url: () => '/api/v1/Users/SendCodeToNewPhone',
    type: 'POST',
  },
  PROFILE_CONFIRM_EMAIL: {
    url: () => '/api/v2/Users/ConfirmEmailCode',
    type: 'POST',
  },
  PROFILE_SEND_EMAIL_CONFIRM_CODE: {
    url: () => '/api/v2/Users/SendEmailCode',
    type: 'GET',
  },
  PROFILE_CHANGE_PHONE: {
    url: () => '/api/v1/Users/ChangePhoneNumber',
    type: 'POST',
  },
  PROFILE_CHANGE_TIMEZONE: {
    url: () => '/api/v1/Users/ChangeUserTimeZone',
    type: 'POST',
  },
  PROFILE_GET_USER_COMMON_SETTLEMENTS: {
    url: () => '/api/v1/Users/UserCommonSettlements',
    type: 'GET',
  },
  PROFILE_UPDATE_USER_COMMON_SETTLEMENTS: {
    url: () => '/api/v1/Users/UpdateUserCommonSettlements',
    type: 'POST',
  },
  GET_EXTENDED_SUGGESTIONS: {
    // conditionType: 1 - города, 2 - регионы
    url: (requestAddress: string, conditionType: number) =>
      `/api/v2/GeoData/ExtendedSuggestionsWithConditions?RequestAddress=${requestAddress}&ConditionType=${conditionType}`,
    type: 'GET',
  },
  GET_COMMON_SETTLEMENT_BY_SUGGESTION: {
    url: () => '/api/v1/GeoData/GetCommonSettlementBySuggestion',
    type: 'POST',
  },
  GET_LEGAL_ENTITIES_SHORT: {
    url: () => '/api/v2/Clients/LegalEntities/GetLegalEntitiesShort',
    type: 'GET',
  },
  SUBCONTRACTOR_MODERATION_CURRENT: {
    url: () => 'api/v1/Verification/Current',
    type: 'GET',
  },
  SUBCONTRACTOR_MODERATION_CREATE: {
    url: () => 'api/v1/Verification',
    type: 'POST',
  },
  SUBCONTRACTOR_MODERATION_UPDATE: {
    url: (id: number) => `/api/v1/Verification/${id}/Update`,
    type: 'POST',
  },
  SUBCONTRACTOR_MODERATION_SEND_TO_VERIFY: {
    url: (id: number) => `/api/v1/Verification/${id}/SendToVerification`,
    type: 'POST',
  },
  GET_ALL_FEDERAL_DISTRICTS: {
    url: () => '/api/v1/GeoData/GetAllFederalDisctricts',
    type: 'GET',
  },
  GET_REGION_BY_SUGGESTION: {
    url: () => '/api/v1/GeoData/GetRegionBySuggestion',
    type: 'POST',
  },
  SUBCONTRACTOR_MODERATION_CURRENT_WITH_CHECKLIST: {
    url: () => '/api/v1/Verification/CurrentWithCheckList',
    type: 'GET',
  },
  SUBCONTRACTOR_MODERATION_REQUEST_SEND_ELABORATION: {
    url: (id: number) => `/api/v1/Verification/${id}/SendToElaboration`,
    type: 'POST',
  },
  STATIC_FILE_GET: {
    url: (key: string) => `api/v1/StaticFile/${key}/Get`,
    type: 'GET',
  },
  STATIC_FILE_DOWNLOAD: {
    url: (key: string) => `api/v1/StaticFile/${key}/Download`,
    type: 'GET',
  },
  STATIC_FILE_COLLECTION: {
    url: () => 'api/v1/StaticFile/Collection',
    type: 'GET',
  },
  ADD_FILE_BY_PERIOD: {
    url: () => '/api/v1/Billing/ExecutorReceipts/AddFileByPeriod',
    type: 'POST',
  },
  GET_RECEIPTS_BY_PERIOD: {
    url: (year: number, month: number) =>
      `/api/v1/Billing/ExecutorReceipts/ByPeriod?year=${year}&month=${month}`,
    type: 'GET',
  },
  GET_SITE_CONTACTS: {
    url: () => '/api/v3/SystemSettings/SiteContacts',
    type: 'GET',
  },
};
