import { useAppDispatch } from 'hooks/useAppDispatch';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';

export const SelfEmployerHint = () => {
  const dispatch = useAppDispatch();
  return (
    <div>
      <div className="mt-6 flex flex-col gap-4 p-4 text-text-100 sm:p-0">
        <p>1. Оформите статус самозанятого</p>
        <p>2. Запустите приложение «Мой налог» и перейдите в раздел «Настройки»</p>
        <p>3. Проследуйте в подраздел «Справки» в разделе «Настройки»</p>
        <p>
          4. Найдите и скачайте справку «О постановке на учет» в списке справок (находится наверху
          списка)
        </p>
        <Button
          variant={ButtonVariant.OUTLINE}
          className="mx-auto"
          onClick={() => dispatch(modalActions.closeModal())}
        >
          Закрыть
        </Button>
      </div>
    </div>
  );
};
