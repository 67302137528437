import { request } from 'api';
import { ExtendedSuggestion, ProfileChangeResponseWithCommonSettlement } from 'types';
import {
  ChangePhoneNumberBody,
  CommonSettlement,
  ConfirmEmailBody,
  ExtendedSettlementSuggestion,
  Profile,
} from './types';

export const ProfileService = {
  getProfile() {
    return request<Profile>('get', '/api/v1/Users/Current');
  },
  getPermissions() {
    return request<number[]>('get', '/api/v1/Users/CurrentPermissions');
  },

  sendPhoneConfirmCode(body: { newPhoneNumber: string }) {
    return request<ConfirmResponse>('post', '/api/v1/Users/SendCodeToNewPhone', {
      data: body,
    });
  },
  resendPhoneConfirmCode(body: { phoneNumber: string }) {
    return request<ConfirmResponse>('post', '/api/v1/Users/SendPhoneCode', {
      data: body,
    });
  },
  changePhoneNumber(body: ChangePhoneNumberBody) {
    return request<ConfirmResponse>('post', '/api/v1/Users/ChangePhoneNumber', {
      data: body,
    });
  },
  changeTimezone(body: { timeZone: number }) {
    return request<ConfirmResponse>('post', '/api/v1/Users/ChangeUserTimeZone', {
      data: body,
    });
  },

  sendEmailConfirmCode() {
    return request<ConfirmResponse>('get', '/api/v2/Users/SendEmailCode');
  },
  confirmEmail(body: ConfirmEmailBody) {
    return request<ConfirmResponse>('post', '/api/v2/Users/ConfirmEmailCode', {
      data: body,
    });
  },

  getUserCommonSettlements() {
    return request<CommonSettlement[]>('get', '/api/v1/Users/UserCommonSettlements');
  },
  getUserSettlementBySuggestion(extendedSuggestion: ExtendedSuggestion) {
    return request<ProfileChangeResponseWithCommonSettlement>(
      'post',
      '/api/v1/GeoData/GetCommonSettlementBySuggestion',
      {
        data: extendedSuggestion,
      }
    );
  },
  getExtendedSettlements(requestAddress: string, conditionType: number) {
    return request<ExtendedSuggestion[]>(
      'get',
      `/api/v2/GeoData/ExtendedSuggestionsWithConditions`,
      { params: { RequestAddress: requestAddress, ConditionType: conditionType } }
    );
  },
  updateUserCommonSettlements(body: { commonSettlements: CommonSettlement[] }) {
    return request<ExtendedSettlementSuggestion[]>(
      'post',
      '/api/v1/Users/UpdateUserCommonSettlements',
      {
        data: body,
      }
    );
  },
};
