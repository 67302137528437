import { ArchiveIcon } from 'assets/icons';
import { ModerationRequestStatusId } from 'enums/moderationRequestStatus';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FormControl } from 'pages/ModerationRequest/redesign/FormControl';
import { FormWrapper } from 'pages/ModerationRequest/redesign/FormWrapper';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { subcontractorModerationRequestActions } from 'reduxStore/reducers/SubcontractorModerationSlice';
import { getSubcontractorModerationRequestData } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorModerationRequestData';
import { Step4Fields } from './Step4Fields';
import { Step4Payload } from './types';
import { checkСertificateElectricalSafetyScan, checkСertificateWorkHeightScan } from './consts';
import { getSubcontractorNavigation } from 'reduxStore/reducers/SubcontractorModerationSlice/selectors/getSubcontractorNavigation';
import { toast } from 'react-toastify';

export const Step4Subcontractor = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const step = pathname.split('step')[1];

  const {
    certificateElectricalSafetyQuantity,
    certificateElectricalSafetyScan,
    certificateWorkHeightQuantity,
    certificateWorkHeightScan,
  } = useAppSelector(getSubcontractorModerationRequestData);
  const isNavigateDisabled = useAppSelector(getSubcontractorNavigation);
  const disableNavigate = (value: boolean) => {
    dispatch(subcontractorModerationRequestActions.setIsNavigateDisabled(value));
  };

  const electricalSafetyQuantity =
    certificateElectricalSafetyQuantity !== null && certificateElectricalSafetyQuantity === 0
      ? undefined
      : certificateElectricalSafetyQuantity;
  const workHeightQuantity =
    certificateWorkHeightQuantity !== null && certificateWorkHeightQuantity === 0
      ? undefined
      : certificateWorkHeightQuantity;

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    watch,
  } = useForm<Step4Payload>({
    defaultValues: {
      certificateElectricalSafetyQuantity: electricalSafetyQuantity,
      certificateElectricalSafetyScan,
      certificateWorkHeightQuantity: workHeightQuantity,
      certificateWorkHeightScan,
    },
    mode: 'onBlur',
  });

  const onSubmit: SubmitHandler<Step4Payload> = (data, event) => {
    const nativeEvent = event?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter as HTMLButtonElement;
    let toastErrors: string[] = [];
    if (data.certificateElectricalSafetyQuantity || data.certificateElectricalSafetyScan?.length) {
      toastErrors = checkСertificateElectricalSafetyScan(
        data.certificateElectricalSafetyQuantity,
        data.certificateElectricalSafetyScan,
        toastErrors,
        setError
      );
    }
    if (data.certificateWorkHeightQuantity || data.certificateWorkHeightScan?.length) {
      toastErrors = checkСertificateWorkHeightScan(
        data.certificateWorkHeightQuantity,
        data.certificateWorkHeightScan,
        toastErrors,
        setError
      );
    }
    if (toastErrors.length) {
      const uniqueMessages = new Set(toastErrors);
      Array.from(uniqueMessages).forEach((message: string) => {
        toast.error(message);
      });
      return;
    }
    dispatch(subcontractorModerationRequestActions.setDocuments(data));
    if (isNavigateDisabled) {
      toast.error('Дождитесь удаления файла');
    } else if (submitter.name === 'forward') {
      navigate(`/moderation-request/final-step`);
    } else if (submitter.name === 'back') {
      const prevStep = +step - 1;
      prevStep
        ? prevStep && navigate(`/moderation-request/step${prevStep}`)
        : navigate(`/moderation-request`);
    }
  };

  return (
    <FormWrapper title="Документы" status={ModerationRequestStatusId.Draft} Icon={ArchiveIcon}>
      <form onSubmit={handleSubmit(onSubmit)} className="flex min-h-form flex-col justify-between">
        <Step4Fields
          errors={errors}
          control={control}
          isFormDisabled={false}
          clearErrors={clearErrors}
          watch={watch}
          disableNavigate={disableNavigate}
        />
        <FormControl />
      </form>
    </FormWrapper>
  );
};
