import React from 'react';

import { Customers } from 'types/customers';

interface CustomerProps {
  clientId?: number;
  name?: string;
}

export default function Customer({ clientId, name }: CustomerProps) {
  const Logo = () => {
    if (clientId) {
      const index = Customers.findIndex((customer) => customer.id === clientId);
      if (index > -1) {
        const A = Customers[index].logo;
        return <A className="h-auto" />;
      }
    }
    if (name) {
      const index = Customers.findIndex((customer) => customer.name === name);
      if (index > -1) {
        const A = Customers[index].logo;
        return <A className="h-auto" />;
      }
    }
    return null;
  };

  return (
    <div className="flex gap-2">
      <div className="flex h-10 w-10 items-center justify-center rounded-full border border-zinc-300 p-1">
        <Logo />
      </div>
      {name && (
        <div className="flex items-center gap-1 font-montserrat text-xs font-semibold">{name}</div>
      )}
    </div>
  );
}
