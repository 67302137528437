import { request } from 'api';
import {
  ExtendedSuggestion,
  GetRegionBySuggestion,
  ProfileChangeResponse,
  ProfileChangeResponseWithCommonSettlement,
  ProfileChangeResponseWithUpdateSettlements,
  SettlementShort,
} from 'types';
import { endpoints } from 'api/endpoints';
import { AxiosResponse } from 'axios';
import { Profile } from './ProfileService/types';

export const getProfile = async (): Promise<AxiosResponse<Profile>> => {
  return await request<Profile>(endpoints.GET_PROFILE.type, endpoints.GET_PROFILE.url());
};

// Получение городов пользователя
export const getUserCommonSettlements = async (): Promise<AxiosResponse<SettlementShort[]>> => {
  return await request(
    endpoints.PROFILE_GET_USER_COMMON_SETTLEMENTS.type,
    endpoints.PROFILE_GET_USER_COMMON_SETTLEMENTS.url()
  );
};

// Отправка код подтверждения
export const sendCodeToNewPhone = async (data: {
  newPhoneNumber: string;
}): Promise<AxiosResponse<ProfileChangeResponse>> => {
  return await request(
    endpoints.PROFILE_SEND_CODE_TO_NEW_PHONE.type,
    endpoints.PROFILE_SEND_CODE_TO_NEW_PHONE.url(),
    {
      data,
    }
  );
};

// Повторная отправка кода подтверждения
export const sendPhoneCode = async (data: {
  phoneNumber: string;
}): Promise<AxiosResponse<ProfileChangeResponse>> => {
  return await request(
    endpoints.PROFILE_SEND_PHONE_CODE.type,
    endpoints.PROFILE_SEND_PHONE_CODE.url(),
    {
      data,
    }
  );
};

export const sendEmailConfirmCode = async (): Promise<AxiosResponse<ProfileChangeResponse>> => {
  return await request(
    endpoints.PROFILE_SEND_EMAIL_CONFIRM_CODE.type,
    endpoints.PROFILE_SEND_EMAIL_CONFIRM_CODE.url()
  );
};

export const confirmEmail = async (data: {
  email: string;
  code: string;
  userId: string;
}): Promise<AxiosResponse<ProfileChangeResponse>> => {
  return await request(
    endpoints.PROFILE_CONFIRM_EMAIL.type,
    endpoints.PROFILE_CONFIRM_EMAIL.url(),
    {
      data,
    }
  );
};

export const changePhoneNumber = async (data: {
  newPhoneNumber: string;
  code: string;
}): Promise<AxiosResponse<ProfileChangeResponse>> => {
  return await request(endpoints.PROFILE_CHANGE_PHONE.type, endpoints.PROFILE_CHANGE_PHONE.url(), {
    data,
  });
};

export const changeUserTimezone = async (data: {
  timeZone: number;
}): Promise<AxiosResponse<ProfileChangeResponse>> => {
  return await request(
    endpoints.PROFILE_CHANGE_TIMEZONE.type,
    endpoints.PROFILE_CHANGE_TIMEZONE.url(),
    {
      data,
    }
  );
};

// Получение списка городов
export const getExtendedSuggestions = async (
  requestAddress: string,
  type: number // 1 - города, 2 - субъекты
): Promise<AxiosResponse<ExtendedSuggestion[]>> => {
  return await request(
    endpoints.GET_EXTENDED_SUGGESTIONS.type,
    endpoints.GET_EXTENDED_SUGGESTIONS.url(requestAddress, type)
  );
};

// Получение данных по городу из подсказки
export const getCommonSettlementBySuggestions = async (
  data: ExtendedSuggestion
): Promise<AxiosResponse<ProfileChangeResponseWithCommonSettlement>> => {
  return await request(
    endpoints.GET_COMMON_SETTLEMENT_BY_SUGGESTION.type,
    endpoints.GET_COMMON_SETTLEMENT_BY_SUGGESTION.url(),
    {
      data,
    }
  );
};
// Получение данных по городу из подсказки
export const getSubjectBySuggestions = async (
  data: ExtendedSuggestion
): Promise<AxiosResponse<GetRegionBySuggestion>> => {
  return await request(
    endpoints.GET_REGION_BY_SUGGESTION.type,
    endpoints.GET_REGION_BY_SUGGESTION.url(),
    {
      data,
    }
  );
};

// Обновление списка городов пользователя
export const updateUserCommonSettlements = async (data: {
  commonSettlements: SettlementShort[];
}): Promise<AxiosResponse<ProfileChangeResponseWithUpdateSettlements>> => {
  return await request(
    endpoints.PROFILE_UPDATE_USER_COMMON_SETTLEMENTS.type,
    endpoints.PROFILE_UPDATE_USER_COMMON_SETTLEMENTS.url(),
    {
      data,
    }
  );
};
