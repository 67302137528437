/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th></thead><tbody><tr><td>1</td><td>Some</td></tr><tr><td>2</td><td>All</td></tr></tbody></table></summary>
 */
export enum Core_Utils_Selection_SelectionTypeEnum {
    '_1' = 1,
    '_2' = 2,
}
