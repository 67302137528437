import { RegisterOptions } from 'react-hook-form/dist/types/validator';

export const smsTimeout = 60 * 4 + 59;

export const newPhoneRules: RegisterOptions = {
  required: {
    value: true,
    message: 'Необходимо указать код подтверждения',
  },
  pattern: {
    value: /^\d+$/,
    message: 'Только цифры',
  },
};

export const confirmCodeRules: RegisterOptions = {
  required: {
    value: true,
    message: 'Необходимо указать код подтверждения',
  },
  pattern: {
    value: /^\d+$/,
    message: 'Только цифры',
  },
};
