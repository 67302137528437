import classNames from 'classnames';
import { ButtonProps } from './types';
import { FC } from 'react';
import { ButtonVariant, buttonVariants } from './constants';

export const Button: FC<ButtonProps> = ({
  className,
  children,
  variant = ButtonVariant.PRIMARY,
  name,
  ...rest
}) => {
  return (
    <button
      name={name}
      className={classNames('btn-base', buttonVariants[variant], className)}
      {...rest}
    >
      {children}
    </button>
  );
};
