import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ReactComponent as LeftArrowIcon } from 'assets/left-arrow.svg';
import useMediaQuery from 'hooks/useMediaQuery';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { IS_MEDIUM_DEVICE } from 'utils/settings';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  modalClasses?: string;
  label?: string;
}

export const Modal = ({ open, onClose, children, modalClasses = '', label }: ModalProps) => {
  const isMedium = useMediaQuery(IS_MEDIUM_DEVICE);
  useEffect(() => {
    const { classList } = document.body;
    if (open) {
      classList.add('without-scroll');
    } else {
      classList.remove('without-scroll');
    }

    return () => {
      classList.remove('without-scroll');
    };
  }, [open]);

  const root = document.getElementById('root');

  const modal = (
    <div className="fixed left-0 right-0 bottom-0 top-0 z-50 h-full w-full overflow-y-auto overflow-x-hidden bg-white sm:bg-[#04040F] sm:bg-opacity-40">
      <div
        className={`relative mx-auto my-6 max-h-[90vh] min-h-modal w-auto max-w-[1000px] items-center justify-center px-4 md:px-0 ${modalClasses}`}
      >
        <div className="relative h-full w-full" onClick={(e) => e.stopPropagation()} aria-hidden>
          <dialog
            open={open}
            onClick={(e) => e.stopPropagation()}
            className={`relative z-50 mx-auto flex w-full max-w-[700px] grow flex-col p-8 lg:grow-0 lg:rounded-xl lg:shadow-sm ${modalClasses}`}
          >
            <div className="flex h-10 w-full items-center justify-start lg:hidden">
              <LeftArrowIcon className="mr-4 cursor-pointer text-text-100" onClick={onClose} />
              <span className="headline-bold text-text-100">{label}</span>
            </div>
            {!isMedium && (
              <div className="flex justify-between gap-3">
                <h3 className="headline-bold text-text-100">{label}</h3>
                <CloseIcon
                  className="flex h-[20px] w-[20px] cursor-pointer items-center justify-center rounded-[50%] text-text-50 transition-colors duration-300 hover:fill-text-100 hover:text-text-100"
                  onClick={onClose}
                />
              </div>
            )}
            <div>{children}</div>
          </dialog>
          <div
            className="fixed top-0 left-0 hidden h-full w-full bg-gray-dark/[0.5] lg:block"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
  if (open) {
    return root && createPortal(modal, root);
  }
  return null;
};
