import { StyledRecieptsByPeriod } from 'components/RecieptsByPeriod/styled';
import { useAppSelector } from 'hooks/useAppSelector';
import { useState } from 'react';
import { getExecutorType } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorType';
import TabGroup from 'ui/TabGroup';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { ExecutorReceipts } from './ExecutorReceipts';

export const Receipts = () => {
  const executorType = useAppSelector(getExecutorType);
  //const tabItems = [{ title: 'Чеки по периодам' }, { title: 'Чеки по сумме' }];
  const tabItems = [{ title: 'Чеки по сумме' }];
  // const moderationTabContent = [
  //   <StyledRecieptsByPeriod key="tab1" />,
  //   <ExecutorReceipts key="tab2" />,
  // ];
  const moderationTabContent = [<ExecutorReceipts key="tab1" />];
  const [selectedIndex, setSelectedIndex] = useState(0);

  return !executorType || executorType === 1 ? (
    <PageWrapper title="Чеки" isGoBack={false}>
      <TabGroup
        tabItems={tabItems}
        tabPanelsContent={moderationTabContent}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
    </PageWrapper>
  ) : (
    <PageWrapper title="Чеки">
      <ExecutorReceipts />
    </PageWrapper>
  );
};
