import { EServiceAreaType } from 'enums/serviceAreaType';
import { ESubcontractorServiceAreaType } from 'enums/subcontractorServiceAreaType';
import {
  ServiceAreaIsCity,
  ServiceAreaIsFederal,
  ServiceAreaIsRegion,
} from 'pages/ModerationRequest/SubcontractorModeration';
import { RegionAndCity, ServiceArea } from '../finalStep/types';
import * as yup from 'yup';
import { OrganizationType } from 'enums/organizationType';
import { UseFormSetError } from 'react-hook-form';

export const serviceAreasRadio = [
  {
    label: 'Федеральный округ',
    value: ESubcontractorServiceAreaType.FederalDistrict,
  },
  {
    label: 'Субъект Федерации',
    value: ESubcontractorServiceAreaType.Region,
  },
  {
    label: 'Населенный пункт',
    value: ESubcontractorServiceAreaType.City,
  },
];

export const getCityAreas = (cities: RegionAndCity[], id: number): ServiceArea[] => {
  if (!cities?.length) {
    return [];
  }
  return cities?.map((city) => ({
    id: 0,
    subcontractorId: id || 0,
    serviceAreaTypeId: EServiceAreaType.City,
    cityFiasId: city.value.fiasId,
    federalDistrictId: null,
    regionFiasId: null,
    objectFullName: city.label,
  }));
};

export const getRegionAreas = (regions: RegionAndCity[], id: number): ServiceArea[] => {
  return regions?.map((region) => ({
    id: 0,
    subcontractorId: id || 0,
    serviceAreaTypeId: EServiceAreaType.Region,
    regionFiasId: region.value.fiasId,
    cityFiasId: null,
    federalDistrictId: null,
    objectFullName: region.label,
  }));
};

export const getFederalAreas = (
  federals: (number | null | undefined)[],
  id: number
): ServiceArea[] => {
  return federals?.map((federal) => ({
    id: 0,
    subcontractorId: id || 0,
    serviceAreaTypeId: EServiceAreaType.FederalDistrict,
    federalDistrictId: federal,
    cityFiasId: null,
    regionFiasId: null,
    objectFullName: null,
  }));
};

export const getServiceAreas = (data: any): ServiceArea[] => {
  let areas: ServiceArea[] = [];
  switch (data.serviceAreaTypeId) {
    case EServiceAreaType.FederalDistrict:
      areas = getFederalAreas(data.serviceAreasF, data.serviceAreaTypeId);
      break;
    case EServiceAreaType.City:
      areas = getCityAreas(data.serviceAreasC, data.serviceAreaTypeId);
      break;
    case EServiceAreaType.Region:
      areas = getRegionAreas(data.serviceAreasR, data.serviceAreaTypeId);
      break;
    default:
      areas = [];
      break;
  }
  return areas;
};

export function isCity(area: ServiceArea): area is ServiceAreaIsCity {
  return 'cityFiasId' in area && !!area.cityFiasId;
}

export const isRegion = (area: ServiceArea): area is ServiceAreaIsRegion => {
  return 'regionFiasId' in area && !!area.regionFiasId;
};

export const isFederal = (area: ServiceArea): area is ServiceAreaIsFederal => {
  return 'federalDistrictId' in area && !!area.federalDistrictId;
};

// export const step1LLCSchema = yup.object().shape({
//   organizationName: yup
//     .string()
//     .typeError('Наименование Организации/ИП должно быть заполнено')
//     .required('Наименование Организации/ИП должно быть заполнено'),
//   egrulScan: yup.array().typeError('Необходимо приложить файл').min(1, 'Необходимо приложить файл'),
// });

// export const step1IndividualSchema = yup.object().shape({
//   organizationName: yup
//     .string()
//     .typeError('Наименование Организации/ИП должно быть заполнено')
//     .required('Наименование Организации/ИП должно быть заполнено'),
//   egripScan: yup.array().typeError('Необходимо приложить файл').min(1, 'Необходимо приложить файл'),
// });

// export const getFinalSchema = (actualSubcontractorType: OrganizationType) => {
//   const schema =
//     actualSubcontractorType === OrganizationType.Individual
//       ? step1IndividualSchema
//       : step1LLCSchema;
//   return schema;
// };

export const checkOrganizationName = (
  organizationName: string | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const organizationNameErrorMessage = 'Наименование Организации/ИП должно быть заполнено';
  if (!organizationName) {
    setError('organizationName', {
      type: 'manual',
      message: organizationNameErrorMessage,
    });
    toastErrors.push(organizationNameErrorMessage);
  }
  return toastErrors;
};

export const checkScan = (
  scan: OrderFile | null,
  fieldName: string,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const scanErrorMessage = 'Необходимо приложить файл';
  if (!scan) {
    setError(fieldName, {
      type: 'manual',
      message: scanErrorMessage,
    });
    toastErrors.push(scanErrorMessage);
  }
  return toastErrors;
};

export const checkAreas = (
  areas: ServiceArea[] | null,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  const areasErrorMessage = 'Территория обслуживания должна быть заполнена';
  if (!areas || !areas.length) {
    setError('serviceAreas', {
      type: 'manual',
      message: areasErrorMessage,
    });
    toastErrors.push(areasErrorMessage);
  }
  return toastErrors;
};
