export const timezoneMap: { [key: number]: string } = {
  0: '(UTC+00:00) Лондон',
  1: '(UTC+01:00) Париж',
  2: '(UTC+02:00) Калининград',
  3: '(UTC+03:00) Москва',
  4: '(UTC+04:00) Саратов',
  5: '(UTC+05:00) Екатеринбург',
  6: '(UTC+06:00) Омск',
  7: '(UTC+07:00) Новосибирск ',
  8: '(UTC+08:00) Иркутск',
  9: '(UTC+09:00) Якутск',
  10: '(UTC+10:00) Владивосток ',
  11: '(UTC+11:00) Магадан',
  12: '(UTC+12:00) Петропавловск-Камчатский',
};
