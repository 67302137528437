import { ActionMeta, MultiValue, SingleValue } from 'react-select';
import { Controller, Control, FieldErrors, RegisterOptions } from 'react-hook-form';

import { OptionType, Select, SelectProps } from 'ui/Select';
import useFieldArrayControl from 'hooks/useFieldErrorMessage';

export interface FieldSelectProps extends Omit<SelectProps, 'onChangeValue'> {
  name: string;
  control: Control<any>;
  rules?: RegisterOptions;
  errors?: {
    [name: string]: FieldErrors;
  };
  onChangeValue?: (
    value: MultiValue<OptionType> | SingleValue<OptionType> | null,
    meta?: ActionMeta<OptionType>
  ) => void;
  noError?: boolean;
}

export const FieldSelect = ({
  name,
  label,
  onChangeValue,
  control,
  rules,
  errors,
  options,
  noError,
  ...rest
}: FieldSelectProps) => {
  const errorMessage = useFieldArrayControl(name, errors);

  return (
    <div className="field-select">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <Select
              name={name}
              value={field.value}
              label={label}
              onChangeValue={(value, meta) => {
                field.onChange(value);
                onChangeValue && onChangeValue(value, meta);
              }}
              options={options}
              errorMessage={!noError ? errorMessage : ''}
              {...rest}
            />
          );
        }}
      />
    </div>
  );
};
