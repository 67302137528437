import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { RequestTab } from './components/RequestTab';

import { useFetchData } from 'hooks/useFetchData';
import { endpoints } from 'api/endpoints';
import { AgentRequest } from './types';
import useMediaQuery from 'hooks/useMediaQuery';
import { SMALL_DEVICE_MEDIA_QUERY } from 'utils/settings';
import TabGroup from 'ui/TabGroup';
import { ReactComponent as LeftArrowIcon } from 'assets/left-arrow.svg';

const AgentRequestDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isSmallDevice = useMediaQuery(SMALL_DEVICE_MEDIA_QUERY);

  const {
    data: request,
    isLoading,
    refetch,
  } = useFetchData<AgentRequest>(
    endpoints.AGENT_GET_REQUEST_DETAILS.url(id),
    endpoints.AGENT_GET_REQUEST_DETAILS.type
  );

  const tabItems = [
    { title: `${isSmallDevice ? `Заявка № ${id}` : 'Данные по заявке'}`, permissions: true },
  ];

  const tabContent = [
    <RequestTab agentRequest={request} refetch={refetch} isLoading={isLoading} key={1} />,
  ];

  return (
    <>
      <div>
        {!isSmallDevice && (
          <div className="mb-4 hidden justify-between gap-4 md:flex">
            <p className="text-gray-medium">Заявка №{request?.id}</p>
            <button
              type="button"
              className="flex items-center justify-center gap-3 self-start rounded-md border border-gray-light px-3 py-1 text-center text-xs text-black transition-[background] delay-100 hover:bg-gray-light focus-visible:border-transparent focus-visible:outline-gray-light"
              onClick={() => navigate(-1)}
            >
              <LeftArrowIcon className="text-black" />
              Назад
            </button>
          </div>
        )}

        <div className="flex flex-col">
          <TabGroup tabItems={tabItems} tabPanelsContent={tabContent} />
        </div>
      </div>
    </>
  );
};
export default AgentRequestDetails;
