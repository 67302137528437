import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

export default function Button({
  className = '',
  children,
  active,
  disabled,
  onClick,
  ...otherProps
}: ButtonProps) {
  return (
    <button
      className={`${className} flex items-center justify-center text-xs leading-5 rounded-full ${
        disabled ? 'cursor-not-allowed' : active ? 'w-8 h-8 bg-blue text-white' : ''
      }`}
      type="button"
      disabled={disabled}
      onClick={onClick}
      {...otherProps}
    >
      <span>{children}</span>
    </button>
  );
}
