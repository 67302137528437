export enum SubcontractorType {
  // ИП
  Individual = 1,
  // ООО
  LLC = 2,
}

export interface SubcontractorTypeIdPayload {
  organizationTypeId: SubcontractorType;
}
