import { ExecutorTypeEnum } from 'reduxStore/reducers/ProfileSlice/types';

export const stepCards = [
  { title: 'Шаг 1', description: 'Укажите номер телефона' },
  {
    title: 'Шаг 2',
    description: 'Укажите регион ведения деятельности',
  },
  {
    title: 'Шаг 3',
    description: 'Отсканируйте паспорт. Поднесите его к камере телефона в развернутом виде',
  },
  { title: 'Шаг 4', description: 'Проверьте данные' },
  { title: 'Шаг 5', description: 'Сфотографируйтесь или загрузите фото из галереи телефона' },
  {
    title: 'Шаг 6',
    description:
      'Подтвердите регистрацию. Ваше заявление для регистрации будет отправлено в налоговую инспекцию. Регистрация может произойти сразу или в течение шести дней',
  },
];

export const executorTypes = [
  {
    label: 'Самозанятый',
    value: ExecutorTypeEnum.Selfemployed,
  },
  {
    label: 'ИП',
    value: ExecutorTypeEnum.Individual,
  },
];

export const defaultFormValues = {
  id: 0,
  rowVersion: null,
  moderatedUserId: 0,
  recruiterId: 0,
  inn: null,
  bik: null,
  bankSettlement: null,
  mobilePhone: null,
  email: null,
  created: null,
  acceptsOffer: false,
  parentId: 0,
  birthDate: null,
  executorType: ExecutorTypeEnum.Selfemployed,
  status: 0,
  fullName: null,
  citizenship: 1,
  pyrusUrl: null,
  passport: {},
  executorPhotos: {
    files: [],
  },
  selfemployedCertificate: {
    files: [],
  },
  educations: null,
  workExperiences: [],
  serviceCities: [],
  executorSpecializations: [],
  individualCertificate: {
    ogrnip: null,
    individualName: null,
    files: [],
  },
  userRegistration: '',
  recruiterFullName: null,
  checkLists: {},
};
