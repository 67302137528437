/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>1</td><td>SpsRequests</td><td>Запросы ЗИП</td></tr><tr><td>2</td><td>AddingNomenclature</td><td>Добавление номенклатуры</td></tr><tr><td>3</td><td>WrittingOff</td><td>Списание</td></tr><tr><td>4</td><td>Closed</td><td>Закрытые</td></tr><tr><td>5</td><td>ConfirmingSpsRequests</td><td>Согласование ЗИП</td></tr></tbody></table></summary>
 */
export enum SpsManagement_SharedDtos_UiSettings_OrderSpsRequestTabEnum {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
}
