import { AxiosError } from 'axios';
import { CitiesSelect } from 'components/redesign/CititesSelect';
import { useAppSelector } from 'hooks/useAppSelector';
import { ValidationError } from 'pages/ModerationRequest/ExecutorModeration/steps/finalStep/types';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { MultiValue } from 'react-select';
import { toast } from 'react-toastify';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { ProfileService } from 'services/ProfileService';
import { CommonSettlement } from 'services/ProfileService/types';
import { ExtendedSuggestion, SettlementShort } from 'types';
import { Input } from 'ui/redesign/Input';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { OptionType } from 'ui/redesign/Select/types';
import { ProfileWithSettlements } from './types';

export const PrivateData: FC = () => {
  const [canUpdate, setCanUpdate] = useState<boolean>(false);
  const userProfile = useAppSelector(getProfile);
  const { control, setValue, reset, watch } = useForm<ProfileWithSettlements>({
    defaultValues: {
      ...userProfile,
      settlements: [],
    },
  });

  const { refetch } = useQuery(
    ['getUserCommonSettlements'],
    () => ProfileService.getUserCommonSettlements(),
    {
      onSuccess: (data) => {
        if (data.data.length > 0) {
          setValue(
            'settlements',
            data.data.map((item) => ({
              label: item.objectFullName,
              value: item,
            }))
          );
        }
      },
    }
  );
  const { mutateAsync: updateSettlements } = useMutation(
    ['updateUserCommonSettlements'],
    (commonSettlements: CommonSettlement[]) =>
      ProfileService.updateUserCommonSettlements({ commonSettlements }),
    {
      onSuccess: () => {
        setCanUpdate(false);
      },
      onError: (data: AxiosError<ValidationError>) => {
        for (const error in data?.response?.data?.errors) {
          data?.response?.data?.errors[error].map((text: string) => toast.error(text));
          toast.error(data?.response?.data?.errors);
        }
        setCanUpdate(false);
      },
    }
  );

  useEffect(() => {
    refetch();
    if (userProfile) {
      reset(userProfile);
    }
  }, [userProfile, setValue, reset]);

  const updateSettlementsHandler = async (settlements: OptionType<SettlementShort>[]) => {
    const settlementsForUpdate = settlements.map((item: any) => {
      if (item.value?.fiasId) {
        return item.value;
      } else {
        return {
          fiasId: item.value.suggestion.data.fias_id,
          objectFullName: item.value.name,
        };
      }
    });
    await updateSettlements(settlementsForUpdate);
  };

  const watchSettlements = watch('settlements');

  useEffect(() => {
    if (!canUpdate) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    updateSettlementsHandler(watchSettlements);
  }, [canUpdate, watchSettlements]);

  const onChangeHandler = async (
    e: MultiValue<OptionType<SettlementShort | ExtendedSuggestion>>
  ) => {
    if (e.length !== watchSettlements.length) {
      setCanUpdate(true);
    }
  };

  return (
    <PageWrapper title="Личные данные">
      <form className="flex flex-col gap-4 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)] sm:gap-8">
        <Input value={userProfile?.lastName ?? ''} disabled label="Фамилия" />
        <Input value={userProfile?.firstName ?? ''} disabled label="Имя" />
        <Input value={userProfile?.middleName ?? ''} disabled label="Отчество" />
        <Input value={userProfile.inn ?? ''} disabled onChange={() => null} label="ИНН" />
        <CitiesSelect
          isMulti
          control={control}
          label="Города обслуживания"
          name="settlements"
          onChange={onChangeHandler}
          isFormDisabled={false}
          isClearable
        />
      </form>
    </PageWrapper>
  );
};
