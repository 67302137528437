/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>1</td><td>Reference</td><td>Значение SLA по справочнику</td></tr><tr><td>2</td><td>Individual</td><td>Индивидуальный SLA</td></tr></tbody></table></summary>
 */
export enum Outsourcing_SharedEnums_SlaTypeEnum {
    '_1' = 1,
    '_2' = 2,
}
