import { FieldDatePicker } from 'components/redesign/FieldDatePicker';
import { FieldInput } from 'components/redesign/FieldInput';
import { FieldTextarea } from 'components/redesign/FieldTextArea';
import { FieldUploadFileInput } from 'components/redesign/FieldUploadFileInput';
import { FileBusinessType } from 'enums/fileBusinessType';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useMediaQuery from 'hooks/useMediaQuery';
import { FC } from 'react';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { IS_SMALL_DEVICE } from 'utils/settings';
import { UploadHint } from './PassportHint';
import { Step2Props } from './types';

export const Step2Fields: FC<Step2Props> = ({
  disableNavigate,
  clearErrors,
  control,
  errors,
  isFormDisabled,
}) => {
  const dispatch = useAppDispatch();
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);
  const showHint = () => {
    dispatch(
      modalActions.handleOpenModal({
        content: <UploadHint />,
        options: {
          title: 'Соблюдайте требования к снимку',
        },
      })
    );
  };

  return (
    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
      <FieldInput
        name="series"
        control={control}
        label="Серия паспорта"
        errorMessage={errors?.series?.message}
        mask="99 99"
        isClearable
        disabled={isFormDisabled}
        clearErrors={clearErrors}
      />
      <FieldInput
        name="number"
        control={control}
        label="Номер паспорта"
        errorMessage={errors?.number?.message}
        mask="999999"
        isClearable
        disabled={isFormDisabled}
        clearErrors={clearErrors}
      />
      <FieldDatePicker
        name="dateOfIssue"
        control={control}
        label="Дата выдачи"
        disabled={isFormDisabled}
        errorMessage={errors?.dateOfIssue?.message}
        maxDate={new Date()}
        clearErrors={clearErrors}
      />
      <FieldInput
        name="unitCode"
        control={control}
        label="Код подразделения"
        errorMessage={errors?.unitCode?.message}
        mask="999-999"
        isClearable
        disabled={isFormDisabled}
        clearErrors={clearErrors}
      />
      <FieldTextarea
        name="issuedBy"
        control={control}
        maxLength={250}
        label="Кем выдан паспорт"
        errorMessage={errors?.issuedBy?.message}
        isClearable
        disabled={isFormDisabled}
        clearErrors={clearErrors}
      />
      <FieldTextarea
        name="birthPlace"
        control={control}
        label="Место рождения"
        maxLength={255}
        errorMessage={errors?.birthPlace?.message}
        isClearable
        disabled={isFormDisabled}
        clearErrors={clearErrors}
      />
      <FieldTextarea
        name="registrationAddress"
        control={control}
        label="Адрес регистрации"
        errorMessage={errors?.registrationAddress?.message}
        isClearable
        disabled={isFormDisabled}
        clearErrors={clearErrors}
        maxLength={255}
      />
      <div></div>
      {isSmall ? (
        <>
          <p className="headline-bold -mb-4 text-text-100">Сканы паспорта</p>
          <FieldUploadFileInput
            name={'files'}
            control={control}
            errorMessage={(errors as any)?.files?.message}
            disabled={isFormDisabled}
            fileBusinessTypeId={FileBusinessType.Passport}
            clearErrors={clearErrors}
          />
          {(errors as any)?.files?.message && (
            <p className="callout-normal -mt-6 !text-text-50">
              Приложите разворот с фотографией, страницы с пропиской
            </p>
          )}

          <p className="headline-bold -mb-4 text-text-100">Фото с паспортом</p>
          <FieldUploadFileInput
            name={'executorPhotos'}
            control={control}
            errorMessage={(errors as any)?.executorPhotos?.message}
            disabled={isFormDisabled}
            fileBusinessTypeId={FileBusinessType.ExecutorPhotos}
            clearErrors={clearErrors}
          />
          <p className="-mt-6">
            <span className="callout-bold cursor-pointer !text-primary-60" onClick={showHint}>
              Смотреть пример фото
            </span>
          </p>
        </>
      ) : (
        <>
          <p className="headline-bold -mb-4 text-text-100">Сканы паспорта</p>
          <p className="headline-bold -mb-4 text-text-100">Фото с паспортом</p>
          <FieldUploadFileInput
            clearErrors={clearErrors}
            name={'files'}
            control={control}
            errorMessage={(errors as any)?.files?.message}
            disabled={isFormDisabled}
            fileBusinessTypeId={FileBusinessType.Passport}
            disableNavigate={disableNavigate}
          />
          <FieldUploadFileInput
            clearErrors={clearErrors}
            name={'executorPhotos'}
            control={control}
            errorMessage={(errors as any)?.executorPhotos?.message}
            disabled={isFormDisabled}
            fileBusinessTypeId={FileBusinessType.ExecutorPhotos}
            disableNavigate={disableNavigate}
          />
          {!(errors as any)?.files?.message && (
            <p className="callout-normal -mt-6 !text-text-50">
              Приложите разворот с фотографией, страницы с пропиской
            </p>
          )}
          <p className="-mt-6">
            <span className="callout-bold cursor-pointer !text-primary-60" onClick={showHint}>
              Смотреть пример фото
            </span>
          </p>
        </>
      )}
    </div>
  );
};
