import { ArchiveIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FormControl } from 'pages/ModerationRequest/redesign/FormControl';
import { FormWrapper } from 'pages/ModerationRequest/redesign/FormWrapper';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getModerationRequestData } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestData';
import { getModerationRequestStatus } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestStatus';
import { Step7Fields } from './Step7Fields';
import { moderationRequestActions } from 'reduxStore/reducers/ModerationRequestSlice';
import { Step7Payload } from './types';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { getExecutorNavigation } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getExecutorNavigation';
import { toast } from 'react-toastify';

export const Step7 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { created } = useAppSelector(getProfile);
  const { status, certificateElectricalSafety, certificateWorkHeight } =
    useAppSelector(getModerationRequestData);
  const isFormDisabled = useAppSelector(getModerationRequestStatus);
  const isNavigateDisabled = useAppSelector(getExecutorNavigation);
  const disableNavigate = (value: boolean) => {
    dispatch(moderationRequestActions.setIsNavigateDisabled(value));
  };
  const { pathname } = useLocation();
  const step = pathname.split('step')[1];
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      certificateElectricalSafety: certificateElectricalSafety.files,
      certificateWorkHeight: certificateWorkHeight.files,
    },
  });

  const onSubmit: SubmitHandler<any> = (data, event) => {
    dispatch(moderationRequestActions.setCertificates(data as Step7Payload));
    const nativeEvent = event?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter as HTMLButtonElement;
    if (isNavigateDisabled) {
      toast.error('Дождитесь удаления файла');
    } else if (submitter.name === 'forward') {
      navigate(`/moderation-request/final-step`);
    } else if (submitter.name === 'back') {
      const prevStep = +step - 1;
      prevStep
        ? prevStep && navigate(`/moderation-request/step${prevStep}`)
        : navigate(`/moderation-request`);
    }
  };

  return (
    <FormWrapper title="Документы" status={status} created={created} Icon={ArchiveIcon}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex min-h-[65vh] flex-col justify-between"
      >
        <Step7Fields
          isFormDisabled={isFormDisabled}
          control={control}
          disableNavigate={disableNavigate}
          errors={errors}
        />
        <FormControl errors={errors} />
      </form>
    </FormWrapper>
  );
};
