import { FC } from 'react';
import { LoaderProps } from './types';

export const Loader: FC<LoaderProps> = ({ show }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="mt-2 flex items-center justify-center gap-2">
      <p className="text-xs">Загрузка...</p>
      <div className="relative h-1 w-full overflow-hidden">
        <div className="absolute z-10 h-1 w-16 animate-load rounded bg-primary-60" />
        <div className="absolute top-0 left-0 h-1 w-full rounded bg-light-50" />
      </div>
    </div>
  );
};
