/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>1</td><td>SetExecutor</td><td>Назначить исполнителя</td></tr><tr><td>2</td><td>Change</td><td>Редактировать</td></tr><tr><td>3</td><td>TakeForFixingIntegrationErrors</td><td>Взять на себя</td></tr><tr><td>4</td><td>BecomeDispatcher</td><td>Стать диспетчером заявки</td></tr><tr><td>5</td><td>TakeForCheck</td><td>Взять на проверку</td></tr><tr><td>6</td><td>SendForRevision</td><td>На доработку</td></tr><tr><td>7</td><td>BecomeExecutor</td><td>Взять в работу</td></tr><tr><td>8</td><td>SetStatusByExecutor</td><td>Обновить статус как исполнитель</td></tr><tr><td>9</td><td>SetStatusByDispatcher</td><td>Обновить статус как диспетчер</td></tr></tbody></table></summary>
 */
export enum Outsourcing_SharedDtos_UiSettings_OrderActionType {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
    '_5' = 5,
    '_6' = 6,
    '_7' = 7,
    '_8' = 8,
    '_9' = 9,
}
