import { useAppSelector } from 'hooks/useAppSelector';
import { InfoCard } from '../../../components/InfoCard';
import { sliderSettings, sliderData } from './constants';
import { StyledSlider } from './styled';
import { getPermissions } from 'reduxStore/reducers/ProfileSlice/selectors/getPermissions';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';

export const Slider = () => {
  const permissions = useAppSelector(getPermissions);
  let slides = sliderData;
  if (
    permissions?.includes(ProfilePermissions.Request_ExecutorSubcontractor) ||
    permissions?.includes(ProfilePermissions.Request_Executor)
  ) {
    slides = sliderData.filter((item) => item.id !== 1);
  }
  return (
    <StyledSlider {...sliderSettings}>
      {slides.map((sliderItem) => (
        <InfoCard key={sliderItem.id} infoItem={sliderItem} />
      ))}
    </StyledSlider>
  );
};
