import { ArrowBack, BurgerIcon } from 'assets/icons';
import classNames from 'classnames';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useMediaQuery from 'hooks/useMediaQuery';
import { SIDEBAR_OPEN_LOCALSTORAGE_NAME } from 'layout/Aside/constants';
import { useNavigationHistory } from 'navigationProvider';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { profileActions } from 'reduxStore/reducers/ProfileSlice';
import { getProfileState } from 'reduxStore/reducers/ProfileSlice/selectors/getProfileState';
import { logoutHandler } from 'utils/helpers';
import { IS_MEDIUM_DEVICE, IS_SMALL_DEVICE } from 'utils/settings';
import { PageWrapperProps } from './types';

export const PageWrapper: FC<PageWrapperProps> = ({
  title,
  children,
  isGoBack = true,
  withMark = false,
  scrollDivRef,
}) => {
  const navigate = useNavigate();
  const { history, removeLastRoute } = useNavigationHistory();
  const location = useLocation();

  const isSmall = useMediaQuery(IS_SMALL_DEVICE);
  const isMedium = useMediaQuery(IS_MEDIUM_DEVICE);
  const dispatch = useAppDispatch();
  const { isSidebarOpen } = useAppSelector(getProfileState);

  const handleToggleOpenSidebar = (): void => {
    if (isSidebarOpen) {
      localStorage.removeItem(SIDEBAR_OPEN_LOCALSTORAGE_NAME);
    } else {
      localStorage.setItem(SIDEBAR_OPEN_LOCALSTORAGE_NAME, 'true');
    }
    dispatch(profileActions.toggleSidebar(!isSidebarOpen));
  };

  const handleGOBack = () => {
    if (history.length <= 1) {
      return;
    }
    navigate(-1);
    removeLastRoute();
    removeLastRoute();
  };

  return (
    <div ref={scrollDivRef}>
      <header className="mb-5 flex items-center justify-between">
        <div className="flex items-center">
          <div>
            {!isMedium ? (
              isGoBack &&
              (history.length > 1 ? (
                <ArrowBack
                  className={classNames('mr-5 h-5 w-5 cursor-pointer', {
                    '!cursor-not-allowed fill-text-50': history.length <= 1,
                  })}
                  onClick={handleGOBack}
                />
              ) : (
                <></>
              ))
            ) : location.pathname.split('/').length < 3 ||
              location.pathname.split('/')[2] === 'final-step' ? (
              <BurgerIcon
                className="mr-5 h-5 w-5 cursor-pointer"
                onClick={handleToggleOpenSidebar}
              />
            ) : (
              <ArrowBack
                className={classNames('mr-5 h-5 w-5', {
                  '!cursor-not-allowed': history.length <= 1,
                })}
                onClick={() => navigate(-1)}
              />
            )}
          </div>
          <span className="title2-medium sm:title2-bold lg:large-title2-bold mt-1">
            {title}
            {withMark && <span className="ml-1 text-red">!</span>}
          </span>
        </div>
        {isSmall && location.pathname === '/profile' && (
          <div className="flex items-center">
            <button className="callout-medium text-right" onClick={logoutHandler}>
              ВЫЙТИ
            </button>
          </div>
        )}
      </header>
      <section>{children}</section>
    </div>
  );
};
