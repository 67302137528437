import { FieldInput } from 'components/redesign/FieldInput';
import { FieldUploadFileInput } from 'components/redesign/FieldUploadFileInput';
import { FileBusinessType } from 'enums/fileBusinessType';
import { FC } from 'react';
import { Step4Props } from './types';
import { ESubcontractorModerationRequestStatusId } from 'enums/subcontractorModerationRequestStatus';
import { ESubcontractorModerFormName } from 'pages/ModerationRequest/types';
import useMediaQuery from 'hooks/useMediaQuery';
import { IS_SMALL_DEVICE } from 'utils/settings';

export const Step4Fields: FC<Step4Props> = ({
  control,
  isFormDisabled,
  errors,
  clearErrors,
  moderationRequestData,
  checkFieldInChecklist,
  watch,
}) => {
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);
  const isEmptyElectrical = watch('certificateElectricalSafetyQuantity');
  const isEmptyWorkHeight = watch('certificateWorkHeightQuantity');

  return (
    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
      <FieldInput
        name="certificateElectricalSafetyQuantity"
        control={control}
        label="Количество сотрудников с сертификатом"
        isClearable
        type="number"
        disabled={
          moderationRequestData?.statusId ===
          ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? checkFieldInChecklist &&
              checkFieldInChecklist(
                moderationRequestData,
                ESubcontractorModerFormName.certificateElectricalSafetyScan
              )
            : isFormDisabled
        }
        errorMessage={errors?.certificateElectricalSafetyQuantity?.message}
        maxLength={4}
      />
      <FieldInput
        name="certificateWorkHeightQuantity"
        control={control}
        label="Количество сотрудников с сертификатом"
        isClearable
        type="number"
        disabled={
          moderationRequestData?.statusId ===
          ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? checkFieldInChecklist &&
              checkFieldInChecklist(
                moderationRequestData,
                ESubcontractorModerFormName.certificateWorkHeightScan
              )
            : isFormDisabled
        }
        errorMessage={errors?.certificateWorkHeightQuantity?.message}
        maxLength={4}
      />
      <div>
        <span className="headline-bold sm:title2-bold">Удостоверения по электробезопасности</span>
      </div>
      {!isSmall && (
        <div>
          <span className="headline-bold sm:title2-bold">
            Удостоверения на допуск к работам на высоте
          </span>
        </div>
      )}
      <FieldUploadFileInput
        name={'certificateElectricalSafetyScan'}
        control={control}
        errorMessage={(errors as any)?.certificateElectricalSafetyScan?.message}
        disabled={
          typeof isEmptyElectrical === 'undefined'
            ? true
            : moderationRequestData?.statusId ===
              ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? checkFieldInChecklist &&
              checkFieldInChecklist(
                moderationRequestData,
                ESubcontractorModerFormName.certificateElectricalSafetyScan
              )
            : isFormDisabled
        }
        fileBusinessTypeId={FileBusinessType.CertificateElectricalSafety}
        clearErrors={clearErrors}
        uploadLimit={5}
      />
      {isSmall && (
        <div>
          <span className="headline-bold sm:title2-bold">
            Удостоверения на допуск к работам на высоте
          </span>
        </div>
      )}
      <FieldUploadFileInput
        name={'certificateWorkHeightScan'}
        control={control}
        errorMessage={(errors as any)?.certificateWorkHeightScan?.message}
        disabled={
          typeof isEmptyWorkHeight === 'undefined'
            ? true
            : moderationRequestData?.statusId ===
              ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? checkFieldInChecklist &&
              checkFieldInChecklist(
                moderationRequestData,
                ESubcontractorModerFormName.certificateWorkHeightScan
              )
            : isFormDisabled
        }
        fileBusinessTypeId={FileBusinessType.CertificateWorkHeight}
        clearErrors={clearErrors}
        uploadLimit={5}
      />
    </div>
  );
};
