import { FC } from 'react';
import { TableProps } from './types';
import classNames from 'classnames';

export const Table: FC<TableProps> = ({ tableHeading, tableData, onRowClick }) => {
  return (
    <div className="styled-scrollbar overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr>
            {tableHeading.map((tableHead) => (
              <th
                key={tableHead.attributeName}
                className="border-r-[1px] border-b-[1px] border-t-[1px] border-light-50 bg-light-30 px-[15px] py-2 text-left first:border-l-[1px]"
              >
                {tableHead.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((tableRow) => {
            return (
              <tr
                key={tableRow.id}
                className={classNames({ 'cursor-pointer': !!onRowClick })}
                onClick={() => onRowClick && onRowClick(tableRow)}
              >
                {tableHeading.map((tableHeadColumn) => {
                  const column = tableRow[tableHeadColumn.attributeName];
                  return (
                    <td
                      className="border-b-[1px] border-light-50 px-[15px] py-2 first:border-l-[1px] last:border-r-[1px]"
                      key={`${column}-${tableHeadColumn.attributeName}`}
                    >
                      {tableHeadColumn?.renderColumn
                        ? tableHeadColumn.renderColumn(column, tableRow)
                        : (column as string | number)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
