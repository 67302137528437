import { request } from 'api';
import { SubcontractorModeration } from 'reduxStore/reducers/SubcontractorModerationSlice/types';

export const SubcontractorModerationRequestService = {
  getCurrentModerationRequest() {
    return request<SubcontractorModeration>('get', '/api/v1/Verification/Current');
  },
  getCurrentModerationRequestWithCheckList() {
    return request<SubcontractorModeration>('get', '/api/v1/Verification/CurrentWithCheckList');
  },
};
