import { Profile } from 'services/ProfileService/types';
import { OrderCardTabSettings } from 'types';

export enum ExecutorTypeEnum {
  // Самозанятый (СЗ)
  Selfemployed = 1,
  // Индивидуальный предприниматель (ИП)
  Individual = 2,
  // Отделе Регионального ИТ Сервиса (ОРИТС)
  DRITS = 3,
  // Субподрядчик
  Subcontractor = 4,
}

export enum ProfileSpecialization {
  // ИТ – инженер
  // Техническое обслуживание, Ремонтно-восстановительные работы, Плановая и аварийная замена ФН, Регистрация ККТ и прочие
  ITEngineer = 1,
  // Монтажные работы
  // Прокладка СКС, Замена ламп накаливания, светодиодных, энергосберегающих, Демонтаж ТВ - Монтаж ТВ, Сбор мебели, Ремонт мебели
  InstallationWork = 2,

  // Доставка
  // Доставка, Разгрузочно-погрузочные работы
  Delivery = 3,

  // Клининг
  // Установка информационных табличек, Мойка окон, витражей, входных групп, Мойка вывесок, баннеров, стоперов на объекте
  Cleaning = 4,
  // Агент
  // Комплектация документов по клиентам
  Agent = 5,
}

export enum ProfilePermissions {
  //   /* Разрешения для модуля Identity (1-100) */
  //
  //   // Получение данных пользователя по id
  //   Users_ReadById = 1,
  // // Получение данных пользователей и назначенных ролей
  //   Users_ReadRoles = 2,
  //   // Изменение ролей у пользователя
  //   Users_UpdateRoles = 3,
  //
  //   //Получение данных ролей
  //   Roles_Read = 4,
  //   // Изменение ролей
  //   Roles_Update = 5,
  //   // Создание ролей
  //   Roles_Create = 6,
  //
  //   // Получение списка исполнителей по городу
  //   Users_ReadExecutorsFromCity = 7,
  //
  //   //Импорт пользователй из pyrus
  //   Users_ImportFromFile = 8,
  //
  //   // Получение доступа к разделам техподдержки
  //   Users_TechSupportAccess = 9,
  //
  //   //Изменение ролей у пользователя техподдержки
  //   Users_UpdateTechSupportRoles = 10,
  //
  //   // Маркер ролей для техподдержки в качестве наблюдателя
  //
  //   Roles_TechSupportObserverMarker = 11,
  //
  //   // Создание учетной записи
  //   Users_Create = 12,
  //
  //   // Напомнить о подтверждении телефона
  //   Users_RemindConfirmPhone = 13,
  //
  //   // Получение списка исполнителей
  //   Users_ReadExecutors = 14,
  //
  //   /* Разрешения для модуля Onboarding(101 - 200) */
  //   [Display(GroupName = 'Onboarding', Name = 'Получение данных заявки на модерацию', Description = '')];
  // ModerationRequest_Read = 101,
  //
  // Создание заявки на модерацию
  ModerationRequest_Create = 102,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Обновление заявки на модерацию', Description = '')];
  // ModerationRequest_Update = 103,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Взять на рассмотрение заявку на модерацию, работа с чатом', Description = '')];
  // Underwriting_RequestReview = 104,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Получение данных чек листа', Description = '')];
  // Underwriting_Read = 105,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Обновление чек листа, завершение рассмотрения заявки', Description = '')];
  // Underwriting_Update = 106,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Получение данных заявки на обучение', Description = '')];
  // SurveyRequest_Read = 107,
  //
  // Обновление заявки на обучение
  SurveyRequest_Update = 108,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Назначить новую дату обучения', Description = '')];
  // SurveyRequest_SetNewDateTraining = 109,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Список всех заявок на модерацию', Description = '')];
  // ModerationRequestList_AllRequest = 110,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Список заявок на модерацию', Description = '')];
  // ModerationRequestList_SentToModeration = 111,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Список заявок на модерацию в моем рассмотрении', Description = '')];
  // ModerationRequestList_MyReviewUnderwriting = 112,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Список отклоненных заявок на модерацию', Description = '')];
  // ModerationRequestList_Rejection = 113,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Список заявок на обучение', Description = '')];
  // ModerationRequestList_Training = 114,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Список заявок на повторное обучение', Description = '')];
  // ModerationRequestList_ReTraining = 115,
  //
  // Подготовить черновик (без сохранения)
  ModerationRequest_PrepareDraft = 116,
  //
  // Доступ к вкладкам со списками заявок на модерацию
  ModerationRequestList_AccessToTabs = 117,
  //
  //   [Display(GroupName = 'Onboarding', Name = 'Обновить статус заявки на модерацию рекрутером вручную', Description = '')];
  // ModerationRequest_ForceUpdateStatusByRecruiter = 118,
  //   #endregion;
  //
  // #region;
  // Разрешения;
  // для;
  // модуля;
  // Outsourcing(201 - 300)
  // Просмотр заказа
  Order_Read = 201,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Стать исполнителем заказа', Description = '')];
  // Order_BecomeExecutor = 202,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Стать диспетчером заказа', Description = '')];
  // Order_BecomeDispatcher = 203,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Назначить исполнителя заказа', Description = '')];
  // Order_SetExecutor = 204,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Создание заказа', Description = '')];
  // Order_Create = 205,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Обновление заказа исполнителем', Description = '')];
  // Order_UpdateByExecutor = 206,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Обновление заказа диспетчером', Description = '')];
  // Order_UpdateByDispatcher = 207,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Показать для супервайзера', Description = '')];
  // Order_ShowForSupervisor = 208,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Выгружать отчеты по модулю Outsourcing', Description = '')];
  // OutsourcingReports_Show = 209,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Выполнять массовое редактирование заказов', Description = '')];
  // Orders_MassEdit = 210,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Подготовка заявки', Description = '')];
  // Order_Prepare = 211,
  //
  // Предоставить настройки интерфейса для исполнителя
  OrderUiSettings_ShowForExecutor = 212,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Предоставить настройки интерфейса для диспетчера', Description = '')];
  // OrderUiSettings_ShowForDispatcher = 213,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Предоставить настройки интерфейса для супервайзера', Description = '')];
  // OrderUiSettings_ShowForSupervisor = 214,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Показать функционал настройки фильтров', Description = '')];
  // OrderFilterSettings_Show = 215,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Получение списка оборудования', Description = '')];
  // OrderEquipments_Read = 216,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Редактирование записей в справочнике оборудования', Description = '')];
  // OrderEquipments_Update = 217,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Создание новой записи в справочнике оборудования', Description = '')];
  // OrderEquipments_Create = 218,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Получение списка вида услуг', Description = '')];
  // OrderServiceTypes_Read = 219,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Редактирование записей в справочнике вида услуг', Description = '')];
  // OrderServiceTypes_Update = 220,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Создание новой записи в справочнике вида услуг', Description = '')];
  // OrderServiceTypes_Create = 221,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Индивидуальное редактирование заявки', Description = '')];
  // Order_SingleEdit = 222,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Создание точки обслуживания', Description = '')];
  // ServiceObjects_Create = 223,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Просмотр доплаты по заявке', Description = '')];
  // OrderBonus_Read = 224,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Просмотр комментария к доплате по заявке', Description = '')];
  // OrderBonus_ReadComment = 225,
  //
  //   [Display(GroupName = 'Outsourcing', Name = 'Изменение доплаты по заявке', Description = '')];
  // OrderBonus_Edit = 226,
  //   #endregion;
  //
  // #region;
  // Разрешения;
  // для;
  // модуля;
  // SpsManagement(301 - 400)
  // Просмотр заявки на ЗИП
  SpsRequest_Read = 301,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Просмотр заявки на ЗИП как диспетчер ЗИП', Description = '')];
  // SpsRequest_ReadAsSpsDispatcher = 302,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Просмотр заявки на ЗИП как логист', Description = '')];
  // SpsRequest_ReadAsLogist = 303,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Стать диспетчером заявки на ЗИП', Description = '')];
  // SpsRequest_BecomeDispatcher = 304,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Стать вторичным диспетчером заявки на ЗИП', Description = '')];
  // SpsRequest_ReassignDispatcher = 305,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Согласовать заявку на ЗИП', Description = '')];
  // SpsRequest_Approve = 306,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Отказать по заявке на ЗИП', Description = '')];
  // SpsRequest_Reject = 307,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Создать заявку на ЗИП', Description = '')];
  // SpsRequest_Create = 308,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Стать логистом заявки на ЗИП', Description = '')];
  // SpsRequest_BecomeLogist = 309,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Стать вторичным логистом заявки на ЗИП', Description = '')];
  // SpsRequest_ReassignLogist = 310,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Подтвердить заявки на ЗИП', Description = '')];
  // SpsRequest_Confirm = 311,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Редактировать заявку на ЗИП', Description = '')];
  // SpsRequest_Edit = 312,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Подтвердить списание заявки на ЗИП', Description = '')];
  // SpsRequest_WriteOff = 313,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Задать количество использованных номенклатур', Description = '')];
  // SpsRequest_SetAmountUsed = 314,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Стать логистом заявки на ЗИП для определения ИУП', Description = '')];
  // SpsRequest_BecomeLogistSSN = 315,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Стать вторичным логистом заявки на ЗИП для определения ИУП', Description = '')];
  // SpsRequest_ReassignLogistSSN = 316,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Завершить обработку ИУП', Description = '')];
  // SpsRequest_ProvisionSSN = 317,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Завершение этапа поставки ИУП', Description = '')];
  // SpsRequest_DeliveryWaitingSSN = 318,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Отправка писем кладовщику', Description = '')];
  // SpsRequest_SendToStorekeeper = 319,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Обновление справочника номенклатур', Description = '')];
  // SpsNomenclatures_Update = 330,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Полная синхронизация справочника номенклатур', Description = '')];
  // SpsNomenclatures_SyncAll = 331,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Полная синхронизация справочника складов', Description = '')];
  // Warehouse_SyncAll = 332,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Просмотр источника и склада ЗИП', Description = '')];
  // SpsRequest_ReadWarehouse = 333,
  //
  //   [Display(GroupName = 'SpsManagement', Name = 'Обновление справочника складов', Description = '')];
  // Warehouse_Update = 334,
  //   #endregion;
  //
  // #region;
  // Разрешения;
  // для;
  // модуля;
  // GeoData(401 - 500)
  //   [Display(GroupName = 'GeoData', Name = 'Получение адреса через подсказку Dadata', Description = '')];
  // GeoData_GetSuggestionAddresses = 401,
  //
  //   [Display(GroupName = 'GeoData', Name = 'Проверка и создание адреса через Dadata', Description = '')];
  // GeoData_CheckOrCreateAddress = 402,
  //   #endregion;
  //
  // #region;
  // Разрешения;
  // для;
  // модуля;
  /* Billing(501 - 600) */
  // Чтение своих чеков исполнителя
  'Billing_GetMyExecutorReceipts' = 501,

  // Обновление своих чеков исполнителя
  'Billing_UpdateMyExecutorReceipts' = 502,

  // Чтение чеков исполнителя
  'Billing_GetExecutorReceipts' = 503,

  // #region Разрешения для модуля Agent (651-700)
  //
  //         [Display(GroupName = "Agent", Name = "Создание агентского заказа", Description = "")]
  //         AgentRequest_Create = 651,
  //
  //         [Display(GroupName = "Agent", Name = "Редактирование агентского заказа", Description = "")]
  //         AgentRequest_Edit = 652,
  //
  //         [Display(GroupName = "Agent", Name = "Просмотр агентского заказа", Description = "")]
  //         AgentRequest_Read = 653,
  //
  //         [Display(GroupName = "Agent", Name = "Обновление агентского заказа инициатором", Description = "")]
  //         AgentRequest_UpdateByInitiator = 654,
  //
  //         [Display(GroupName = "Agent", Name = "Обновление агентского заказа диспетчером", Description = "")]
  //         AgentRequest_UpdateByDispatcher = 655,
  //
  //         [Display(GroupName = "Agent", Name = "Обновление агентского заказа исполнителем", Description = "")]
  //         AgentRequest_UpdateByExecutor = 656,
  //
  //         [Display(GroupName = "Agent", Name = "Стать диспетчером агентского заказа", Description = "")]
  //         AgentRequest_BecomeDispatcher = 657,
  //
  //         [Display(GroupName = "Agent", Name = "Согласовать агентский заказ", Description = "")]
  //         AgentRequest_Approve = 658,
  //
  //         [Display(GroupName = "Agent", Name = "Отказать по агентскому заказу", Description = "")]
  //         AgentRequest_Reject = 659,
  //
  //         [Display(GroupName = "Agent", Name = "Просмотр агентских заказов диспетчером", Description = "")]
  //         AgentRequest_ShowForDispatcher = 660,
  //
  //         [Display(GroupName = "Agent", Name = "Просмотр агентских заказов инициатором", Description = "")]
  //         AgentRequest_ShowForInitiator = 661,
  //
  //  Просмотр агентских заказов исполнителем
  AgentRequest_ShowForAgentExecutor = 662,

  // Разрешения для модуля Company (901-1000)
  //
  // Создание анкеты
  VerificationQuestionary_Create = 901,
  //
  // [Display(GroupName = "Company", Name = "Обновление заявки на модерацию", Description = "")]
  // VerificationQuestionary_Update = 902,
  //
  // [Display(GroupName = "Company", Name = "Просмотр заявки на модерацию", Description = "")]
  // VerificationQuestionary_Read = 903,
  //
  // [Display(GroupName = "Company", Name = "Подготовка черновика заявки на модерацию", Description = "")]
  // VerificationQuestionary_PrepareDraft = 904,
  //
  // [Display(GroupName = "Company", Name = "Получение списка анкет организаций", Description = "")]
  // VerificationQuestionaryList_Read = 905,
  //
  // [Display(GroupName = "Company", Name = "Получение доступа к табам со списками анкет организаций", Description = "")]
  // VerificationQuestionaryList_AccessToTabs = 906,
  //
  // [Display(GroupName = "Company", Name = "Получение данных чек-листа организации", Description = "")]
  // CheckList_Read = 907,
  //
  // [Display(GroupName = "Company", Name = "Рассмотрение анкеты на модерацию, работа с чатом", Description = "")]
  // CheckList_RequestReview = 908,
  //
  // [Display(GroupName = "Company", Name = "Обновление чек-листа, завершение рассмотрения анкеты", Description = "")]
  // CheckList_Update = 909,
  //
  // [Display(GroupName = "Company", Name = "Назначение тегов для субподрядчиков", Description = "")]
  // Organization_ChangeCharacteristic = 910,
  //
  // Разрешения для модуля Outsource (1001-1100)
  // Разрешение-маркер. Исполнитель
  Request_Executor = 1001,
  // Разрешение-маркер. Исполнитель-субподрядчик
  Request_ExecutorSubcontractor = 1002,
  // Разрешение-маркер. Диспетчер
  Request_Dispatcher = 1003,
  // Просмотр заявки
  MainRequest_Read = 1004,
  // Стать исполнителем заявки
  MainRequest_BecomeExecutor = 1005,
  // Отменить работы по заявке
  MainRequest_CancelWork = 1006,
  // Выполнить работы по заявке
  MainRequest_CompleteWork = 1007,
  // Отказаться от заявки
  MainRequest_Refuse = 1008,
  // #region;
  // NotSet(65534)
  //   [Display(GroupName = 'ServiceState', Name = 'Не назначено', Description = '')];
  // NotSet = 65534, //error condition
  //   #endregion;
  //
  // #region;
  // SuperAdmin(65535)
  //   [Display(GroupName = 'SuperAdmin', Name = 'AccessAll', Description = '')];
  // AccessAll = 65535,
  //   #endregion;
  //
}

export interface ProfileState {
  profile: Profile;
  permissions: ProfilePermissions[];
  tabSettings: OrderCardTabSettings[];
  isSidebarOpen: boolean;
}
