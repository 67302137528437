import { ArrowBack, CloseModalIcon } from 'assets/icons';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FC, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { getModalState } from 'reduxStore/reducers/ModalSlice/selectors/getModaState';
import { overlayVariant, tapAnimationVariant } from './constants';

export const ModalWindow: FC = () => {
  const { isOpen, options, content } = useAppSelector(getModalState);
  const dispatch = useAppDispatch();

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') dispatch(modalActions.closeModal());
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          variants={overlayVariant}
          initial="initial"
          animate="animate"
          exit="initial"
          transition={{ duration: 0.2 }}
          className="hide-scrollbar fixed left-0 right-0 bottom-0 top-0 z-[1000] h-full w-full overflow-y-auto overflow-x-hidden bg-[#04040F] bg-opacity-40"
          aria-hidden
          onClick={() => dispatch(modalActions.closeModal())}
        >
          <div
            className={classNames(
              'relative mx-auto flex h-full w-full items-start bg-base sm:h-auto sm:max-h-[90vh] sm:min-h-modal sm:w-auto sm:max-w-[750px] sm:items-center sm:justify-center sm:!bg-transparent md:px-0',
              {
                'bg-transparent': options?.isTransparent,
              }
            )}
          >
            <div
              className={classNames(
                'default-scrollbar-styles relative flex h-full min-h-[20vh] flex-col overflow-y-auto rounded-[6px] bg-base pt-4 sm:max-h-[90vh] sm:p-[32px] sm:shadow-3xl',
                options?.modalClassName
              )}
              onClick={(e) => e.stopPropagation()}
              aria-hidden
            >
              {(options?.withCloseButton !== false || options?.title) && (
                <div
                  className={classNames('flex items-center px-4', {
                    'gap-3 sm:justify-between': !!options?.title,
                    'justify-start sm:justify-end': !options?.title,
                  })}
                >
                  {options?.title && (
                    <span className="headline-bold order-2 leading-6 text-text-100 sm:order-1">
                      {options?.title}
                    </span>
                  )}

                  {options?.withBackButton && (
                    <motion.button
                      whileTap={tapAnimationVariant}
                      className="pointer order-1 flex h-[32px] w-[32px] items-center justify-center rounded-[50%] transition-colors duration-300 sm:order-2 sm:text-text-50"
                      onClick={() => dispatch(modalActions.closeModal())}
                    >
                      <ArrowBack className="text-text-100 sm:text-text-50" />
                    </motion.button>
                  )}

                  {options?.withCloseButton !== false && options?.withBackButton !== true && (
                    <motion.button
                      whileTap={tapAnimationVariant}
                      className="pointer order-1 flex h-[32px] w-[32px] items-center justify-center rounded-[50%] transition-colors duration-300 sm:order-2 sm:text-text-50"
                      onClick={() => dispatch(modalActions.closeModal())}
                    >
                      <CloseModalIcon className="text-text-100 sm:text-text-50" />
                    </motion.button>
                  )}
                </div>
              )}
              <div
                className={classNames('h-full overflow-auto sm:px-4', options?.contentClassName)}
              >
                {content}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body as HTMLElement
  );
};

ModalWindow.displayName = 'ModalWindow';
