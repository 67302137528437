import { AxiosError, AxiosResponse } from 'axios';
import { ChangeEvent, useRef, useState } from 'react';

import { FilePreview } from 'components/FilePreview';
import { Modal } from 'ui/Modal';

import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { UploadFileIcon } from 'assets/icons';
import classNames from 'classnames';
import { FileBusinessType } from 'enums/fileBusinessType';
import { ErrorResponse, OrderFile, PaymentOrderEnum, PaymentOrderRequest } from 'types';
import { File } from 'ui/redesign/File';
import { fetchFile, handleFileDownload } from 'utils/helpers';

interface UploadReceiptInputProps {
  paymentOrderId: number;
  onChange: (files: OrderFile[]) => void;
  fileBusinessTypeId?: FileBusinessType;
  receiptFiles: OrderFile[];
  disabled?: boolean;
  status: PaymentOrderEnum;
  className?: string;
  isHidePreviewFileButton?: boolean;
}

const MAX_SIZE: number = 10 * 1024 * 1024;

function UploadReceiptInput({
  paymentOrderId,
  fileBusinessTypeId = 11,
  receiptFiles = [],
  disabled = false,
  onChange,
  status,
  className,
  isHidePreviewFileButton,
}: UploadReceiptInputProps) {
  const [files, setFiles] = useState<OrderFile[]>(receiptFiles); // Все файлы: загруженные пользователем и с бэкенда
  const [showError, setShowError] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<string>('');
  const [fileModalOpen, setFileModalOpen] = useState<boolean>(false);
  const [currentFile, setCurrentFile] = useState<{ content: string; type: string } | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const uploadFile = async (files: FileList) => {
    const formData: FormData = new FormData();
    Array.from(files).forEach((file) => {
      const blob = new Blob([file], { type: file.type });
      formData.append('files', blob, file.name);
    });
    formData.append('FileBuisenessTypeId', String(fileBusinessTypeId));
    formData.append('DeleteLink', 'false');

    try {
      const response = await request<OrderFile[]>(
        endpoints.UPLOAD_FILE.type,
        endpoints.UPLOAD_FILE.url(),
        {
          data: formData,
        }
      );
      if (response.status >= 200 || response.status < 300) {
        const requestBody: PaymentOrderRequest = {
          paymentOrderId,
          files: response.data,
        };
        onChange(response.data);
        setFiles(response.data);
      }
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      axiosError.response?.data.detail && setShowErrorMessage(axiosError.response?.data.detail);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowErrorMessage('');
    const _files = e.target.files;
    if (_files && _files.length > 0) {
      for (let i = 0; i < _files.length; i++) {
        if (_files[i].size > MAX_SIZE) {
          setShowError(true);
          return;
        }
      }
      setShowError(false);
      uploadFile(_files);
    }
  };

  const handleFilePreview = async (callback: Promise<AxiosResponse>) => {
    const response = await callback;
    const file = response.data;
    setCurrentFile({
      content: file.content,
      type: file.contentType,
    });
    setFileModalOpen(true);
  };

  const handleDivClick = () => {
    if (fileInputRef.current && !disabled) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      {currentFile && fileModalOpen && (
        <Modal
          open={fileModalOpen}
          onClose={() => setFileModalOpen(false)}
          modalClasses="flex flex-col lg:max-w-[75%] xl:max-w-[65%] h-max p-0"
          label="Просмотр"
        >
          <FilePreview file={currentFile} />
        </Modal>
      )}
      <div className="flex flex-col items-center">
        {files.length > 0 ? (
          <div className="w-full">
            <File
              file={files[0]}
              key={files[0].downloadFileIdHash}
              onPreview={
                isHidePreviewFileButton
                  ? undefined
                  : () => handleFilePreview(fetchFile(files[0].downloadFileIdHash))
              }
              onDelete={
                PaymentOrderEnum['Ожидает загрузки'] === status ||
                PaymentOrderEnum['Чек не принят'] === status
                  ? () => {
                      setFiles([]);
                      onChange([]);
                    }
                  : undefined
              }
              onDownload={
                PaymentOrderEnum['Чек принят'] === status ||
                PaymentOrderEnum['Ожидает проверки'] === status
                  ? () => handleFileDownload(fetchFile(files[0].downloadFileIdHash))
                  : undefined
              }
              disabled={disabled}
            />
            {showErrorMessage && <p className="px-3 text-red">{showErrorMessage}</p>}
          </div>
        ) : (
          <div
            className={classNames(
              'relative flex w-full flex-col items-center rounded-[0.625rem] border border-light-30 p-3 text-text-50',
              className,
              {
                '!border-danger': showErrorMessage,
                'cursor-pointer hover:border-primary-60 hover:text-primary-60': !disabled,
                'cursor-not-allowed hover:border-light-30 hover:text-text-50': disabled,
                'text-danger hover:text-primary-60': showErrorMessage,
              }
            )}
            onClick={handleDivClick}
          >
            <UploadFileIcon
              className={classNames(
                'btn-accent headline-bold mb-4 mt-3 flex max-w-[256px] items-center justify-center text-text-100'
              )}
            />
            <span
              className={classNames(
                'btn-accent headline-bold mb-4 flex max-w-[256px] items-center justify-center text-primary-60'
              )}
            >
              Добавить файлы
            </span>
            <input
              type="file"
              className="hidden"
              id={`download-file-${paymentOrderId}`}
              accept=".png, .jpg, .jpeg, .pdf"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
            <div className="text-center">
              <p>Максимальный размер файла: 10 МБ.</p>
              <p>Допустимые типы: jpg, jpeg, pdf, png</p>
              <p>Допустимо приложить не более 1 файла</p>
              {showError && (
                <p className="mt-2 text-red">
                  Один из выбранных файлов больше 10 мегабайт, выберите файл меньшего размера
                </p>
              )}
              {showErrorMessage && <p className="px-3 text-red">{showErrorMessage}</p>}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default UploadReceiptInput;
