import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubcontractorModeration, SubcontractorModerationRequestState } from './types';
import { Step1Payload } from 'pages/ModerationRequest/SubcontractorModeration/steps/step1/types';
import { Step2Payload } from 'pages/ModerationRequest/SubcontractorModeration/steps/step2/types';
import { Step3Payload } from 'pages/ModerationRequest/SubcontractorModeration/steps/step3/types';
import { Step4Payload } from 'pages/ModerationRequest/SubcontractorModeration/steps/step4/types';
import { OrganizationType } from 'enums/organizationType';
import { EServiceAreaType } from 'enums/serviceAreaType';

const initialState: SubcontractorModerationRequestState = {
  subcontractorModeration: JSON.parse(
    sessionStorage.getItem('subcontractorModeration') || 'null'
  ) ?? {
    id: 0,
    rowVersion: null,
    moderatedUserId: 0,
    organizationTypeId: OrganizationType.Individual,
    ownerOrganizationId: 0,
    statusId: 0,
    organizationName: '',
    serviceAreas: [],
    egrulScan: null,
    egripScan: null,
    legalAddress: '',
    address: '',
    phoneNumber: '',
    email: '',
    inn: '',
    innScan: null,
    ogrn: '',
    ogrnScan: null,
    ogrnip: '',
    ogrnipScan: null,
    bankSettlement: '',
    bankName: '',
    bik: '',
    withVat: true,
    kpp: '',
    okato: '',
    okpo: '',
    files: [],
    serviceAreaTypeId: EServiceAreaType.FederalDistrict,
    contractSubcontractorProjectFiles: [],
    certificateElectricalSafetyQuantity: undefined,
    certificateElectricalSafetyScan: [],
    certificateWorkHeightQuantity: undefined,
    certificateWorkHeightScan: [],
  },
  isFormDisabled:
    JSON.parse(sessionStorage.getItem('isSubcontractorFormDisabled') || 'null') || false,
  isNavigateDisabled: false,
};

export const subcontractorModerationSlice = createSlice({
  name: 'moderationRequest',
  initialState,
  reducers: {
    setModerationRequest: (state, action: PayloadAction<SubcontractorModeration>) => {
      state.subcontractorModeration = action.payload;
      sessionStorage.setItem('subcontractorModeration', JSON.stringify(action.payload));
    },
    setIsFormDisabled: (state, action) => {
      state.isFormDisabled = action.payload;

      sessionStorage.setItem('isSubcontractorFormDisabled', JSON.stringify(action.payload));
    },
    setOrganizationTypeId: (state, action: PayloadAction<OrganizationType>) => {
      state.subcontractorModeration.organizationTypeId = action.payload;
      sessionStorage.setItem(
        'subcontractorModeration',
        JSON.stringify(state.subcontractorModeration)
      );
    },
    setCompanyData: (state, action: PayloadAction<Step1Payload>) => {
      state.subcontractorModeration.organizationName = action.payload.organizationName;
      state.subcontractorModeration.egrulScan = action.payload.egrulScan;
      state.subcontractorModeration.egripScan = action.payload.egripScan;
      state.subcontractorModeration.serviceAreas = action.payload.serviceAreas;
      state.subcontractorModeration.serviceAreaTypeId = action.payload.serviceAreaTypeId;

      sessionStorage.setItem(
        'subcontractorModeration',
        JSON.stringify(state.subcontractorModeration)
      );
    },
    setMainRequisites: (state, action: PayloadAction<Step2Payload>) => {
      state.subcontractorModeration.legalAddress = action.payload.legalAddress;
      state.subcontractorModeration.address = action.payload.address;
      state.subcontractorModeration.email = action.payload.email;
      state.subcontractorModeration.phoneNumber = action.payload.phoneNumber;
      state.subcontractorModeration.inn = action.payload.inn;
      state.subcontractorModeration.ogrnip = action.payload.ogrnip;
      state.subcontractorModeration.innScan = action.payload.innScan;
      state.subcontractorModeration.ogrnipScan = action.payload.ogrnipScan;

      sessionStorage.setItem(
        'subcontractorModeration',
        JSON.stringify(state.subcontractorModeration)
      );
    },
    setLLCMainRequisites: (state, action: PayloadAction<Step2Payload>) => {
      state.subcontractorModeration.legalAddress = action.payload.legalAddress;
      state.subcontractorModeration.address = action.payload.address;
      state.subcontractorModeration.email = action.payload.email;
      state.subcontractorModeration.phoneNumber = action.payload.phoneNumber;
      state.subcontractorModeration.inn = action.payload.inn;
      state.subcontractorModeration.ogrn = action.payload.ogrn;
      state.subcontractorModeration.innScan = action.payload.innScan;
      state.subcontractorModeration.ogrnScan = action.payload.ogrnScan;

      sessionStorage.setItem(
        'subcontractorModeration',
        JSON.stringify(state.subcontractorModeration)
      );
    },
    setBankProps: (state, action: PayloadAction<Step3Payload>) => {
      state.subcontractorModeration.bankSettlement = action.payload.bankSettlement;
      state.subcontractorModeration.bankName = action.payload.bankName;
      state.subcontractorModeration.bik = action.payload.bik;
      state.subcontractorModeration.withVat = action.payload.withVat;
      state.subcontractorModeration.files = action.payload.files;

      sessionStorage.setItem(
        'subcontractorModeration',
        JSON.stringify(state.subcontractorModeration)
      );
    },
    setLLCBankProps: (state, action: PayloadAction<Step3Payload>) => {
      state.subcontractorModeration.bankSettlement = action.payload.bankSettlement;
      state.subcontractorModeration.bankName = action.payload.bankName;
      state.subcontractorModeration.bik = action.payload.bik;
      state.subcontractorModeration.withVat = action.payload.withVat;
      state.subcontractorModeration.files = action.payload.files;
      state.subcontractorModeration.okpo = action.payload.okpo;
      state.subcontractorModeration.okato = action.payload.okato;
      state.subcontractorModeration.kpp = action.payload.kpp;

      sessionStorage.setItem(
        'subcontractorModeration',
        JSON.stringify(state.subcontractorModeration)
      );
    },
    setDocuments: (state, action: PayloadAction<Step4Payload>) => {
      state.subcontractorModeration.certificateElectricalSafetyQuantity =
        action.payload.certificateElectricalSafetyQuantity;
      state.subcontractorModeration.certificateElectricalSafetyScan =
        action.payload.certificateElectricalSafetyScan;
      state.subcontractorModeration.certificateWorkHeightQuantity =
        action.payload.certificateWorkHeightQuantity;
      state.subcontractorModeration.certificateWorkHeightScan =
        action.payload.certificateWorkHeightScan;

      sessionStorage.setItem(
        'subcontractorModeration',
        JSON.stringify(state.subcontractorModeration)
      );
    },
    setIsNavigateDisabled: (state, action: PayloadAction<boolean>) => {
      state.isNavigateDisabled = action.payload;
    },
  },
});

export const {
  reducer: subcontractorModerationRequestReducer,
  actions: subcontractorModerationRequestActions,
} = subcontractorModerationSlice;
