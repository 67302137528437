import { getPermissions } from 'reduxStore/reducers/ProfileSlice/selectors/getPermissions';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import {
  ProfilePermissions,
  ProfileSpecialization,
  ExecutorTypeEnum,
} from 'reduxStore/reducers/ProfileSlice/types';
import { sleep } from 'utils/helpers';
import { useAppSelector } from './useAppSelector';
import { getExecutorSpecializationIds } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorSpecializationIds';
import { getExecutorType } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorType';

export function useOrderRoute() {
  const user = useAppSelector(getProfile);
  const executorSpecializationIds = useAppSelector(getExecutorSpecializationIds);
  const executorType = useAppSelector(getExecutorType);

  const permissions = useAppSelector(getPermissions);

  const ordersUrl = '/orders';
  if (!user) {
    sleep(2000);
  }
  // Агенты
  if (
    executorSpecializationIds?.includes(ProfileSpecialization.Agent) &&
    permissions?.includes(ProfilePermissions.AgentRequest_ShowForAgentExecutor)
  ) {
    return {
      prefix: '/agent',
      mainUrl: `/agent${ordersUrl}/inProgress`,
    };
  }
  // Субподрядчик, вкладки new нет
  if (
    executorType === ExecutorTypeEnum.Subcontractor &&
    permissions?.includes(ProfilePermissions.Request_ExecutorSubcontractor)
  ) {
    return {
      prefix: '',
      mainUrl: `${ordersUrl}/inProgress`,
    };
  }
  // Обычный исполнитель
  if (permissions?.includes(ProfilePermissions.Request_Executor)) {
    return {
      prefix: '',
      mainUrl: `${ordersUrl}/new`,
    };
  }
  return {
    prefix: '',
    mainUrl: '/help',
  };
}
