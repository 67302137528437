import { Link, useParams } from 'react-router-dom';

import { AgentRequestForListCard } from 'pages/AgentRequests/types';

import { monthsMap } from 'const';
import { useAppSelector } from 'hooks/useAppSelector';
import { getTimezone } from 'reduxStore/reducers/ProfileSlice/selectors/getTimezone';
import { formatAmount, getDateWithTimezone } from 'utils/helpers';
import { ROUBLE_SIGN } from 'utils/settings';

interface IOrderCardProps {
  order: AgentRequestForListCard;
}

export const AgentRequestCard = ({ order }: IOrderCardProps) => {
  const { id, created, compensation, month, year } = order;

  const { type } = useParams();
  const timeZone = useAppSelector(getTimezone);

  return (
    <Link to={`/agent/orders/${type}/${id}`}>
      <div className="mb-4 w-full rounded-xl px-2 py-4 shadow-3xl">
        <div className="mb-4">
          <h6>Год</h6>
          <p className="text-base font-semibold leading-[20px]">{year}</p>
        </div>
        <div className="mb-4">
          <h6>Месяц</h6>
          <p className="text-base font-semibold leading-[20px]">{monthsMap[month]}</p>
        </div>
        <div className="mb-4">
          <h6>Вознаграждение</h6>
          <p className="text-base font-semibold leading-[20px]">
            {formatAmount(compensation)}&nbsp;{ROUBLE_SIGN}
          </p>
        </div>

        <div className="border-t border-gray">
          <div className="mt-2 flex justify-between">
            <p className="text-gray-medium">{id}</p>
            <p>
              <span className="font-semibold">Дата: </span>
              {getDateWithTimezone(created, timeZone, 'dd.MM.yyyy')}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};
