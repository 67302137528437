/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>0</td><td>PhysicalForeclosures</td><td>Проверка ФССП</td></tr><tr><td>1</td><td>PassportIsExpire</td><td>Проверка паспорта на просрочку</td></tr></tbody></table></summary>
 */
export enum Onboarding_Domain_ModerationDetails_ScoringTypeEnum {
    '_0' = 0,
    '_1' = 1,
}
