export enum FileBusinessType {
  // Не задан
  NotSet = 0,
  // Паспорт
  Passport = 1,
  // Образование
  Education = 2,
  // Опыт работы
  WorkExperience = 3,
  //Фото исполнителя
  ExecutorPhotos = 4,
  // Сертификат самозанятого
  SelfemployedCertificate = 5,
  // Другое
  Other = 6,
  // Результат массового редактирования заявок.
  Outsourcing__OrdersMassEditResult = 8,
  // Акт выполненных работ
  OrderWorkAct = 9,
  // Архив
  Archive = 10,
  // Информация об оплате
  Billing__ReceiptData = 11,
  // Свидетельство о постановке на учет ИП
  Onboarding__IndividualCertificate = 12,
  // Отчет
  Report = 14,
  // ОГРН Субподрядчика
  Ogrn = 15,
  // ОГРНИП Субподрядчика
  Ogrnip = 16,
  // ЕГРЮЛ Субподрядчика
  Egrul = 17,
  // ЕГРИП Субподрядчика
  Egrip = 18,
  // ИНН Субподрядчика
  Inn = 19,
  // Иные документы субподрядчика
  OtherSubcontractorDocs = 20,
  // Проект договора с субподрядчиком
  ContractSubcontractorProject = 21,

  // Стартовое письмо
  StartEmail = 22,
  // Акт (агентский)
  AgentAct = 23,
  // Исходные данные для массового создания заявок
  MassCreationInitialData = 24,
  // Реестр (результат) массового создания заявок
  MassCreationRegister = 25,
  // Удостоверение (электробезопасность)
  CertificateElectricalSafety = 26,
  // Удостоверение (работа на высоте)
  CertificateWorkHeight = 27,
}
