import {
  ModerationRequestStatusId,
  ModerationRequestStatusName,
} from 'enums/moderationRequestStatus';
import {
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormSetError,
  UseFormWatch,
} from 'react-hook-form';
import {
  ESubcontractorModerationRequestStatusId,
  ESubcontractorModerationRequestStatusName,
} from 'enums/subcontractorModerationRequestStatus';

//Draft = 'Черновик',
//   SentToModeration = 'Отправлена на модерацию',
//   ReviewStarted = 'Взята на рассмотрение',
//   ElaborationRequested = 'Запрошено уточнение',
//   ElaborationProvided = 'Уточнение предоставлено',
//   ModerationFailed = 'Не прошла модерацию',
//   Scoring = 'Скоринг',
//   ScoringFailed = 'Скоринг провален',
//   ScoringCompleted = 'Скоринг пройден',
//   Training = 'На обучение',
//   TrainingCompleted = 'Обучение пройдено',
//   TrainingFailed = 'Обучение провалено',
//   CreatingUnderwriting = 'Создается чек-лист',
//   ReTraining = 'Повторное обучение',
//   Imported = 'Импортировано',
//   Archive = 'Архив',

export const moderationRequestTitle: {
  [p: number]: ModerationRequestStatusName;
} = {
  [ModerationRequestStatusId.Draft]: ModerationRequestStatusName.Draft,
  [ModerationRequestStatusId.SentToModeration]: ModerationRequestStatusName.SentToModeration,
  [ModerationRequestStatusId.ReviewStarted]: ModerationRequestStatusName.ReviewStarted,
  [ModerationRequestStatusId.ElaborationRequested]:
    ModerationRequestStatusName.ElaborationRequested,
  [ModerationRequestStatusId.ElaborationProvided]: ModerationRequestStatusName.ElaborationProvided,
  [ModerationRequestStatusId.ModerationFailed]: ModerationRequestStatusName.ModerationFailed,
  [ModerationRequestStatusId.Scoring]: ModerationRequestStatusName.Scoring,
  [ModerationRequestStatusId.ScoringFailed]: ModerationRequestStatusName.ScoringFailed,
  [ModerationRequestStatusId.ScoringCompleted]: ModerationRequestStatusName.ScoringCompleted,
  [ModerationRequestStatusId.Training]: ModerationRequestStatusName.Training,
  [ModerationRequestStatusId.TrainingCompleted]: ModerationRequestStatusName.TrainingCompleted,
  [ModerationRequestStatusId.TrainingFailed]: ModerationRequestStatusName.TrainingFailed,
  [ModerationRequestStatusId.CreatingUnderwriting]:
    ModerationRequestStatusName.CreatingUnderwriting,
  [ModerationRequestStatusId.ReTraining]: ModerationRequestStatusName.ReTraining,
  [ModerationRequestStatusId.Imported]: ModerationRequestStatusName.Imported,
  [ModerationRequestStatusId.Archive]: ModerationRequestStatusName.Archive,
};

export enum EExecutorModerForm {
  executorType = 'executorType',
  fullName = 'fullName',
  citizenship = 'citizenship',
  birthDate = 'birthDate',
  mobilePhone = 'mobilePhone',
  email = 'email',
  inn = 'inn',
  bik = 'bik',
  bankSettlement = 'bankSettlement',
  acceptsOffer = 'acceptsOffer',
  series = 'series',
  number = 'number',
  dateOfIssue = 'dateOfIssue',
  unitCode = 'unitCode',
  issuedBy = 'issuedBy',
  executorSpecializations = 'executorSpecializations',
  passportFiles = 'passportFiles',
  executorPhotos = 'executorPhotos',
  selfemployedCertificate = 'selfemployedCertificate',
  educationExperience = 'educationExperience',
  additionalEducationExperience = 'additionalEducationExperience',
  workExperiences = 'workExperiences',
  description = 'description',
  files = 'files',
  ogrnip = 'ogrnip',
  individualName = 'individualName',
  individualFiles = 'individualFiles',
  serviceCities = 'serviceCities',
  birthPlace = 'birthPlace',
  registrationAddress = 'registrationAddress',
  certificateElectricalSafety = 'certificateElectricalSafety',
  certificateWorkHeight = 'certificateWorkHeight',
}

export enum ESubcontractorModerFormName {
  organizationName = 'organizationName',
  egrulScan = 'egrulScan',
  egripScan = 'egripScan',
  serviceAreas = 'serviceAreas',
  serviceAreasC = 'serviceAreasC',
  serviceAreasF = 'serviceAreasF',
  serviceAreasR = 'serviceAreasR',
  legalAddress = 'legalAddress',
  address = 'address',
  phoneNumber = 'phoneNumber',
  email = 'email',
  inn = 'inn',
  innScan = 'innScan',
  ogrn = 'ogrn',
  ogrnScan = 'ogrnScan',
  ogrnip = 'ogrnip',
  ogrnipScan = 'ogrnipScan',
  bankSettlement = 'bankSettlement',
  bankName = 'bankName',
  bik = 'bik',
  withVat = 'withVat',
  kpp = 'kpp',
  okato = 'okato',
  okpo = 'okpo',
  files = 'files',
  serviceAreaTypeId = 'serviceAreaTypeId',
  certificateElectricalSafetyQuantity = 'certificateElectricalSafetyQuantity',
  certificateElectricalSafetyScan = 'certificateElectricalSafetyScan',
  certificateWorkHeightQuantity = 'certificateWorkHeightQuantity',
  certificateWorkHeightScan = 'certificateWorkHeightScan',
}

export enum ESubcontractorModerFormLabel {
  organizationName = 'Наименование Организации/ИП',
  egrulScan = 'egrulScan',
  egripScan = 'egripScan',
  federalDistricts = 'Федеральный округ',
  subjects = 'Субъект Федерации',
  cities = 'Населенный пункт обслуживания',
  legalAddress = 'Юридический адрес',
  address = 'Фактический адрес',
  phoneNumber = 'Телефон',
  email = 'Электронная почта',
  inn = 'ИНН',
  ogrn = 'ОГРН',
  ogrnip = 'ОГРНИП',
  bankSettlement = 'Расчетный счет',
  bankName = 'Полное наименование банка',
  bik = 'БИК',
  withVat = 'Форма работы',
  kpp = 'КПП',
  okato = 'ОКАТО',
  okpo = 'ОКПО',
  files = 'Устав, Доверенность, иные документы',
  certificateElectricalSafetyScan = 'Удостоверения по электробезопасности',
  certificateWorkHeightScan = 'Удостоверения на допуск к работам на высоте',
  certificateElectricalSafetyQuantity = 'Количество сотрудников с сертификатом',
  certificateWorkHeightQuantity = 'Количество сотрудников с сертификатом',
}

export const subcontractorModerationRequestTitle: {
  [p: number]: ESubcontractorModerationRequestStatusName;
} = {
  [ESubcontractorModerationRequestStatusId.Draft]: ESubcontractorModerationRequestStatusName.Draft,
  [ESubcontractorModerationRequestStatusId.SendToVerification]:
    ESubcontractorModerationRequestStatusName.SendToVerification,
  [ESubcontractorModerationRequestStatusId.ElaborationRequest]:
    ESubcontractorModerationRequestStatusName.ElaborationRequest,
  [ESubcontractorModerationRequestStatusId.ElaborationProvided]:
    ESubcontractorModerationRequestStatusName.ElaborationProvided,
  [ESubcontractorModerationRequestStatusId.Signing]:
    ESubcontractorModerationRequestStatusName.Signing,
  [ESubcontractorModerationRequestStatusId.Registered]:
    ESubcontractorModerationRequestStatusName.Registered,
  [ESubcontractorModerationRequestStatusId.Refused]:
    ESubcontractorModerationRequestStatusName.Refused,
};

export interface FormHandlers {
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  errors: FieldErrors;
}

type ControlConfig = {
  name: string;
  label: string;
  mask: string;
  length: number;
  validationMessage: string;
};

export const SubcontractorFormControls: { [p: string]: ControlConfig } = {
  okato: {
    name: 'okato',
    label: 'okato',
    mask: '99999999999',
    length: 11,
    validationMessage: '',
  },
};
