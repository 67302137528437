import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useFirebase } from 'hooks/useFirebase';
import { useNavigationHistory } from 'navigationProvider';
import { User as oidcUser } from 'oidc-client';
import { AuthProvider } from 'oidc-react';
import { AuthProviderProps } from 'oidc-react/build/src/AuthContextInterface';
import { useQuery } from 'react-query';
import { useNavigate, useRoutes } from 'react-router-dom';
import { profileActions } from 'reduxStore/reducers/ProfileSlice';
import { getExecutorType } from 'reduxStore/reducers/ProfileSlice/selectors/getExecutorType';
import { createRouter } from 'routes';
import { getProfile } from 'services';
import { ProfileService } from 'services/ProfileService';
import { useOrderRoute } from './hooks/useOrderRoute';
import { oidcSettings } from './utils/settings';

function App() {
  const { clearHistory } = useNavigationHistory();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const executorType = useAppSelector(getExecutorType);
  const routing = useRoutes(createRouter(executorType));

  const { mainUrl } = useOrderRoute();

  useQuery(['getProfile'], () => ProfileService.getProfile(), {
    onSuccess: async (data) => {
      dispatch(profileActions.setProfile(data.data));
    },
  });

  const onSignIn = async (userData: oidcUser | null) => {
    try {
      userData && sessionStorage.setItem('token', userData.access_token);
      clearHistory();
      await getPermissions();
      const response = await getProfile();
      if (response.status === 200) {
        dispatch(profileActions.setProfile(response.data));
        navigate(mainUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onSignOut = () => {
    sessionStorage.clear();
  };

  // Получение разрешений пользователя
  const getPermissions = async () => {
    const response = await request<number[]>(
      endpoints.GET_USER_PERMISSIONS.type,
      endpoints.GET_USER_PERMISSIONS.url()
    );
    if (response.status === 200) {
      dispatch(profileActions.setPermissions(response.data));
    }
  };

  const settings: AuthProviderProps = {
    ...oidcSettings,
    onSignIn,
    onSignOut,
  };

  useFirebase();

  return <AuthProvider {...settings}>{routing}</AuthProvider>;
}
export default App;
