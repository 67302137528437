import { ChangeEvent } from 'react';
import { Controller, Control, FieldErrors, RegisterOptions } from 'react-hook-form';
import useFieldArrayControl from 'hooks/useFieldErrorMessage';
import { Textarea } from 'ui/Textarea';

interface FieldTextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label: string;
  control: Control<any>;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  errors?: {
    [name: string]: FieldErrors;
  };
  rules?: RegisterOptions;
}

export const FieldTextarea = ({
  name,
  label,
  control,
  errors,
  onChange,
  rules,
  ...other
}: FieldTextareaProps) => {
  const errorMessage = useFieldArrayControl(name, errors);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Textarea
          label={label}
          errorMessage={errorMessage}
          value={field.value}
          onChange={(val) => {
            field.onChange(val);
            onChange && onChange(val);
          }}
          {...other}
        />
      )}
    />
  );
};
