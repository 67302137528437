import { useAppSelector } from 'hooks/useAppSelector';
import useMediaQuery from 'hooks/useMediaQuery';
import { Outlet } from 'react-router-dom';
import { getProfileState } from 'reduxStore/reducers/ProfileSlice/selectors/getProfileState';
import { IS_MEDIUM_DEVICE } from 'utils/settings';
import { Aside } from './Aside';
import { SIDEBAR_OPEN_LOCALSTORAGE_NAME } from './Aside/constants';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { profileActions } from 'reduxStore/reducers/ProfileSlice';

export const Layout = () => {
  const isMedium = useMediaQuery(IS_MEDIUM_DEVICE);
  const { isSidebarOpen } = useAppSelector(getProfileState);
  const dispatch = useAppDispatch();

  const handleToggleOpenSidebar = (): void => {
    if (isSidebarOpen) {
      localStorage.removeItem(SIDEBAR_OPEN_LOCALSTORAGE_NAME);
    } else {
      localStorage.setItem(SIDEBAR_OPEN_LOCALSTORAGE_NAME, 'true');
    }
    dispatch(profileActions.toggleSidebar(!isSidebarOpen));
  };

  return (
    <div className="lg:gradient-0 mt-0 flex text-text-100 lg:mt-0 lg:pt-5">
      <Aside />
      {isMedium && isSidebarOpen && (
        <div
          className="absolute top-0 z-40 h-screen w-screen bg-gray-dark bg-opacity-40"
          onClick={handleToggleOpenSidebar}
        ></div>
      )}
      <main
        className={
          'hide-scrollbar relative box-border h-layout flex-grow overflow-y-auto rounded-tl-[1.875rem] bg-base p-4 sm:h-layout lg:px-10 lg:pt-10 lg:pb-1'
        }
      >
        <Outlet />
      </main>
    </div>
  );
};
