/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th><th>Общее название</th></thead><tbody><tr><td>1</td><td>ITEngineer</td><td>ИТ – инженер</td></tr><tr><td>2</td><td>InstallationWork</td><td>Монтажные работы</td></tr><tr><td>3</td><td>Delivery</td><td>Доставка</td></tr><tr><td>4</td><td>Cleaning</td><td>Клининг</td></tr></tbody></table></summary>
 */
export enum Common_SharedEnums_ExecutorSpecializationEnum {
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
}
