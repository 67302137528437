import { Controller } from 'react-hook-form';
import { UploadFileInput } from 'ui/redesign/UploadFileInput';
import { FieldUploadInputProps } from './types';

export const FieldUploadFileInput = ({
  name,
  control,
  errorMessage,
  rules,
  onChange,
  fileBusinessTypeId,
  deleteLink,
  label,
  maxFileSize,
  disabled,
  uploadLimit,
  info,
  otherTextInfo,
  className,
  isHideDeleteFileButton,
  isHidePreviewFileButton,
  isHideDownloadFileButton,
  clearErrors,
  filters,
  acceptExtensions,
  disableNavigate,
}: FieldUploadInputProps) => {
  return (
    <div className="flex flex-col">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <UploadFileInput
              name={name}
              fileBusinessTypeId={fileBusinessTypeId}
              deleteLink={deleteLink}
              clearErrors={clearErrors}
              onChange={(val) => {
                field.onChange(val);
                onChange && onChange(val);
                if (name.split('.')[2] === 'files') {
                  clearErrors && clearErrors();
                }
                clearErrors && clearErrors(name);
              }}
              label={label}
              maxFileSize={maxFileSize}
              errorMessage={errorMessage}
              value={field.value}
              disabled={disabled}
              info={info}
              uploadLimit={uploadLimit}
              otherTextInfo={otherTextInfo}
              className={className}
              isHideDeleteFileButton={isHideDeleteFileButton}
              isHidePreviewFileButton={isHidePreviewFileButton}
              isHideDownloadFileButton={isHideDownloadFileButton}
              filters={filters}
              acceptExtensions={acceptExtensions}
              disableNavigate={disableNavigate}
            />
          );
        }}
      />
    </div>
  );
};
