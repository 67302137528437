import { FC } from 'react';
import { FormWrapperProps } from './types';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { formattedDate } from 'utils/helpers/formatDate.helper';
import { moderationRequestTitle } from 'pages/ModerationRequest/types';
import { ProfilePermissions } from 'reduxStore/reducers/ProfileSlice/types';
import classNames from 'classnames';

export const FormWrapper: FC<FormWrapperProps> = ({ children, status, created, title, Icon }) => {
  const isSubcontractor = JSON.parse(sessionStorage.getItem('permissions') || 'null').includes(
    ProfilePermissions.VerificationQuestionary_Create
  );
  return (
    <PageWrapper title="Заявка на модерацию">
      <div
        className={classNames('hide-scrollbar flex flex-col gap-6 overflow-y-auto', {
          '!pb-0': isSubcontractor,
        })}
      >
        <div>
          <p className="callout-normal sm:body-normal !text-text-50">Статус:</p>
          <p className="body-bold sm:headline-bold !text-primary-60">
            {moderationRequestTitle[status]}
          </p>
        </div>
        {!isSubcontractor && (
          <div>
            <p className="callout-normal sm:body-normal !text-text-50">Дата регистрации:</p>
            <p className="body-bold sm:headline-bold !text-text-100">
              {created && formattedDate(created)}
            </p>
          </div>
        )}
        <div className="flex items-center">
          <Icon />
          <span className="body-bold sm:title2-bold ml-3.5">{title}</span>
        </div>
        <section>{children}</section>
      </div>
    </PageWrapper>
  );
};
