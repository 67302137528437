/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * <details><summary>Таблица статусов</summary><table><thead><th>Id</th><th>Внутреннее название</th></thead><tbody><tr><td>1</td><td>View</td></tr><tr><td>2</td><td>Edit</td></tr></tbody></table></summary>
 */
export enum SpsManagement_SharedEnums_SpsRequestStateEnum {
    '_1' = 1,
    '_2' = 2,
}
