import { FC } from 'react';
import { PaymentOrderEnum, RefuseReasonEnum } from './types';
import { CanceledIcon, CheckApproovedIcon, WaitingApproveIcon, WaitingIcon } from 'assets/icons';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export const months = [
  { label: 'Январь', value: 1, infoValue: '01' },
  { label: 'Февраль', value: 2, infoValue: '02' },
  { label: 'Март', value: 3, infoValue: '03' },
  { label: 'Апрель', value: 4, infoValue: '04' },
  { label: 'Май', value: 5, infoValue: '05' },
  { label: 'Июнь', value: 6, infoValue: '06' },
  { label: 'Июль', value: 7, infoValue: '07' },
  { label: 'Август', value: 8, infoValue: '08' },
  { label: 'Сентябрь', value: 9, infoValue: '09' },
  { label: 'Октябрь', value: 10, infoValue: '10' },
  { label: 'Ноябрь', value: 11, infoValue: '11' },
  { label: 'Декабрь', value: 12, infoValue: '12' },
];

export const currentYear = new Date().getFullYear();
export const currentMonth = new Date().getMonth();

export const receiptsFilterDefaultValues = {
  year: { label: currentYear, value: currentYear },
  month: { label: months[currentMonth].label, value: months[currentMonth].value },
};

export const years1S = [...new Array(2)]
  .map((_, index) => {
    return {
      label: String(currentYear - index),
      value: currentYear - index,
    };
  })
  .reverse();

let startYear = 2023;
export const yearsByPeriod: { label: string; value: number }[] = [];

while (startYear <= currentYear) {
  yearsByPeriod.push({
    label: String(startYear),
    value: startYear,
  });

  startYear++;
}

interface StatusIconProps {
  status: PaymentOrderEnum;
  withoutText?: boolean;
}

export const StatusIcon: FC<StatusIconProps> = ({ status, withoutText = false }) => {
  switch (status) {
    case 3:
      return (
        <div className="flex items-center gap-2">
          {!withoutText && <span className="headline-normal !text-success-60">Чек принят</span>}
          <CheckApproovedIcon className="h-5 w-5 self-center" />
        </div>
      );
    case 4:
      return (
        <div className="flex items-center gap-2">
          {!withoutText && (
            <span className="sm:headline-normal headline-normal !text-danger">Чек не принят</span>
          )}
          <CanceledIcon className="h-5 w-5 self-center" />
        </div>
      );
    case 1:
      return (
        <div className="flex items-center gap-2">
          {!withoutText && (
            <span className="sm:headline-normal headline-normal !text-warning">
              Ожидает загрузки
            </span>
          )}
          <WaitingIcon className="h-5 w-5 self-center !text-warning" />
        </div>
      );
    case 2:
      return (
        <div className="flex items-center gap-2">
          {!withoutText && (
            <span className="sm:headline-normal headline-normal !text-[#18AAD2]">
              Ожидает проверки
            </span>
          )}
          <WaitingApproveIcon className="h-5 w-5 self-center" />
        </div>
      );
    default:
      return null;
  }
};

export function formatDate(dateString: string) {
  const date = new Date(dateString);
  return format(date, 'd MMMM yyyy', { locale: ru });
}

export function getReason(value: number) {
  const key = Object.keys(RefuseReasonEnum).find(
    (key: any) => RefuseReasonEnum[key] == value.toString()
  );
  return key;
}
