import { useAppDispatch } from 'hooks/useAppDispatch';
import { FC, useState } from 'react';
import { Calendar } from 'react-date-range';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { Button } from '../Button';
import { ButtonVariant } from '../Button/constants';
import { maxDateDefault, ruLocale } from './constants';
import { CalendarModalProps } from './types';
import { format } from 'date-fns';

export const CalendarModal: FC<CalendarModalProps> = ({ onChange, maxDate, value }) => {
  const dispatch = useAppDispatch();
  const [date, setDate] = useState<Date | null>(value ? value : null);

  const onChangeHandler = (value?: Date | null) => {
    value === undefined ? setDate(null) : setDate(value);
    onChange && onChange(value);
  };

  return (
    <div className="mx-auto flex h-[95vh] w-full max-w-[400px] flex-col items-center justify-center">
      <div className="body-bold flex w-full justify-between bg-primary-60 p-4 !text-white">
        <p>Выберите дату</p>
        <p>{date && format(date, 'dd.MM.yyyy')}</p>
      </div>
      <Calendar
        months={1}
        editableDateInputs={true}
        onChange={onChangeHandler}
        direction="vertical"
        scroll={{ enabled: false, calendarHeight: 250 }}
        locale={ruLocale}
        dateDisplayFormat="dd.MM.yyyy"
        startDatePlaceholder="дд.мм.гггг"
        endDatePlaceholder="дд.мм.гггг"
        weekdayDisplayFormat="EEEEEE"
        showMonthArrow={true}
        displayMode="date"
        date={date ? date : undefined}
        maxDate={maxDate || maxDateDefault}
      />
      <div className="flex w-full justify-end bg-white">
        <Button
          variant={ButtonVariant.GHOST}
          onClick={() => dispatch(modalActions.closeModal())}
          className="!callout-medium !w-10 !text-primary-60"
        >
          ОТМЕНА
        </Button>
        <Button
          onClick={() => dispatch(modalActions.closeModal())}
          className="!callout-medium !w-10 !text-primary-60"
          variant={ButtonVariant.GHOST}
        >
          ВЫБРАТЬ
        </Button>
      </div>
    </div>
  );
};
