import { Controller, Control, FieldErrors, RegisterOptions } from 'react-hook-form';

import { RadioGroup, RadioGroupProps } from 'ui/RadioGroup';

interface FieldRadioGroupProps extends RadioGroupProps {
  name: string;
  control: Control<any>;
  errors?: {
    [name: string]: FieldErrors;
  };
  rules?: RegisterOptions;
}

export const FieldRadioGroup = ({
  data,
  name,
  label,
  control,
  errors,
  rules,
  onChange,
  disabled,
}: FieldRadioGroupProps) => {
  const errorMessage = errors && errors[name] ? errors[name]?.message : '';
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <RadioGroup
            label={label}
            errorMessage={errorMessage}
            data={data}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
              onChange && onChange(val);
            }}
            disabled={disabled}
          />
        );
      }}
    />
  );
};
