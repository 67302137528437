import React from 'react';
import { ReactComponent as EyeIcon } from 'assets/eye.svg';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { ReactComponent as PdfIcon } from 'assets/pdf-icon.svg';
import { ReactComponent as ImageIcon } from 'assets/image-icon.svg';
import { OrderFile } from 'types';

interface FileProps {
  file: OrderFile;
  onDownload?: () => void;
  onPreview?: () => void;
  onDelete?: () => void;
  disabled?: boolean;
  short?: boolean;
  hideDelFileButton?: boolean;
}

export const File = ({
  file,
  onDownload,
  onPreview,
  onDelete,
  disabled = false,
  short = false,
  hideDelFileButton = false,
}: FileProps) => {
  const Icon = ({ fileName }: { fileName: string }) => {
    const fileType = fileName.match(/\.[0-9a-z]+$/i);
    const extension = fileType && fileType[0];
    const classes = `${
      short ? 'w-[14px] h-[14px]' : 'w-5 h-5'
    } flex justify-center items-center mr-2 shrink-0`;
    switch (extension?.toLowerCase()) {
      case '.jpg':
      case '.jpeg':
      case '.png':
        return (
          <div className={classes}>
            <ImageIcon />
          </div>
        );
      case '.pdf':
        return (
          <div className={classes}>
            <PdfIcon />
          </div>
        );
      default:
        return (
          <div className={classes}>
            <PdfIcon />
          </div>
        );
    }
  };

  const hideDelFileBtn = hideDelFileButton && !file.deletedFileIdHash;

  return (
    <div
      className="mb-1 flex items-center justify-between last-of-type:mb-0"
      key={file.downloadFileIdHash}
    >
      <Icon fileName={file.fileName} />
      <span
        className={`${
          short ? 'callout-normal !text-text-100' : 'callout-normal !text-text-100'
        } grow`}
      >
        {file.fileName}
      </span>
      <div className={`flex ${short ? 'gap-2' : 'gap-4'} items-center`}>
        {onDownload && (
          <button
            type="button"
            className={`${
              short ? 'h-[14px] w-[14px]' : 'h-5 w-5'
            } flex cursor-pointer items-center justify-center`}
            onClick={onDownload}
          >
            <DownloadIcon className="text-blue" />
          </button>
        )}
        {onPreview && (
          <button
            type="button"
            className={`${
              short ? 'h-[14px] w-[14px]' : 'h-5 w-5'
            } hidden items-center justify-center md:flex`}
            onClick={onPreview}
          >
            <EyeIcon />
          </button>
        )}
        {onDelete && !hideDelFileBtn && (
          <button
            type="button"
            className={`${
              short ? 'h-[14px] w-[14px]' : 'h-5 w-5'
            } flex items-center justify-center disabled:cursor-not-allowed`}
            onClick={onDelete}
            disabled={disabled}
          >
            <CloseIcon className={`${disabled ? 'text-red/[.3]' : 'text-red'}`} />
          </button>
        )}
      </div>
    </div>
  );
};
