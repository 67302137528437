import { CloseIcon, IconCalendar } from 'assets/icons';
import { format } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { useAppDispatch } from 'hooks/useAppDispatch';
import useMediaQuery from 'hooks/useMediaQuery';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { IS_SMALL_DEVICE } from 'utils/settings';
import { Input } from '../Input';
import { CalendarModal } from './CalendarModal';
import { maxDateDefault, variants } from './constants';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';
import { DatePickerProps } from './types';
import { editFilterRuLocale } from '../DateRangePicker/constants';

export const DatePicker: FC<DatePickerProps> = ({
  value,
  label,
  errorMessage,
  onChange,
  maxDate,
  disabled,
}) => {
  const ruLocale = editFilterRuLocale();
  const [date, setDate] = useState<Date | null>(value ? value : null);

  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);
  const dispatch = useAppDispatch();

  const calendarRef = useRef(null);
  const inputRef = useRef(null);

  const clickEventListenerHandler = useCallback(
    (event: Event) => {
      const element = event.target as HTMLElement | null;
      if (element) {
        const calRef = element.closest('.calendarRef');
        const inRef = element.closest('.inputRef');
        if (calRef !== calendarRef.current && inRef !== inputRef.current && isOpenCalendar) {
          setIsOpenCalendar(false);
        }
      }
    },
    [isOpenCalendar, setIsOpenCalendar, calendarRef.current, inputRef.current]
  );

  const onChangeHandler = (value?: Date | null) => {
    value === undefined ? setDate(null) : setDate(value);
    onChange && onChange(value);
    value && setIsOpenCalendar(false);
  };

  const openCalendarHandler = () => {
    if (isSmall) {
      dispatch(
        modalActions.handleOpenModal({
          content: <CalendarModal value={value} onChange={onChange} maxDate={maxDate} />,
          options: {
            withCloseButton: false,
            modalClassName: 'w-full bg-[#04040F] bg-opacity-10 px-4',
            isTransparent: true,
          },
        })
      );
    } else {
      setIsOpenCalendar((prev) => !prev);
    }
  };

  useEffect(() => {
    document.addEventListener('click', clickEventListenerHandler);
    return () => {
      document.removeEventListener('click', clickEventListenerHandler);
    };
  }, [clickEventListenerHandler]);

  return (
    <div className="inputRef relative" ref={inputRef}>
      <Input
        icons={[
          {
            icon: <CloseIcon className="-ml-16 text-text-50 hover:text-text-100" />,
            onClick: () => {
              onChange && onChange(null);
              setIsOpenCalendar(false);
            },
          },
          {
            icon: <IconCalendar className="-ml-7 text-text-50 hover:text-text-100" />,
            onClick: disabled ? undefined : openCalendarHandler,
          },
        ]}
        label={label}
        value={!value ? '' : isNaN(Date.parse(value as any)) ? '' : format(value, 'dd.MM.yyyy')}
        errorMessage={errorMessage}
        onChange={(value) => value === undefined && onChange && onChange(value)}
        onClick={disabled ? undefined : openCalendarHandler}
        disabled={disabled}
      />
      <AnimatePresence>
        {isOpenCalendar && (
          <motion.div
            variants={variants}
            initial="initial"
            animate="animate"
            exit="initial"
            transition={{ duration: 0.33 }}
            className="calendarRef absolute top-full left-0 z-10 !mt-0 w-full"
            ref={calendarRef}
          >
            <Calendar
              months={2}
              editableDateInputs={true}
              onChange={onChangeHandler}
              direction="vertical"
              scroll={{ enabled: true, calendarHeight: 250 }}
              locale={ruLocale}
              dateDisplayFormat="dd.MM.yyyy"
              startDatePlaceholder="дд.мм.гггг"
              endDatePlaceholder="дд.мм.гггг"
              weekdayDisplayFormat="EEEEEE"
              showMonthArrow={false}
              displayMode="date"
              maxDate={maxDate || maxDateDefault}
              date={date ? date : undefined}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
