import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Statuses } from 'types/status';
import { Order } from 'types';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { styles } from './consts';
import useMediaQuery from 'hooks/useMediaQuery';
import { IS_SMALL_DEVICE } from 'utils/settings';

interface DeclineStatusFormProps {
  order: Order | undefined;
  newStatus: number;
  onClose: () => void;
  onStatusChange: (formData: Record<string, never>) => void;
}

export const RefuseStatusForm = ({
  order,
  newStatus,
  onClose,
  onStatusChange,
}: DeclineStatusFormProps) => {
  const { handleSubmit } = useForm<Record<string, never>>();
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);

  const submitFormHandler: SubmitHandler<Record<string, never>> = (values) => {
    onStatusChange(values);
  };

  return (
    <form onSubmit={handleSubmit(submitFormHandler)}>
      {isSmall ? (
        <div className="mb-4 flex h-full flex-col justify-between gap-4">
          <div className="flex-1 self-start">
            <p className={styles.label}>Текущий статус</p>
            {order && (
              <p className="title2-bold mb-4 text-text-100">{Statuses[order.status]?.name}</p>
            )}
            <p className={styles.label}>Новый статус заявки</p>
            <p className="title2-bold text-primary-60">{Statuses[newStatus].name}</p>
          </div>
          <div className="fixed bottom-0 mb-10 w-[90%] pr-8 sm:mb-0">
            <Button
              className="btn-base mb-6 w-full max-w-[640px] bg-primary-60 text-white hover:bg-primary-80 active:bg-primary-100 disabled:bg-primary-40"
              type="submit"
            >
              СОХРАНИТЬ
            </Button>
            <Button
              variant={ButtonVariant.OUTLINE}
              className="btn-ghost w-full max-w-[640px] self-center"
              type="button"
              onClick={onClose}
            >
              ОТМЕНА
            </Button>
          </div>
        </div>
      ) : (
        <div className="mb-4 flex flex-col items-center justify-between gap-4 bg-white">
          <div className="mt-2 flex w-full justify-between">
            <div>
              <p className={styles.label}>Текущий статус</p>
              {order && <p className="title2-bold text-text-100">{Statuses[order.status]?.name}</p>}
            </div>
            <div>
              <p className={styles.label}>Новый статус заявки</p>
              <p className="title2-bold text-primary-60">{Statuses[newStatus]?.name}</p>
            </div>
          </div>
          <div className="flex w-full justify-end gap-3">
            <Button variant={ButtonVariant.OUTLINE} onClick={onClose} type="button">
              ОТМЕНА
            </Button>
            <Button type="submit">СОХРАНИТЬ</Button>
          </div>
        </div>
      )}
    </form>
  );
};
