import { FieldRadioGroup } from 'components/FieldRadioGroup';
import { FieldInput } from 'components/redesign/FieldInput';
import { FieldUploadFileInput } from 'components/redesign/FieldUploadFileInput';
import { FileBusinessType } from 'enums/fileBusinessType';
import { OrganizationType } from 'enums/organizationType';
import { ESubcontractorModerationRequestStatusId } from 'enums/subcontractorModerationRequestStatus';
import { ESubcontractorModerFormName } from 'pages/ModerationRequest/types';
import { FC } from 'react';
import { vatRadio } from './consts';
import { Step3Props } from './types';
import { FieldTextarea } from 'components/redesign/FieldTextArea';

export const Step3Fields: FC<Step3Props> = ({
  control,
  isFormDisabled,
  errors,
  clearErrors,
  organizationTypeId,
  moderationRequestData,
  checkFieldInChecklist,
}) => {
  return (
    <div className="flex flex-col gap-8 sm:grid sm:grid-cols-[minmax(0,600px)_minmax(0,600px)]">
      <FieldInput
        name="bankSettlement"
        control={control}
        label="Расчетный счет"
        isClearable
        disabled={
          moderationRequestData?.statusId ===
          ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? checkFieldInChecklist &&
              checkFieldInChecklist(
                moderationRequestData,
                ESubcontractorModerFormName.bankSettlement
              )
            : isFormDisabled
        }
        errorMessage={errors?.bankSettlement?.message}
        mask="99999999999999999999"
        clearErrors={clearErrors}
      />
      <FieldTextarea
        name="bankName"
        control={control}
        label="Полное наименование Банка"
        isClearable
        disabled={
          moderationRequestData?.statusId ===
          ESubcontractorModerationRequestStatusId.ElaborationRequest
            ? checkFieldInChecklist &&
              checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.bankName)
            : isFormDisabled
        }
        errorMessage={errors?.bankName?.message}
        maxLength={150}
        clearErrors={clearErrors}
      />
      {organizationTypeId === OrganizationType.LLC && (
        <>
          <FieldInput
            name="bik"
            control={control}
            label="БИК"
            isClearable
            disabled={
              moderationRequestData?.statusId ===
              ESubcontractorModerationRequestStatusId.ElaborationRequest
                ? checkFieldInChecklist &&
                  checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.bik)
                : isFormDisabled
            }
            errorMessage={errors?.bik?.message}
            mask="999999999"
            clearErrors={clearErrors}
          />
          <FieldInput
            name="kpp"
            control={control}
            label="КПП"
            isClearable
            disabled={
              moderationRequestData?.statusId ===
              ESubcontractorModerationRequestStatusId.ElaborationRequest
                ? checkFieldInChecklist &&
                  checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.kpp)
                : isFormDisabled
            }
            errorMessage={errors?.kpp?.message}
            mask="999999999"
            clearErrors={clearErrors}
          />
          <FieldInput
            name="okato"
            control={control}
            label="ОКАТО"
            isClearable
            disabled={
              moderationRequestData?.statusId ===
              ESubcontractorModerationRequestStatusId.ElaborationRequest
                ? checkFieldInChecklist &&
                  checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.okato)
                : isFormDisabled
            }
            errorMessage={errors?.okato?.message}
            mask="99999999999"
            clearErrors={clearErrors}
          />
          <FieldInput
            name="okpo"
            control={control}
            label="ОКПО"
            isClearable
            disabled={
              moderationRequestData?.statusId ===
              ESubcontractorModerationRequestStatusId.ElaborationRequest
                ? checkFieldInChecklist &&
                  checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.okpo)
                : isFormDisabled
            }
            errorMessage={errors?.okpo?.message}
            mask="99999999"
            clearErrors={clearErrors}
          />
        </>
      )}
      <div>
        {organizationTypeId === OrganizationType.Individual && (
          <FieldInput
            name="bik"
            control={control}
            label="БИК"
            isClearable
            disabled={
              moderationRequestData?.statusId ===
              ESubcontractorModerationRequestStatusId.ElaborationRequest
                ? checkFieldInChecklist &&
                  checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.bik)
                : isFormDisabled
            }
            errorMessage={errors?.bik?.message}
            mask="999999999"
            clearErrors={clearErrors}
          />
        )}
        <div className="my-4">
          <span className="headline-bold sm:title2-bold">Форма работы</span>
        </div>
        <FieldRadioGroup
          name={ESubcontractorModerFormName.withVat}
          control={control}
          errors={errors}
          data={vatRadio}
          disabled={
            moderationRequestData?.statusId ===
            ESubcontractorModerationRequestStatusId.ElaborationRequest
              ? checkFieldInChecklist &&
                checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.withVat)
              : isFormDisabled
          }
        />
        <div className="my-4">
          <span className="body-bold sm:title2-bold">Устав, Доверенность, Иные документы</span>
        </div>
        <FieldUploadFileInput
          name={'files'}
          control={control}
          errorMessage={(errors as any)?.files?.message}
          disabled={
            moderationRequestData?.statusId ===
            ESubcontractorModerationRequestStatusId.ElaborationRequest
              ? checkFieldInChecklist &&
                checkFieldInChecklist(moderationRequestData, ESubcontractorModerFormName.files)
              : isFormDisabled
          }
          fileBusinessTypeId={FileBusinessType.OtherSubcontractorDocs}
          clearErrors={clearErrors}
          uploadLimit={10}
        />
      </div>
    </div>
  );
};
