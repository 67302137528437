import { request } from 'api';
import { OrderFile, OrderFileContent } from 'types';

export const FileService = {
  uploadFile(formData: FormData) {
    return request<OrderFile[]>('post', '/api/v1/File', { data: formData });
  },
  fetchFile(hash: string) {
    return request<OrderFileContent>('get', `/api/v1/File/Download/${hash}`);
  },
  deleteFile(hash: string) {
    return request<void>('delete', `/api/v1/File/Delete/${hash}`);
  },
};
