import UploadHintImg from 'assets/images/upload-hint.png';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';

export const UploadHint = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="p-4 sm:p-0">
      <div className="body-normal flex flex-col gap-4 !text-text-100">
        <img
          src={UploadHintImg}
          alt="Страница не найдена"
          className="mx-auto block h-[281px] w-[281px]"
        />
        <p>1. Ваше лицо и фотография в паспорте должны быть хорошо видны</p>
        <p>2. Текст должен хорошо читаться, не заслоняйте поля пальцами</p>
        <p>3. Снимок должен быть качественным и четким, без размытия</p>
        <p>
          4. Разрешается наложение на фото водяных знаков и полупрозрачных трафаретов, при этом все
          данные должны оставаться читаемыми
        </p>
        <Button
          variant={ButtonVariant.OUTLINE}
          className="w-[140px mx-auto hidden sm:block"
          onClick={() => dispatch(modalActions.closeModal())}
        >
          Закрыть
        </Button>
      </div>
    </div>
  );
};
