import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalWindowState } from './types';

const initialState: ModalWindowState = {
  isOpen: false,
  options: null,
  filters: null,
  content: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    handleOpenModal: (
      state,
      action: PayloadAction<{
        content: ModalWindowState['content'];
        options?: ModalWindowState['options'];
      }>
    ) => {
      state.isOpen = true;
      state.content = action.payload.content;
      state.options = action.payload.options;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.content = null;
      state.options = null;
    },
    saveFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
  },
});

export const { reducer: modalReducer, actions: modalActions } = modalSlice;
