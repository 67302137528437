import { useQuery } from 'react-query';
import { AxiosRequestConfig, Method } from 'axios';
import { request } from 'api';

export const useFetchData = <T = any>(
  endpoint: string,
  method: Method = 'GET',
  options: AxiosRequestConfig = {},
  queryOptions = {}
) => {
  const { isLoading, error, data, isError, isFetching, refetch } = useQuery(
    endpoint,
    () => request<T>(method, endpoint, options),
    { ...queryOptions }
  );

  return { data: data?.data, error, isLoading, isError, isFetching, refetch };
};
