import { UseFormSetError } from 'react-hook-form';
import { RequestHandler } from '../finalStep/consts';
import { Education, WorkExperience } from 'services/ModerationRequestService/types';

export const checkExperience = (
  experience: WorkExperience | Education,
  index: number,
  requestHandler: RequestHandler,
  toastErrors: string[],
  setError: UseFormSetError<any>
) => {
  let name = '';
  const fileErrorMessage = 'Необходимо приложить 1 или более файлов';
  const descriptionErrorMessage = 'Описание должно быть заполнено';

  if ('isPrimary' in experience) {
    name = experience.isPrimary ? 'primaryEducation' : 'secondaryEducation';
  } else {
    name = 'workExperiences';
  }

  if (experience?.description && !experience?.files?.length) {
    const fullName = `${name}${index}files`;

    setError(fullName, {
      type: 'manual',
      message: fileErrorMessage,
    });
    toastErrors.push(fileErrorMessage);
  }

  if (!experience?.description && experience?.files?.length) {
    const fullName = `${name}${index}description`;
    setError(fullName, {
      type: 'manual',
      message: descriptionErrorMessage,
    });
    toastErrors.push(descriptionErrorMessage);
  }

  if (requestHandler === RequestHandler.MODERATION) {
    if (!experience?.description && !experience?.files?.length) {
      const fullName = `${name}${index}files`;

      setError(fullName, {
        type: 'manual',
        message: fileErrorMessage,
      });
      toastErrors.push(fileErrorMessage);
    }

    if (!experience?.description && !experience?.files?.length) {
      const fullName = `${name}${index}description`;
      setError(fullName, {
        type: 'manual',
        message: descriptionErrorMessage,
      });
      toastErrors.push(descriptionErrorMessage);
    }
  }
  return toastErrors;
};
