import { FC } from 'react';
import { FieldCheckboxProps } from './types';
import { Controller } from 'react-hook-form';
import { Checkbox } from 'ui/redesign/Checkbox';

export const FieldCheckbox: FC<FieldCheckboxProps> = ({
  name,
  control,
  rules,
  onChange,
  errorMessage,
  clearErrors,
  disabled,
  ...other
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const onChangeHandle = (value: React.ChangeEvent<HTMLInputElement>) => {
          const actualValue = value.target.checked;
          field.onChange(actualValue);
          onChange && onChange(actualValue);
          clearErrors && clearErrors(name);
        };
        return (
          <Checkbox
            checked={field.value}
            onChange={onChangeHandle}
            errorMessage={errorMessage}
            disabled={disabled}
            {...other}
          />
        );
      }}
    />
  );
};
