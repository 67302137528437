export const OrderActions: Record<number, { id: number; label: string }> = {
  7: {
    id: 7,
    label: 'Взять в работу',
  },
  12: {
    id: 12,
    label: 'Выполнена',
  },
  13: {
    id: 13,
    label: 'Отменена',
  },
  14: {
    id: 14,
    label: 'Отказаться',
  },
  22: {
    id: 22,
    label: 'Отмена заявки',
  },
};
