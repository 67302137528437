import { useCallback, useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import { Controller, useForm } from 'react-hook-form';

import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { FieldInput } from 'components/redesign/FieldInput';
import { FieldSelect } from 'components/redesign/FieldSelect';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useFetchData } from 'hooks/useFetchData';
import useMediaQuery from 'hooks/useMediaQuery';
import { modalActions } from 'reduxStore/reducers/ModalSlice';
import { EntityShort, Settlement } from 'types';
import { OptionType } from 'ui/Select';
import { Button } from 'ui/redesign/Button';
import { ButtonVariant } from 'ui/redesign/Button/constants';
import { DateRangePicker } from 'ui/redesign/DateRangePicker';
import { transformToSelectOptions } from 'utils/helpers';
import { IS_SMALL_DEVICE } from 'utils/settings';
import { OrderTypesEnum } from '../enums';
import { CommonSettlementsResponse } from './types';

export interface OrderFilters {
  cities: any[] | null;
  customId: string;
  create_date: Range;
  clients: any[] | null;
  areDependentObjectsIncluded: boolean | null;
}

type Filter = keyof OrderFilters;

interface FilterProps {
  onFilter: (filters: OrderFilters) => void;
  orderTabType: OrderTypesEnum;
  prevFilters: any;
}
export const defaultFilters: any = {
  cities: [],
  customId: '',
  create_date: {},
  clients: [],
};

export const OrderFiltersModal = ({ onFilter, orderTabType, prevFilters }: FilterProps) => {
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState<OrderFilters>({ ...prevFilters });
  const [openCalendar, setOpenCalendar] = useState(false);
  const [cities, setCities] = useState<Settlement[]>([]);
  const dispatch = useAppDispatch();
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);
  const { data: projects } = useFetchData<EntityShort[]>(endpoints.GET_LEGAL_ENTITIES_SHORT.url());
  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      cities: filters.cities,
      customId: filters.customId,
      create_date: filters.create_date,
      clients: filters.clients,
    },
  });

  useEffect(() => {
    getCities('');
  }, []);

  const getCities = async (template: string) => {
    const response = await request<CommonSettlementsResponse>(
      endpoints.GET_COMMON_SETTLEMENTS.type,
      endpoints.GET_COMMON_SETTLEMENTS.url(template)
    );
    if (response.status === 200) {
      setCities(response.data.pagedList);
    }
  };

  const activeFiltersLength = Object.values(filters).filter(
    (item) => item && ((Boolean(item) && item.length) || Object.keys(item).length || item > 0)
  ).length;

  const onShowFilters = () => {
    document.body.classList.add('fixed-container');
    setOpen(true);
  };

  const onCloseFilters = () => {
    document.body.classList.remove('fixed-container');
    setOpen(false);
    dispatch(modalActions.closeModal());
  };

  const onChangeValueHandler = (value: OrderFilters[Filter], name: Filter) => {
    setFilters((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onResetFilter = useCallback(() => {
    reset(defaultFilters);
    setFilters(defaultFilters);
    onFilter(defaultFilters);
  }, [onFilter, reset]);

  const submitFiltersHandler = () => {
    onFilter(filters);
    dispatch(modalActions.closeModal());
  };

  // Маленький экран
  if (isSmall) {
    return (
      <div className="-mt-4 flex w-full flex-col">
        <div className="mb-4 flex w-full cursor-pointer items-center justify-center gap-2">
          <form
            onSubmit={handleSubmit(submitFiltersHandler)}
            className="flex h-full w-full flex-col p-4"
          >
            <div className="flex grow flex-col gap-4">
              <div className="flex justify-between">
                <span className="title2-medium">Фильтр</span>
                <button className="mr-2 !text-primary-60" type="button" onClick={onResetFilter}>
                  Очистить фильтры
                </button>
              </div>
              <div>
                <FieldSelect
                  name="cities"
                  control={control}
                  isMulti
                  variant="filter"
                  label="Город"
                  isClearable
                  onChange={(val: any) => {
                    onChangeValueHandler(val, 'cities');
                  }}
                  options={transformToSelectOptions<Settlement>(cities, 'objectFullName', 'fiasId')}
                  onInputChange={(newValue: string) => {
                    if (newValue.length < 3) {
                      return;
                    }
                    getCities(newValue);
                  }}
                />
              </div>
              <div>
                <Controller
                  name="create_date"
                  control={control}
                  render={({ field }) => {
                    return (
                      <DateRangePicker
                        placeholder="дд.мм.гггг"
                        label="Дата создания заявки"
                        value={field.value}
                        canClear={true}
                        maxDate={new Date()}
                        onChange={(val) => {
                          onChangeValueHandler(val, 'create_date');
                          field.onChange(val);
                        }}
                      />
                    );
                  }}
                />
              </div>
              <div>
                <FieldSelect
                  variant="filter"
                  label="Клиент"
                  name="clients"
                  control={control}
                  onChange={(val: any) => {
                    onChangeValueHandler(val, 'clients');
                  }}
                  options={transformToSelectOptions<EntityShort>(projects, 'shortName', 'id')}
                  isMulti
                />
              </div>
              <div>
                <FieldInput
                  name="customId"
                  label="Номер заявки"
                  control={control}
                  onChange={(val) => {
                    val
                      ? onChangeValueHandler(val, 'customId')
                      : onChangeValueHandler('', 'customId');
                  }}
                />
              </div>
            </div>
            {((isSmall && !openCalendar) || !isSmall) && (
              <div className="fixed bottom-10 w-full pr-8">
                <Button className="mb-6 w-full max-w-[640px]" type="submit">
                  ПРИМЕНИТЬ ФИЛЬТРЫ
                </Button>
                <Button
                  variant={ButtonVariant.OUTLINE}
                  className="btn-ghost w-full max-w-[640px] self-center"
                  type="button"
                  onClick={onCloseFilters}
                >
                  ЗАКРЫТЬ
                </Button>
              </div>
            )}
          </form>
          {!isSmall && !activeFiltersLength ? (
            <div className="flex h-5 w-5 items-center justify-center rounded-full bg-blue font-montserrat text-white">
              {activeFiltersLength}
            </div>
          ) : null}
        </div>
        <dialog className="fixed top-0 z-30 m-0 h-screen w-full p-4" open={open}></dialog>
      </div>
    );
  } else {
    return (
      <form onSubmit={handleSubmit(submitFiltersHandler)} className="relative flex flex-col gap-6">
        <div className="flex justify-between">
          <div className="title-bold text-text-100">Фильтр</div>
          <button
            className="headline-normal border-0 !text-primary-60 hover:font-semibold"
            type="button"
            onClick={onResetFilter}
          >
            Очистить
          </button>
        </div>
        <FieldSelect
          name="cities"
          control={control}
          isMulti
          variant="filter"
          label="Город"
          isClearable
          onChange={(val: any) => {
            onChangeValueHandler(val, 'cities');
          }}
          options={transformToSelectOptions<Settlement>(
            cities,
            'objectFullName',
            'fiasId',
            watch('cities')
          )}
          onInputChange={(newValue: string) => {
            if (newValue.length < 3) {
              return;
            }
            getCities(newValue);
          }}
        />
        <Controller
          name="create_date"
          control={control}
          render={({ field }) => {
            return (
              <DateRangePicker
                label="Дата создания заявки"
                value={field.value}
                onChange={(val) => {
                  onChangeValueHandler(val, 'create_date');
                  field.onChange(val);
                }}
                months={1}
                maxDate={new Date()}
              />
            );
          }}
        />
        <FieldSelect
          variant="filter"
          label="Клиент"
          name="clients"
          control={control}
          isClearable
          onChange={(val: any) => {
            onChangeValueHandler(val, 'clients');
          }}
          options={transformToSelectOptions<EntityShort>(
            projects,
            'shortName',
            'id',
            watch('clients')
          )}
          isMulti
        />
        <FieldInput
          name="customId"
          label="Номер заявки"
          control={control}
          onChange={(val) => {
            val ? onChangeValueHandler(val, 'customId') : onChangeValueHandler('', 'customId');
          }}
        />
        <div className="flex items-center justify-end">
          <Button type="submit">ПРИМЕНИТЬ</Button>
        </div>
      </form>
    );
  }
};
