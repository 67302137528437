import { UserIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FormControl } from 'pages/ModerationRequest/redesign/FormControl';
import { FormWrapper } from 'pages/ModerationRequest/redesign/FormWrapper';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { moderationRequestActions } from 'reduxStore/reducers/ModerationRequestSlice';
import { getModerationRequestData } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestData';
import { getModerationRequestStatus } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestStatus';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { Step1Fields } from './Step1Fields';
import { checkName } from './consts';
import { Step1Payload } from './types';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

export const Step1 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isFormDisabled = useAppSelector(getModerationRequestStatus);
  const {
    lastName: profileLastName,
    firstName: profileFirstName,
    middleName: profileMiddleName,
    inn,
    created,
  } = useAppSelector(getProfile);
  const {
    status,
    birthDate,
    lastName: requestLastName,
    firstName: requestFirstName,
    middleName: requestMiddleName,
  } = useAppSelector(getModerationRequestData);

  const lastName = requestLastName === null ? profileLastName : requestLastName;
  const firstName = requestFirstName === null ? profileFirstName : requestFirstName;
  const middleName = requestMiddleName === null ? profileMiddleName : requestMiddleName;

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<Step1Payload>({
    defaultValues: {
      lastName,
      firstName,
      middleName,
      birthDate,
      inn,
    },
    mode: 'onBlur',
  });

  const onSubmit = () => {
    handleSubmit((data) => {
      if (typeof data.birthDate === 'object') {
        data.birthDate = data.birthDate && format(data.birthDate, 'yyyy-MM-dd');
      }
      const toastErrors: string[] = [];
      checkName(data.firstName, 'firstName', 'Имя должно', toastErrors, setError);
      checkName(data.lastName, 'lastName', 'Фамилия должна', toastErrors, setError);
      if (data.middleName) {
        checkName(data.middleName, 'middleName', 'Отчество должно', toastErrors, setError);
      }
      if (toastErrors.length) {
        const onlyUnique = new Set(toastErrors);
        Array.from(onlyUnique).forEach((message: string) => {
          toast.error(message);
        });
        return;
      }
      dispatch(moderationRequestActions.setPrivateData(data));
      navigate(`/moderation-request/step2`);
    })();
  };

  return (
    <FormWrapper title="Личные данные" status={status} created={created} Icon={UserIcon}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex min-h-[65vh] flex-col justify-between"
      >
        <Step1Fields
          errors={errors}
          control={control}
          isFormDisabled={isFormDisabled}
          clearErrors={clearErrors}
        />
        <FormControl />
      </form>
    </FormWrapper>
  );
};
