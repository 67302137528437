import { EducationIcon } from 'assets/icons';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { FormControl } from 'pages/ModerationRequest/redesign/FormControl';
import { FormWrapper } from 'pages/ModerationRequest/redesign/FormWrapper';
import { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { moderationRequestActions } from 'reduxStore/reducers/ModerationRequestSlice';
import { getModerationRequestData } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestData';
import { Step6Fields } from './Step6Fields';
import { getModerationRequestStatus } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getModerationRequestStatus';
import { getProfile } from 'reduxStore/reducers/ProfileSlice/selectors/getProfile';
import { ExecutorTypeEnum } from 'enums/executorType';
import { checkExperience } from './consts';
import { Education, WorkExperience } from 'types';
import { RequestHandler } from '../finalStep/consts';
import { getExecutorNavigation } from 'reduxStore/reducers/ModerationRequestSlice/selectors/getExecutorNavigation';
import { toast } from 'react-toastify';

export const Step6 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isFormDisabled = useAppSelector(getModerationRequestStatus);
  const { created } = useAppSelector(getProfile);
  const { status, educations, workExperiences, executorType } =
    useAppSelector(getModerationRequestData);
  const isNavigateDisabled = useAppSelector(getExecutorNavigation);
  const disableNavigate = (value: boolean) => {
    dispatch(moderationRequestActions.setIsNavigateDisabled(value));
  };
  const { pathname } = useLocation();
  const step = pathname.split('step')[1];
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm<FieldValues>({
    defaultValues: {
      primaryEducation: educations?.filter((item) => item.isPrimary),
      secondaryEducation: educations?.filter((item) => !item.isPrimary),
      workExperiences: workExperiences,
    },
  });

  useEffect(() => {
    educations
      ?.filter((item) => item.isPrimary)
      .forEach((item, index) => {
        setValue(`primaryEducation.${index}`, item);
      });
    educations
      ?.filter((item) => !item.isPrimary)
      .forEach((item, index) => {
        setValue(`secondaryEducation.${index}`, item);
      });
  }, []);

  const onSubmit: SubmitHandler<any> = (data, event) => {
    const toastErrors: string[] = [];
    if (data?.primaryEducation?.length) {
      data?.primaryEducation.map((item: Education | WorkExperience, index: number) => {
        checkExperience(item, index, RequestHandler.DRAFT, toastErrors, setError);
      });
    }
    if (data?.secondaryEducation?.length) {
      data?.secondaryEducation.map((item: Education | WorkExperience, index: number) => {
        checkExperience(item, index, RequestHandler.DRAFT, toastErrors, setError);
      });
    }
    if (data?.workExperiences?.length) {
      data?.workExperiences.map((item: Education | WorkExperience, index: number) => {
        checkExperience(item, index, RequestHandler.DRAFT, toastErrors, setError);
      });
    }
    if (toastErrors.length) {
      const uniqueMessages = new Set(toastErrors);
      Array.from(uniqueMessages).forEach((message: string) => {
        toast.error(message);
      });
      return;
    }
    const educations = [...data.primaryEducation, ...data.secondaryEducation];
    dispatch(
      moderationRequestActions.setEducations({
        educations,
        workExperiences: data.workExperiences,
      })
    );
    const nativeEvent = event?.nativeEvent as SubmitEvent;
    const submitter = nativeEvent.submitter as HTMLButtonElement;
    if (isNavigateDisabled) {
      toast.error('Дождитесь удаления файла');
    } else if (submitter.name === 'forward') {
      const nextStep = +step + 1;
      navigate(`/moderation-request/step${nextStep}`);
    } else if (submitter.name === 'back') {
      const prevStep = +step - 1;
      prevStep
        ? prevStep && navigate(`/moderation-request/step${prevStep}`)
        : navigate(`/moderation-request`);
    }
  };

  return (
    <FormWrapper
      title={executorType === ExecutorTypeEnum.Selfemployed ? 'Образование' : 'Опыт работы'}
      status={status}
      created={created}
      Icon={EducationIcon}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex min-h-[65vh] flex-col justify-between"
      >
        <Step6Fields
          clearErrors={clearErrors}
          setError={setError}
          control={control}
          watch={watch}
          errors={errors}
          isFormDisabled={isFormDisabled}
          executorType={executorType}
          disableNavigate={disableNavigate}
        />
        <FormControl errors={errors} />
      </form>
    </FormWrapper>
  );
};
