import React, { ChangeEvent, useEffect, useState } from 'react';
import { DropdownArrowGrayIcon } from 'assets';

export interface NumberInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string;
  value?: number;
  onChange?: (count: number) => void;
  errorMessage?: string;
  step?: number;
  controlIcons?: boolean;
}

const validKeyForPayment = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'];

export default function NumberInput({
  label,
  value,
  onChange,
  errorMessage,
  step = 1,
  controlIcons = true,
  ...other
}: NumberInputProps) {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (value) {
      setCount(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onChange) {
      onChange(count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const handleDecrease = () => {
    setCount((e) => {
      if (e < step) return 0;
      return e - step;
    });
  };

  const handleIncrease = () => setCount((e) => e + step);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCount(() => {
      if (+e.target.value < step) return 0;
      return +e.target.value;
    });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const onlyNumbers = +event.target.value.replace(/[^0-9]/g, '');
    onChange && onChange(onlyNumbers);
  };

  return (
    <div className="field-number-input">
      {label && <div className="label">{label}</div>}

      <div className="relative">
        <input
          type="text"
          className={`input pr-[70px] ${errorMessage && 'input-error'} ${
            other.readOnly && 'text-gray-medium focus:border-gray focus:shadow-none'
          }`}
          onChange={controlIcons ? handleOnChange : handleChange}
          value={controlIcons ? value : value === 0 ? undefined : value}
          step={step}
          onKeyDown={
            controlIcons
              ? undefined
              : (e) => {
                  if (!validKeyForPayment.includes(e.key)) {
                    e.preventDefault();
                  }
                }
          }
          {...other}
        />
        {controlIcons && (
          <span
            className={`absolute right-2 top-1/2 flex -translate-y-1/2 gap-2 ${
              other.disabled ? 'pointer-events-none' : ''
            } ${other.readOnly ? 'pointer-events-none' : ''}`}
          >
            <div
              className={`flex h-10 w-5 cursor-pointer items-center justify-center ${
                other.disabled && 'cursor-not-allowed'
              }`}
              onClick={handleIncrease}
            >
              <img src={DropdownArrowGrayIcon} className="w-[14px] rotate-180" alt="Больше" />
            </div>
            <div
              className={`flex h-10 w-5 cursor-pointer items-center justify-center ${
                other.disabled && 'cursor-not-allowed'
              }`}
              onClick={handleDecrease}
            >
              <img src={DropdownArrowGrayIcon} className="w-[14px]" alt="Меньше" />
            </div>
          </span>
        )}
      </div>

      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}
