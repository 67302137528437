import { request } from 'api';
import { endpoints } from 'api/endpoints';
import { IconEmail, IconPhone, IconSupport } from 'assets/icons';
import useMediaQuery from 'hooks/useMediaQuery';
import { FC, useEffect, useState } from 'react';
import { InfoBlock } from 'ui/redesign/InfoBlock';
import { PageWrapper } from 'ui/redesign/PageWrapper';
import { IS_SMALL_DEVICE } from 'utils/settings';
import { ContactResponse } from './types';

export const Support: FC = () => {
  const isSmall = useMediaQuery(IS_SMALL_DEVICE);
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState<ContactResponse | undefined>(undefined);

  const getContacts = async () => {
    setIsLoading(true);
    request<ContactResponse>(
      endpoints.GET_SITE_CONTACTS.type,
      endpoints.GET_SITE_CONTACTS.url()
    ).then((response) => {
      if (response.status === 200) {
        setContacts(response.data);
        setIsLoading(false);
      }
    });
  };

  const formatNumber = (str: string) => {
    const onlyNumbers = str.replace(/\D/g, '');
    const formattedNumber = onlyNumbers.replace(
      /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
      '$1 ($2) $3 $4 $5'
    );
    return formattedNumber;
  };

  useEffect(() => {
    getContacts();
  }, []);

  return (
    <PageWrapper title="Техническая поддержка">
      <section className="flex flex-col gap-8 lg:max-w-[600px]">
        <div className="flex items-center justify-center">
          <IconSupport />
        </div>
        <InfoBlock
          text="Круглосуточная техническая поддержка"
          description={
            <p className="callout-normal sm:headline-normal mt-1">
              Если у вас возникли какие-либо вопросы, обратитесь в поддержку по номеру:
            </p>
          }
        />
        {isSmall ? (
          <ul className="rounded-[10px] bg-base px-5">
            <li className="grid grid-cols-support-contact-item-mobile justify-between gap-3 py-5 sm:grid-cols-support-contact-item">
              <div className="flex flex-col gap-3 sm:items-center">
                <p className="callout-normal sm:headline-normal">
                  Для звонков по России (бесплатно)
                </p>
                <p className="headline-medium text-primary-60">
                  {contacts?.techSupportPhone && formatNumber(contacts?.techSupportPhone)}
                </p>
              </div>
              <div className="gradient-0 flex h-10 min-h-[2.5rem] w-10 min-w-[2.5rem] items-center justify-center rounded-[50%] fill-white text-white">
                <IconPhone />
              </div>
            </li>
            <li className="grid grid-cols-support-contact-item-mobile justify-between gap-3 py-5 sm:grid-cols-support-contact-item">
              <div className="flex flex-col gap-3 sm:items-center">
                <p className="callout-normal sm:headline-normal">По вопросам трудоустройства</p>
                <p className="headline-medium text-primary-60">{contacts?.hrEmail}</p>
              </div>
              <div className="gradient-0 flex h-10 min-h-[2.5rem] w-10 min-w-[2.5rem] items-center justify-center rounded-[50%] fill-white text-white">
                <IconEmail />
              </div>
            </li>
            <li className="grid grid-cols-support-contact-item-mobile justify-between gap-3 py-5 sm:grid-cols-support-contact-item">
              <div className="flex flex-col gap-3 sm:items-center">
                <p className="callout-normal sm:headline-normal">Почта тех. поддержки</p>
                <p className="headline-medium text-primary-60">{contacts?.techSupportFormLink}</p>
              </div>
              <div className="gradient-0 flex h-10 min-h-[2.5rem] w-10 min-w-[2.5rem] items-center justify-center rounded-[50%] fill-white text-white">
                <IconEmail />
              </div>
            </li>
          </ul>
        ) : (
          <ul className="rounded-[10px] bg-base px-5 shadow">
            <li className="grid grid-cols-[auto_170px] items-center justify-between gap-3 border-[#E4E4E5] py-5 first:border-b-[1px]">
              <div className="flex items-center gap-3">
                <span
                  className="gradient-0 flex h-10 min-h-[2.5rem] w-10 min-w-[2.5rem] items-center 
                  justify-center rounded-[50%] fill-white text-white"
                >
                  <IconPhone />
                </span>
                <p className="text-lg">Для звонков по России (бесплатно) </p>
              </div>
              <span className="text-lg font-medium text-[#4251DA]">
                {contacts?.techSupportPhone && formatNumber(contacts?.techSupportPhone)}
              </span>
            </li>
            <li className="grid grid-cols-[auto_135px] items-center justify-between gap-3 border-b-[1px] border-[#E4E4E5] py-5">
              <div className="flex items-center gap-3">
                <span
                  className="gradient-0 flex h-10 min-h-[2.5rem] w-10 min-w-[2.5rem] items-center 
                  justify-center rounded-[50%] fill-white text-white"
                >
                  <IconEmail />
                </span>
                <p className="text-lg">По вопросам трудоустройства</p>
              </div>
              <a
                href={`mailto:${contacts?.hrEmail}`}
                className="text-lg font-medium text-[#4251DA]"
              >
                {contacts?.hrEmail}
              </a>
            </li>
            <li className="grid grid-cols-[auto_180px] items-center justify-between gap-3 border-[#E4E4E5] py-5 first:border-b-[1px]">
              <div className="flex items-center gap-3">
                <span
                  className="gradient-0 flex h-10 min-h-[2.5rem] w-10 min-w-[2.5rem] items-center 
                  justify-center rounded-[50%] fill-white text-white"
                >
                  <IconEmail />
                </span>
                <p className="text-lg">Почта тех. поддержки</p>
              </div>
              <a
                href={`mailto:${contacts?.techSupportFormLink}`}
                className="text-lg font-medium text-[#4251DA]"
              >
                {contacts?.techSupportFormLink}
              </a>
            </li>
          </ul>
        )}
      </section>
    </PageWrapper>
  );
};
