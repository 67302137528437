import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StoreProvider } from 'reduxStore/StoreProvider';
import './index.css';
import './reset.css';
import { ModalWindow } from './ui/redesign/ModalWindow';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NavigationHistoryProvider } from 'navigationProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: 'always',
      refetchOnMount: 'always',
      staleTime: 1_000,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <NavigationHistoryProvider>
        <StoreProvider>
          <QueryClientProvider client={queryClient}>
            <App />
            <ModalWindow />
            <ToastContainer />
          </QueryClientProvider>
        </StoreProvider>
      </NavigationHistoryProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
