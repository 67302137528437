import { FC } from 'react';

export const CodeRequestTimeout: FC<{ timer: number }> = ({ timer }) => {
  const minutes = Math.floor(timer / 60);
  const seconds = timer - minutes * 60;
  return (
    <p className="mt-2 text-xs text-gray-medium">
      Повторно отправить код через {minutes < 10 ? `0${minutes}` : minutes}:
      {seconds < 10 ? `0${seconds}` : seconds} сек.
    </p>
  );
};
